import React, { useEffect, useMemo, useState } from 'react';
import DialogLayout from 'src/components/core/dialog-layout';
import Card from 'src/components/ui/card';
import Input from 'src/components/ui/input';
import TextDiff from 'src/components/ui/textDiff';
import Typography from 'src/components/ui/typography';
import { TableChangeRequest } from 'src/resources/table-change-requests/table-change-requests-model';
import { diffText } from 'src/utils/diff-text-utils';
import './index.scss';

interface Props {
  changeRequest?: TableChangeRequest;
  isLoading: boolean;
  isOpen: boolean;
  onCancel: VoidFunction;
  onApprove: (reason: string) => void;
}

const ConfirmApprovalModal: React.FC<Props> = props => {
  const { isOpen, isLoading, changeRequest, onCancel, onApprove } = props;
  const [reason, setReason] = useState('');
  const diffTextMetadata = useMemo(
    () => (changeRequest ? diffText(changeRequest.currentValue, changeRequest.newValue) : []),
    [changeRequest]
  );

  useEffect(() => {
    setReason('');
  }, [changeRequest]);

  return (
    <DialogLayout
      open={isOpen}
      isLoading={isLoading}
      title="Confirm Approval"
      subtitle="You are approving the change request below:"
      scapeActionText="CANCEL"
      scapeActionHandler={onCancel}
      confirmActionText="CONFIRM APPROVAL"
      confirmActionHandler={() => onApprove(reason)}
      className="confirm-approval-dialog-container"
      variant="outlined">
      <>
        <Card variant="secondary">
          <header>
            <Typography sizeVariant="sm">Change request:</Typography>
          </header>
          <article>
            <TextDiff data={diffTextMetadata} />
          </article>
        </Card>
        <Input
          title="Observation"
          className="my-4"
          value={reason}
          onChange={e => setReason(e.target.value)}
          placeholder="Type observation"
        />
        <Typography>This action is irreversible. Want to confirm?</Typography>
      </>
    </DialogLayout>
  );
};

export default ConfirmApprovalModal;
