import { useState } from 'react';
import { ExternalData } from 'src/@types/external-api';
import { AccordionControls } from 'src/components/ui/accordion';
import AccordionV2, { AccordionV2Header } from 'src/components/ui/accordionv2';
import { hasUnselectedStatus } from 'src/components/ui/filter';
import { FacetValue } from 'src/components/ui/filter/types';
import { PaginationData } from 'src/components/ui/pagination';
import {
  Feedback,
  FeedbackStatus,
  PinnedFeedback,
  SeenFeedback,
  UnseenFeedback
} from 'src/resources/feedback/feedback-model';
import { ListResult } from 'src/resources/feedback/feedback-types';
import { getFeedbackStatusLabel } from '../feedback-utils';
import FeedbackList from './feedback-list';
import './index.scss';

interface SingleFeedbacksProps {
  feedbacks: {
    data: ExternalData<ListResult<Feedback>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
}

interface MultipleFeedbacksProps {
  unseenFeedbacks: {
    data: ExternalData<ListResult<UnseenFeedback>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
  pinnedFeedbacks: {
    data: ExternalData<ListResult<PinnedFeedback>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
  seenFeedbacks: {
    data: ExternalData<ListResult<SeenFeedback>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
}

interface Props extends SingleFeedbacksProps, MultipleFeedbacksProps {
  facetValues: FacetValue[];
}

const SingleFeedbacksListRenderer: React.FC<SingleFeedbacksProps> = props => {
  return <FeedbackList {...props.feedbacks} />;
};

const MultipleFeedbacksListRenderer: React.FC<MultipleFeedbacksProps> = props => {
  const [accordionsState, setAccordionsState] = useState<FeedbackStatus>(FeedbackStatus.Pinned);

  const handleChangeAccordion: AccordionControls['onSelect'] = status => {
    setAccordionsState(status as FeedbackStatus);
  };

  return (
    <article className="feedbacks-accordions-container fancy-scrollbar">
      <AccordionV2
        className={accordionsState === FeedbackStatus.Pinned ? 'active' : ''}
        controls={{
          activeKey: accordionsState,
          onSelect: handleChangeAccordion
        }}
        accordionItems={[
          {
            key: FeedbackStatus.Pinned,
            data: {
              header: <AccordionV2Header title={getFeedbackStatusLabel(FeedbackStatus.Pinned)} />,
              content: <FeedbackList {...props.pinnedFeedbacks} />
            }
          }
        ]}
      />
      <AccordionV2
        className={accordionsState === FeedbackStatus.Unseen ? 'active' : ''}
        controls={{
          activeKey: accordionsState,
          onSelect: handleChangeAccordion
        }}
        accordionItems={[
          {
            key: FeedbackStatus.Unseen,
            data: {
              header: <AccordionV2Header title={getFeedbackStatusLabel(FeedbackStatus.Unseen)} />,
              content: <FeedbackList {...props.unseenFeedbacks} />
            }
          }
        ]}
      />
      <AccordionV2
        className={accordionsState === FeedbackStatus.Seen ? 'active' : ''}
        controls={{
          activeKey: accordionsState,
          onSelect: handleChangeAccordion
        }}
        accordionItems={[
          {
            key: FeedbackStatus.Seen,
            data: {
              header: <AccordionV2Header title={getFeedbackStatusLabel(FeedbackStatus.Seen)} />,
              content: <FeedbackList {...props.seenFeedbacks} />
            }
          }
        ]}
      />
    </article>
  );
};

const FeedbacksListRenderer: React.FC<Props> = props => {
  const { facetValues } = props;

  return hasUnselectedStatus(facetValues) ? (
    <MultipleFeedbacksListRenderer {...props} />
  ) : (
    <SingleFeedbacksListRenderer {...props} />
  );
};

export default FeedbacksListRenderer;
