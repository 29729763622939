import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from 'src/@types/external-api';
import useExternalApiErrorHandler from 'src/hooks/use-external-api-error-handler';
import {
  PendingTableChangeRequest,
  RequestStatusEnum,
  ReviewedTableChangeRequest,
  TableChangeRequest,
  TableChangeRequestsCounter
} from 'src/resources/table-change-requests/table-change-requests-model';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalCallSuccessData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData
} from 'src/helpers/external-data';
import useToasts from '../toasts/toasts-hook';
import TableChangeRequestsContext from './table-change-requests-context';
import {
  CreateChangeRequestParams,
  CreateChangeRequestReviewParams,
  FetchTableChangeRequestsWithoutStatusParams,
  FetchTableChangeRequestsParams,
  ListResult
} from './table-change-requests-types';
import tableChangeRequestsService from './table-change-requests.service';

const TableChangeRequestsProvider: React.FC = ({ children }) => {
  const { push } = useToasts();
  const errorHandler = useExternalApiErrorHandler();
  const [createdRequest, setCreatedRequest] = useState<ExternalAction>({});
  const [createdReview, setCreatedReview] = useState<ExternalAction>({});
  const [pendingChangeRequestsCounter, setPendingChangeRequestsCounter] = useState<
    ExternalData<TableChangeRequestsCounter>
  >(makeExternalDataInitialData());
  const [tableChangeRequests, setTableChangeRequests] = useState<
    ExternalData<ListResult<TableChangeRequest>>
  >(makeExternalDataInitialData());
  const [pendingTableChangeRequests, setPendingTableChangeRequests] = useState<
    ExternalData<ListResult<PendingTableChangeRequest>>
  >(makeExternalDataInitialData());
  const [reviewedTableChangeRequests, setReviewedTableChangeRequests] = useState<
    ExternalData<ListResult<ReviewedTableChangeRequest>>
  >(makeExternalDataInitialData());
  const [datasetsWithRequests, setDatasetsWithRequests] = useState<ExternalData<string[]>>({
    loading: false
  });

  // This cleanup logic resets the external action to its initial state
  // Later, we should find a way to improve this
  useEffect(() => {
    if (createdReview.success) {
      setCreatedReview({});
    }
  }, [createdReview.success]);

  const createRequest = async (params: CreateChangeRequestParams): Promise<void> => {
    try {
      setCreatedRequest(makeExternalDataInitialData());
      await tableChangeRequestsService.create(params);
      setCreatedRequest(makeExternalCallSuccessData);
      push('Change request created successfully');
    } catch (err: any) {
      setCreatedRequest(makeExternalCallErrorData(err));
      errorHandler(err);
    }
  };

  const fetchPendingChangeRequestsCounter = useCallback(
    async (tableName): Promise<void> => {
      try {
        setPendingChangeRequestsCounter(makeExternalDataInitialKeepData);

        const data = await tableChangeRequestsService.count(tableName, RequestStatusEnum.Pending);

        setPendingChangeRequestsCounter(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setPendingChangeRequestsCounter(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchTableChangeRequests = useCallback(
    async (fetchTableChangeRequestsParams?: FetchTableChangeRequestsParams): Promise<void> => {
      try {
        setTableChangeRequests(makeExternalDataInitialKeepData);
        const data = await tableChangeRequestsService.list<TableChangeRequest>(
          fetchTableChangeRequestsParams
        );
        setTableChangeRequests(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setTableChangeRequests(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchPendingTableChangeRequests = useCallback(
    async (
      fetchTableChangeRequestsParams?: FetchTableChangeRequestsWithoutStatusParams
    ): Promise<void> => {
      try {
        setPendingTableChangeRequests(makeExternalDataInitialKeepData);
        const data = await tableChangeRequestsService.list<PendingTableChangeRequest>({
          ...fetchTableChangeRequestsParams,
          status: RequestStatusEnum.Pending
        });
        setPendingTableChangeRequests(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setPendingTableChangeRequests(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchReviewedTableChangeRequests = useCallback(
    async (
      fetchTableChangeRequestsParams?: FetchTableChangeRequestsWithoutStatusParams
    ): Promise<void> => {
      try {
        setReviewedTableChangeRequests(makeExternalDataInitialKeepData);
        const data = await tableChangeRequestsService.list<ReviewedTableChangeRequest>({
          ...fetchTableChangeRequestsParams,
          status: RequestStatusEnum.Approved
        });
        setReviewedTableChangeRequests(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setReviewedTableChangeRequests(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchDatasetsWithRequests = useCallback(async (): Promise<void> => {
    try {
      setDatasetsWithRequests(makeExternalDataInitialData());
      const data = await tableChangeRequestsService.listTables();
      setDatasetsWithRequests(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setDatasetsWithRequests(makeExternalCallErrorData(err));
      errorHandler(err);
    }
  }, [errorHandler]);

  const createReview = useCallback(
    async (params: CreateChangeRequestReviewParams): Promise<void> => {
      try {
        setCreatedReview(makeExternalDataInitialData());
        await tableChangeRequestsService.review(params);
        push('Approval review sent successfully');
        setCreatedReview(makeExternalCallSuccessData);
      } catch (err: any) {
        setCreatedReview(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler, push]
  );

  return (
    <TableChangeRequestsContext.Provider
      value={{
        pendingChangeRequestsCounter,
        createdReview,
        tableChangeRequests,
        pendingTableChangeRequests,
        reviewedTableChangeRequests,
        createdRequest,
        datasetsWithRequests,
        createRequest,
        createReview,
        fetchTableChangeRequests,
        fetchPendingChangeRequestsCounter,
        fetchDatasetsWithRequests,
        fetchPendingTableChangeRequests,
        fetchReviewedTableChangeRequests
      }}>
      {children}
    </TableChangeRequestsContext.Provider>
  );
};

export default TableChangeRequestsProvider;
