import React from 'react';
import './index.scss';

interface CardProps {
  variant?: 'primary' | 'secondary';
  className?: string;
  children: React.ReactNode;
  onClick?: VoidFunction;
}

const Card = (props: CardProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { children, className, variant = 'primary', onClick } = props;

  return (
    <div
      className={`card-container ${variant} ${className ?? ''} ${onClick ? 'clickable' : ''}`}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
