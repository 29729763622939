import React from 'react';
import Card from 'src/components/ui/card';
import TableStatus from 'src/components/ui/table-status';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import { Table } from 'src/resources/table/models/table-model';
import './index.scss';

interface Props {
  table: Table;
  onClick: VoidFunction;
}

const DataSetItem: React.FC<Props> = props => {
  const { table, onClick } = props;

  return (
    <Card className="data-set-item" onClick={onClick}>
      <Title sizeVariant="sm">{table.tableName}</Title>
      <Typography colorVariant="primary" sizeVariant="sm" className="mt-1">
        dataset: {table.dataset}
      </Typography>
      <Typography colorVariant="primary" sizeVariant="sm">
        project: {table.project}
      </Typography>
      <span className="data-set-item-tags">
        <Typography colorVariant="primary" sizeVariant="sm">
          TABLE
        </Typography>
        <Typography colorVariant="primary" sizeVariant="sm">
          BIGQUERY
        </Typography>
        <TableStatus table={table} className="table-status" />
      </span>
      <Typography sizeVariant="sm" className="mt-3">
        {table.description}
      </Typography>
    </Card>
  );
};

export default DataSetItem;
