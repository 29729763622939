import {
  PendingTableChangeRequest,
  ReviewedTableChangeRequest,
  TableChangeRequestsCounter
} from './table-change-requests-model';
import { apiWrapper } from 'src/config/api-wrapper';
import { TableChangeRequest } from 'src/resources/table-change-requests/table-change-requests-model';
import {
  tableChangeRequestsMapper,
  tableChangeRequestPaginationMapper,
  tableChangeRequestsCounterMapper
} from './table-change-requests-mappers';
import {
  CreateChangeRequestParams,
  CreateChangeRequestReviewParams,
  FetchTableChangeRequestsParams,
  ListResult
} from './table-change-requests-types';

class TableChangeRequestsServices {
  public create(params: CreateChangeRequestParams): Promise<void> {
    return apiWrapper.api.post('/schema/change', params);
  }

  public review(params: CreateChangeRequestReviewParams): Promise<void> {
    return apiWrapper.api.post('/schema/review', params);
  }

  public count(
    tableName: string,
    status: TableChangeRequest['status']
  ): Promise<TableChangeRequestsCounter> {
    const params: FetchTableChangeRequestsParams = {
      status,
      table_name: tableName,
      page_size: 1
    };

    return apiWrapper.api
      .get('/schema', { params })
      .then(res => res.data)
      .then(data => tableChangeRequestsCounterMapper(data));
  }

  public list<T = TableChangeRequest | PendingTableChangeRequest | ReviewedTableChangeRequest>(
    fetchTableChangeRequestsParams?: FetchTableChangeRequestsParams
  ): Promise<ListResult<T>> {
    return apiWrapper.api
      .get('/schema', { params: fetchTableChangeRequestsParams })
      .then(res => res.data)
      .then(data => ({
        changeRequests: tableChangeRequestsMapper(data.content) as T[],
        pagination: tableChangeRequestPaginationMapper(data)
      }));
  }

  public listTables(): Promise<string[]> {
    return apiWrapper.api.get('/schema/tables').then(res => res.data);
  }
}

export default new TableChangeRequestsServices();
