import { Table as TableModel } from 'src/resources/table/models/table-model';
import Table, { TableProps } from '../table';
import React, { RefObject, useMemo } from 'react';

export interface Props {
  table: TableModel;
  onClickRow: (row: string) => void;
  onEdit?: (rowData: string[]) => void;
  parentId?: string;
  parentRef?: RefObject<HTMLDivElement>;
}

const makeTableFileName = (table: TableModel): string => {
  return `${table.tableName}-schema`;
};

const tableColumnsToTableTableComponentRows = (
  columns: TableModel['columns'],
  onClickRow?: (row: string) => void
): TableProps['rows'] => {
  return columns.map((column, idx) => ({
    data: [
      idx + 1,
      column.name,
      column.description,
      column.dictionaryDescription,
      column.type,
      column.mode
    ],
    // the row[1] is because the row[0] is the item position instead of the item name
    onClick: column.distributionAvailable ? row => onClickRow?.(row[1].toString()) : undefined
  }));
};

const headers: TableProps['headers'] = [
  '#',
  'Column',
  'Description',
  'Dictionary',
  'Data Type',
  'Mode'
];

const dataTypes: TableProps['dataTypes'] = [
  'number',
  'string',
  'string',
  'string',
  'string',
  'string'
];

const SchemaTable: React.FC<Props> = ({ table, onClickRow, onEdit, parentId, parentRef }) => {
  const downloadOptions: TableProps['downloadOptions'] = useMemo(
    () => ({
      fileName: makeTableFileName(table),
      data: tableColumnsToTableTableComponentRows(table.columns)
    }),
    [table]
  );
  const rows = useMemo(
    () => tableColumnsToTableTableComponentRows(table.columns, onClickRow),
    [onClickRow, table.columns]
  );

  return (
    <Table
      description="Definition of fields, columns, descriptions, data types and samples of data"
      rows={rows}
      downloadOptions={downloadOptions}
      hasInfiniteScroll
      headers={headers}
      dataTypes={dataTypes}
      // removes the # from the data
      onEdit={row => onEdit?.(row.slice(1) as string[])}
      isExpandable
      isSortable
      hasSearchBar
      parentId={parentId}
      parentRef={parentRef}
    />
  );
};

export default SchemaTable;
