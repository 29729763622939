import { createContext } from 'react';
import { User } from './user-model';

export interface SingInOptions {
  originalUri: string;
}

export interface AuthContextType {
  user?: User;
  isAuthenticated: boolean;
  signIn: (option: SingInOptions) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType>(null as any);

export default AuthContext;
