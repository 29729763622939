import React, { useEffect, useState } from 'react';
import { ExternalData } from 'src/@types/external-api';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import { AccordionControls } from 'src/components/ui/accordion';
import AccordionV2, { AccordionV2Header } from 'src/components/ui/accordionv2';
import { hasUnselectedStatus } from 'src/components/ui/filter';
import { FacetValue } from 'src/components/ui/filter/types';
import { PaginationData } from 'src/components/ui/pagination';
import {
  PendingTableChangeRequest,
  ReviewedTableChangeRequest,
  TableChangeRequest
} from 'src/resources/table-change-requests/table-change-requests-model';
import { ListResult } from 'src/resources/table-change-requests/table-change-requests-types';
import './index.scss';
import ContextualizedRequestedChangesList from './requested-changes-list/contextualized-index';

interface MultipleChangeRequestsProps {
  pendingTableChangeRequests: {
    data: ExternalData<ListResult<PendingTableChangeRequest>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
  reviewedTableChangeRequests: {
    data: ExternalData<ListResult<ReviewedTableChangeRequest>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
}

interface SingleChangeRequestsProps {
  tableChangeRequests: {
    data: ExternalData<ListResult<TableChangeRequest>>;
    onPaginationChange: (newPaginationData: PaginationData) => void;
  };
}

interface Props extends MultipleChangeRequestsProps, SingleChangeRequestsProps {
  facetValues: FacetValue[];
}

const pendingAnalysis = 'pending-analysis';
const analyzed = 'analyzed';

const MultipleRequestsListRenderer: React.FC<MultipleChangeRequestsProps> = props => {
  const { pendingTableChangeRequests, reviewedTableChangeRequests } = props;
  const [accordionsState, setAccordionsState] = useState(pendingAnalysis);
  const [isPendingFetchingMore, setIsPendingFetchingMore] = useState(false);
  const [isReviewedFetchingMore, setIsReviewedFetchingMore] = useState(false);

  useEffect(() => {
    if (!pendingTableChangeRequests.data.loading) {
      setIsPendingFetchingMore(false);
    }
  }, [pendingTableChangeRequests.data.loading]);

  useEffect(() => {
    if (!reviewedTableChangeRequests.data.loading) {
      setIsReviewedFetchingMore(false);
    }
  }, [reviewedTableChangeRequests.data.loading]);

  const handleChangeAccordion: AccordionControls['onSelect'] = status => {
    setAccordionsState(status as string);
  };

  const handlePendingAccordionPaginationChange = (paginationData: any): void => {
    setIsPendingFetchingMore(true);
    pendingTableChangeRequests.onPaginationChange(paginationData);
  };

  const handleReviewedAccordionPaginationChange = (paginationData: any): void => {
    setIsReviewedFetchingMore(true);
    reviewedTableChangeRequests.onPaginationChange(paginationData);
  };

  return (
    <article className="changes-request-accordions-container">
      <AccordionV2
        controls={{
          activeKey: accordionsState,
          onSelect: handleChangeAccordion
        }}
        accordionItems={[
          {
            key: pendingAnalysis,
            data: {
              header: <AccordionV2Header title={'Pending Analysis'} />,
              content: (
                <ExternalDataRenderer
                  externalData={pendingTableChangeRequests.data}
                  makeCustomIsLoading={() =>
                    !!(pendingTableChangeRequests.data.loading && !isPendingFetchingMore)
                  }
                  makeDataElement={() => (
                    <ContextualizedRequestedChangesList
                      data={pendingTableChangeRequests.data}
                      setPagination={handlePendingAccordionPaginationChange}
                    />
                  )}
                />
              )
            }
          }
        ]}
      />
      <AccordionV2
        controls={{
          activeKey: accordionsState,
          onSelect: handleChangeAccordion
        }}
        accordionItems={[
          {
            key: analyzed,
            data: {
              header: <AccordionV2Header title={'Analyzed'} />,
              content: (
                <ExternalDataRenderer
                  externalData={reviewedTableChangeRequests.data}
                  makeCustomIsLoading={() =>
                    !!(reviewedTableChangeRequests.data.loading && !isReviewedFetchingMore)
                  }
                  makeDataElement={() => (
                    <ContextualizedRequestedChangesList
                      data={reviewedTableChangeRequests.data}
                      setPagination={handleReviewedAccordionPaginationChange}
                    />
                  )}
                />
              )
            }
          }
        ]}
      />
    </article>
  );
};

const SingleRequestsListRenderer: React.FC<SingleChangeRequestsProps> = props => {
  const { tableChangeRequests } = props;
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  useEffect(() => {
    if (!tableChangeRequests.data.loading) {
      setIsFetchingMore(false);
    }
  }, [tableChangeRequests.data.loading]);

  const handlePaginationChange = (paginationData: any): void => {
    setIsFetchingMore(true);
    tableChangeRequests.onPaginationChange(paginationData);
  };

  return (
    <ExternalDataRenderer
      externalData={tableChangeRequests.data}
      makeCustomIsLoading={() => !!(tableChangeRequests.data.loading && !isFetchingMore)}
      makeDataElement={() => (
        <ContextualizedRequestedChangesList
          data={tableChangeRequests.data}
          className={'changes-request-single-accordion-container fancy-scrollbar pe-2'}
          setPagination={handlePaginationChange}
        />
      )}
    />
  );
};

const ChangesRequestsListRenderer: React.FC<Props> = props => {
  const {
    facetValues,
    tableChangeRequests,
    pendingTableChangeRequests,
    reviewedTableChangeRequests
  } = props;

  return hasUnselectedStatus(facetValues) ? (
    <MultipleRequestsListRenderer
      pendingTableChangeRequests={pendingTableChangeRequests}
      reviewedTableChangeRequests={reviewedTableChangeRequests}
    />
  ) : (
    <SingleRequestsListRenderer tableChangeRequests={tableChangeRequests} />
  );
};

export default ChangesRequestsListRenderer;
