import React, { useMemo } from 'react';
import Accordion from 'src/components/ui/accordion';
import Button from 'src/components/ui/button';
import TextDiff from 'src/components/ui/textDiff';
import Typography from 'src/components/ui/typography';
import {
  RequestStatusEnum,
  TableChangeRequest
} from 'src/resources/table-change-requests/table-change-requests-model';
import { diffText } from 'src/utils/diff-text-utils';
import {
  requestDescriptionShape,
  requesterReviewerFormatter,
  toRequestStatusUi,
  tableNameFormatter
} from '../../../../data-set-details/utils';
import './index.scss';
import { mdiLink } from '@mdi/js';
import { Icon } from '@mdi/react';
import { encodeTableId } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

export interface Props {
  data: TableChangeRequest[];
  handlers?: {
    handleApprove: (changeId: string) => void;
    handleReject: (changeId: string) => void;
  };
}

const AccordionHeader: React.FC<{
  request: TableChangeRequest;
  onNavClick: VoidFunction;
}> = props => {
  const { request, onNavClick } = props;
  const statusUi = useMemo(() => toRequestStatusUi(request.status), [request.status]);

  return (
    <>
      <div className="table-change-request-header-container">
        <div className="table-change-request-header">
          <Typography colorVariant="tertiary" weight="bold">
            {requestDescriptionShape(request.requestType)}
          </Typography>
          <Typography
            className="table-name-typography"
            sizeVariant="sm"
            colorVariant="primary"
            onClick={onNavClick}>
            <Icon className="me-1" path={mdiLink} color="var(--warning-blue)" size={'16px'} />
            {tableNameFormatter(request.tableName)}
          </Typography>
          <div className="table-status">
            <Icon
              className="me-1"
              path={statusUi.icon.path}
              color={statusUi.icon.color}
              size={'16px'}
            />
            <Typography
              sizeVariant="sm"
              weight="bold"
              style={{
                color: statusUi.icon.color
              }}>
              {statusUi.label}
            </Typography>
          </div>
        </div>
        <div className="me-3 table-change-request-header-requested-by">
          <div>
            <Typography sizeVariant="sm">Requested By:</Typography>
            <Typography sizeVariant="sm" colorVariant="primary">
              {requesterReviewerFormatter(request.requestedBy, request.requestedDt)}
            </Typography>
          </div>
          {request.reviewedBy && (
            <div>
              <Typography sizeVariant="sm">Reviewed By:</Typography>
              <Typography sizeVariant="sm" colorVariant="primary">
                {requesterReviewerFormatter(request.reviewedBy, request.reviewedDt)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

interface AccordionContentProps {
  request: TableChangeRequest;
  handlers?: {
    handleApprove: VoidFunction;
    handleReject: VoidFunction;
  };
}

const AccordionContent: React.FC<AccordionContentProps> = data => {
  const { request, handlers } = data;
  const diffTextMetadata = useMemo(
    () => diffText(request.currentValue, request.newValue),
    [request.currentValue, request.newValue]
  );

  return (
    <div className="table-change-request-content-container">
      <TextDiff data={diffTextMetadata} />
      <footer>
        <div className="reason-container">
          <Typography sizeVariant="sm">Request Reason:</Typography>
          <Typography sizeVariant="sm" colorVariant="primary">
            {request.requestDescription}
          </Typography>
        </div>
        {handlers && request.status === RequestStatusEnum.Pending && (
          <div className="handlers-container">
            <Button className="reject-btn" variant="quaternary" onClick={handlers.handleReject}>
              REJECT REQUEST
            </Button>
            <Button className="approve-btn" onClick={handlers.handleApprove}>
              APPROVE REQUEST
            </Button>
          </div>
        )}
      </footer>
    </div>
  );
};

const RequestChangesAccordions: React.FC<Props> = props => {
  const { data, handlers } = props;
  const navigate = useNavigate();

  const handleNavigateToTable = (request: TableChangeRequest): void => {
    navigate(`/dashboard/search/${encodeTableId(request.tableId)}`);
  };

  return (
    <Accordion
      accordionItems={data.map(request => ({
        key: request.id,
        data: {
          header: (
            <AccordionHeader request={request} onNavClick={() => handleNavigateToTable(request)} />
          ),
          content: (
            <AccordionContent
              request={request}
              handlers={
                handlers && {
                  handleApprove: () => handlers.handleApprove(request.id),
                  handleReject: () => handlers.handleReject(request.id)
                }
              }
            />
          )
        }
      }))}
    />
  );
};

export default RequestChangesAccordions;
