import { createContext } from 'react';
import { Feature } from 'src/config/features';

export interface FeaturesCtxType {
  loading: boolean;
  hasFeature: (feature: Feature) => boolean;
}

const FeaturesContext = createContext<FeaturesCtxType>(null as any);

export default FeaturesContext;
