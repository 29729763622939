import { DataDistribution, Distribution } from '../models/table-data-distribution-model';

export const tableDataDistributionMapper = (apiData: any): DataDistribution => {
  return {
    labelName: apiData.labelName,
    description: apiData.description,
    updatedAt: new Date(apiData.lastUpdateDt),
    distribution: (apiData.visualization as any[]).map<Distribution>((visualization: any) => ({
      key: visualization.label,
      value: Number(visualization.count)
    }))
  };
};
