import { ExternalData } from 'src/@types/external-api';
import NoResultsFound from 'src/components/ui/no-results-found';
import { LimitOptions, PaginationData } from 'src/components/ui/pagination';
import useIntersectElement from 'src/hooks/use-intersect-element';
import {
  TableChangeRequest,
  PendingTableChangeRequest,
  ReviewedTableChangeRequest
} from 'src/resources/table-change-requests/table-change-requests-model';
import { ListResult } from 'src/resources/table-change-requests/table-change-requests-types';
import RequestChangesAccordions, {
  Props as RequestChangesAccordionsProps
} from './requested-changes-accordions';

export interface Props {
  data: ExternalData<
    ListResult<TableChangeRequest | PendingTableChangeRequest | ReviewedTableChangeRequest>
  >;
  setPagination: (pagination: PaginationData) => void;
  handlers?: RequestChangesAccordionsProps['handlers'];
  className?: string;
}

const RequestedChangesList: React.FC<Props> = props => {
  const { data, setPagination, handlers, className = '' } = props;
  const canLoadMore = data.data ? data.data.pagination.limit < data.data.pagination.total : false;
  const intersectElement = useIntersectElement(() => {
    if (!data.loading && canLoadMore && data.data) {
      setPagination({
        page: data.data.pagination.offset,
        limit: (Number(data.data.pagination.limit) +
          Number(data.data.pagination.limit)) as LimitOptions
      });
    }
  });

  if ((data.data?.changeRequests?.length ?? 0) === 0) {
    return <NoResultsFound description="Filters hasn't returned results" />;
  }

  return (
    <div className={className}>
      <RequestChangesAccordions data={data.data?.changeRequests ?? []} handlers={handlers} />
      {canLoadMore && intersectElement}
    </div>
  );
};

export default RequestedChangesList;
