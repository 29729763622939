import { mdiMagnify, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { BaseSyntheticEvent, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

export interface Props {
  id: string;
  placeholder: string;
  value: string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  onChange: (value: string) => void;
  maxLength?: number;
}

const SearchInput: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, placeholder, value, className, required = false, onChange, maxLength } = props;

  const handleChange = useCallback(
    (event: BaseSyntheticEvent<InputEvent, any, HTMLInputElement>) => {
      const { value } = event.target;

      onChange(value);
    },
    [onChange]
  );

  const handleEraseContent = (): void => {
    onChange('');
  };

  return (
    <Form.Group className={`search-input ${className ?? ''}`}>
      <Form.Control
        id={id}
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={handleChange}
        maxLength={maxLength}
      />
      {value === '' ? (
        <Icon
          path={mdiMagnify}
          className="helper-icon"
          title="Search"
          size="27px"
          color="var(--detail-gray)"
        />
      ) : (
        <span onClick={handleEraseContent}>
          <Icon
            path={mdiClose}
            className="helper-icon actionable"
            title="Clear"
            size="27px"
            color="var(--detail-gray)"
          />
        </span>
      )}
    </Form.Group>
  );
};

export default SearchInput;
