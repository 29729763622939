import { useCallback } from 'react';
import { NodeProps, useReactFlow, getOutgoers, MarkerType } from 'reactflow';

import { uuid } from '../utils';

// this hook implements the logic for clicking a workflow node
// on workflow node click: create a new child node of the clicked node

const useNodeClick = (id: NodeProps['id']): ((type?: string, data?: any) => void) => {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(
    (type?: string, data?: any) => {
      // we need the parent node object for positioning the new child node
      const parentNode = getNode(id);

      if (!parentNode) {
        return;
      }

      // create a unique id for the child node
      const childNodeId = uuid();

      // create a unique id for the placeholder (the placeholder gets added to the new child node)
      // const childPlaceholderId = uuid();

      // create the child node
      const childNode = {
        id: data.id !== undefined ? String(data.id) : childNodeId,
        // we try to place the child node close to the calculated position from the layout algorithm
        // 450 pixels at right/left side of the parent node, this spacing can be adjusted in the useLayout hook
        position: {
          x: parentNode.position.x + (data.direction === 'right' ? 450 : -450),
          y: parentNode.position.y
        },
        type: type ?? 'workflow',
        data
      };

      // create a placeholder for the new child node
      // we want to display a placeholder for all workflow nodes that do not have a child already
      // as the newly created node will not have a child, it gets this placeholder
      // const childPlaceholderNode = {
      //   id: childPlaceholderId,
      // we place the placeholder 150 pixels below the child node, spacing can be adjusted in the useLayout hook
      //   position: { x: childNode.position.x, y: childNode.position.y + 150 },
      //   type: 'placeholder',
      //   data: { label: '+' }
      // };

      // we need to create a connection from parent to child
      const childEdge = {
        id: `${parentNode.id}=>${childNode.id}`,
        source: parentNode.id,
        target: childNode.id,
        markerEnd:
          data.direction === 'right'
            ? {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20
              }
            : 'arrow',
        markerStart:
          data.direction === 'right'
            ? 'arrow'
            : {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20
              },
        type: 'smoothstep',
        sourceHandle: data.direction === 'left' ? 'source-a' : 'source-b',
        targetHandle: data.direction === 'left' ? 'target-b' : 'target-a'
      };

      // we need to create a connection from child to our placeholder
      // const childPlaceholderEdge = {
      //   id: `${childNodeId}=>${childPlaceholderId}`,
      //   source: childNodeId,
      //   target: childPlaceholderId,
      //   type: 'placeholder'
      // };

      // if the clicked node has had any placeholders as children, we remove them because it will get a child now
      const existingPlaceholders = getOutgoers(parentNode, getNodes(), getEdges())
        .filter(node => node.type === 'placeholder')
        .map(node => node.id);

      // add the new nodes (child and placeholder), filter out the existing placeholder nodes of the clicked node
      setNodes(nodes =>
        nodes
          .filter(node => !existingPlaceholders.includes(node.id))
          // .concat([childNode, childPlaceholderNode])
          .concat([childNode])
      );

      // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
      setEdges(edges =>
        edges
          .filter(edge => !existingPlaceholders.includes(edge.target))
          // .concat([childEdge, childPlaceholderEdge])
          .concat([childEdge])
      );
    },
    [getEdges, getNode, getNodes, id, setEdges, setNodes]
  );

  return onClick;
};

export default useNodeClick;
