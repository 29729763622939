import { apiWrapper } from 'src/config/api-wrapper';
import { marketsDataMapper } from './markets-data-mapper';
import { MarketsData } from './markets-data-model';

class MarketsService {
  public list(): Promise<MarketsData> {
    return apiWrapper.api
      .get('/countries')
      .then(res => res.data)
      .then(data => marketsDataMapper(data));
  }
}

export default new MarketsService();
