import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Button from 'src/components/ui/button';
import MetricsDisplay from 'src/components/ui/metrics-display';
import MetricsFilter from 'src/components/ui/metrics-filter';
import {
  datesOptions as metricsDatesOptions,
  Filter
} from 'src/components/ui/metrics-filter/utils';
import SearchInput from 'src/components/ui/search-input';
import Subtitle from 'src/components/ui/subtitle';
import Title from 'src/components/ui/title';
import { searchMaxLength } from 'src/helpers/search-validators';
import useDataTopic from 'src/resources/data-topic/data-topic-hook';
import { MetricsFilters } from 'src/resources/data-topic/data-topic-types';
import useMarketsData from 'src/resources/markets-data/markets-data-hook';
import DataTopicList from './components/data-topic-list';
import StepInstructions from './components/step-instructions';
import './index.scss';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';

const DashboardScreen: React.FC = () => {
  const navigate = useNavigate();
  const { dataTopics, fetchDataTopics } = useDataTopic();
  const { marketsData, fetchMarketsData } = useMarketsData();
  const { globalDataTopicMetrics, fetchGlobalDataTopicMetrics } = useDataTopic();
  const [searchText, setSearchText] = useState<string>('');
  const [metricsFilterDateName, setMetricsFilterDateName] = useState<string>('');
  const [metricsFilter, setMetricsFilter] = useState<MetricsFilters | null>();

  useEffect(() => {
    if (!marketsData.data) {
      void fetchMarketsData();
    }
  }, [marketsData.data, fetchMarketsData]);

  useEffect(() => {
    void fetchDataTopics();
  }, [fetchDataTopics]);

  useEffect(() => {
    let abortFetchDataTopicMetrics: VoidFunction;

    if (metricsFilter) {
      abortFetchDataTopicMetrics = fetchGlobalDataTopicMetrics(metricsFilter);
    }

    return () => abortFetchDataTopicMetrics?.();
  }, [fetchGlobalDataTopicMetrics, metricsFilter]);

  const handleSearch = (): void => {
    navigate({
      pathname: 'search',
      search: `?s=${searchText}`
    });
  };

  const onMetricsFilterChanges = useCallback((filters: Filter): void => {
    const { market, date } = filters;

    setMetricsFilterDateName(metricsDatesOptions.find(opt => opt.value === date)!.name);

    setMetricsFilter({
      countriesCode: market,
      datePeriod: date
    });
  }, []);

  return (
    <TealiumDataLayer>
      <Container className="dashboard">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Title>Global Metrics</Title>
                <Subtitle>Filter global sales and important metrics for a date range.</Subtitle>
              </Col>
            </Row>
            <ExternalDataRenderer
              externalData={marketsData}
              makeCustomIsLoading={() => !marketsData.data}
              makeDataElement={data => (
                <MetricsFilter
                  className="my-5"
                  marketsData={data}
                  onChange={onMetricsFilterChanges}
                />
              )}
            />
            <Stack direction="horizontal" className="mt-4 justify-content-between">
              <ExternalDataRenderer
                externalData={globalDataTopicMetrics}
                makeDataElement={data =>
                  data.map(metrics => (
                    <MetricsDisplay
                      key={metrics.title}
                      metrics={metrics}
                      previousDataLabel={metricsFilterDateName}
                    />
                  ))
                }
              />
            </Stack>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Title>Open Search Field</Title>
                <Subtitle>
                  Search for logical names, physical names or descriptions from tables
                </Subtitle>
              </Col>
            </Row>
            <Form onSubmit={handleSearch}>
              <Stack className="mt-4" direction="horizontal" gap={3}>
                <div className="flex-grow-1">
                  <SearchInput
                    id="search"
                    required
                    value={searchText}
                    onChange={txt => setSearchText(txt)}
                    placeholder="Type here"
                    maxLength={searchMaxLength}
                  />
                </div>
                <Button>SEARCH</Button>
              </Stack>
            </Form>
          </Card.Body>
          <Card.Body className="data-topics">
            <Row>
              <Col xs={12}>
                <Title>Search by Data Topic</Title>
                <Subtitle>
                  Click to select one of the Data Topics below to see its details.
                </Subtitle>
              </Col>
              <DataTopicList className="mt-4" dataTopics={dataTopics} />
            </Row>
          </Card.Body>
          <Card.Body>
            <Row>
              <Col>
                <Title>How it works</Title>
                <Subtitle>
                  The Data Catalog can be used to view common datasets and metrics that are
                  available in the ACE Platform.
                </Subtitle>
              </Col>
            </Row>
            <Row>
              <StepInstructions
                className="mt-4"
                steps={[
                  {
                    title: 'Global Metrics',
                    description:
                      // eslint-disable-next-line max-len
                      'Global metrics can be filtered to view a specific period. There are also indicators to show if the metrics have increased or decreased based on the time period selected. '
                  },
                  {
                    title: 'Open Search Field',
                    description:
                      'Open text search used to locate specific tables, descriptions or metrics. '
                  },
                  {
                    title: 'Search by Category',
                    description:
                      // eslint-disable-next-line max-len
                      'Search by category will enable users to select a topic to view the common datasets and metrics available for that specific topic. ​'
                  }
                ]}
              />
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </TealiumDataLayer>
  );
};

export default DashboardScreen;
