import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  ProOptions,
  ReactFlowProvider,
  NodeTypes,
  Node,
  Edge,
  MarkerType,
  Controls,
  useReactFlow
} from 'reactflow';
import useLayout from './hooks/useLayout';
import 'reactflow/dist/style.css';
import { hierarchy } from 'd3-hierarchy';
import LoadingComponent from '../loading-component';

interface ReactFlowProps<T> {
  data: T;
  defaultNodes?: Node[];
  defaultEdges?: Edge[];
  nodeTypes: NodeTypes;
  type: string;
  style?: React.CSSProperties;
}

const fitViewOptions = {
  padding: 0.1
};

// remove reactflow logo
const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

const ReactFixedFlow = <T extends {}>(props: ReactFlowProps<T>): JSX.Element => {
  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();

  const { fitView } = useReactFlow();

  useEffect(() => {
    const timeoutFitView = setTimeout(() => {
      fitView(fitViewOptions);
    }, 500);

    if (props.defaultNodes?.length) {
      return () => clearTimeout(timeoutFitView);
    }
  }, [fitView, props.defaultNodes]);

  return (
    <>
      <ReactFlow
        {...props}
        proOptions={proOptions}
        fitView
        fitViewOptions={fitViewOptions}
        minZoom={0.2}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}>
        <Background style={props.style} />
        <Controls />
      </ReactFlow>
    </>
  );
};

const ReactFlowWrapper = <T extends { id: number | null; parents?: T[] | null }>(
  props: ReactFlowProps<T>
): JSX.Element => {
  const defaultNodes: Node[] = useMemo(() => [], []);
  const defaultEdges: Edge[] = useMemo(() => [], []);
  const [didLoadNodes, setLoadNodes] = useState(false);

  const createTree = useCallback((): void => {
    const newNode = (id: number | null, data: T, direction: 'left' | 'right'): Node => ({
      id: String(id ?? 0),
      data: { ...data, direction },
      type: props.type,
      position: { x: 0, y: 0 }
    });

    const newEdge = (
      parentId: number | null,
      childId: number | null,
      direction: 'left' | 'right'
    ): Edge => ({
      id: `${parentId ?? 0}=>${childId ?? 0}`,
      source: String(parentId ?? 0) ?? '',
      target: String(childId ?? 0) ?? '',
      markerEnd:
        direction === 'right'
          ? {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20
            }
          : 'arrow',
      markerStart:
        direction === 'right'
          ? 'arrow'
          : {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20
            },
      type: 'smoothstep',
      sourceHandle: direction === 'left' ? 'source-a' : 'source-b',
      targetHandle: direction === 'left' ? 'target-b' : 'target-a'
    });

    // Create right side tree
    const tree = hierarchy(props.data);
    for (const node of tree.descendants()) {
      defaultNodes.push(newNode(node.data.id, node.data, 'right'));

      if (node.parent) {
        defaultEdges.push(newEdge(node.parent.data.id, node.data.id, 'right'));
      }
    }

    // Create left side tree
    const leftTree = hierarchy(props.data, d => d.parents);
    for (const node of leftTree.descendants()) {
      defaultNodes.push(newNode(node.data.id, node.data, 'left'));

      if (node.parent) {
        defaultEdges.push(newEdge(node.parent.data.id, node.data.id, 'left'));
      }
    }

    setLoadNodes(true);
  }, [defaultEdges, defaultNodes, props.data, props.type]);

  useEffect(() => {
    createTree();
  }, [createTree]);

  return (
    <div style={{ height: '90vh' }}>
      {didLoadNodes ? (
        <ReactFlowProvider>
          <ReactFixedFlow {...props} defaultNodes={defaultNodes} defaultEdges={defaultEdges} />
        </ReactFlowProvider>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default ReactFlowWrapper;
