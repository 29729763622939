import { Permission } from './features-types';
import { apiWrapper } from 'src/config/api-wrapper';
import { permissionsMapper } from './features-mapper';

class PermissionService {
  public list(): Promise<Permission[]> {
    return apiWrapper.api.get('/user/info').then(res => permissionsMapper(res.data.permissions));
  }
}

export default new PermissionService();
