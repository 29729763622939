import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, ListItemIcon, ListItemText, SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

interface FloatingMenuProps {
  anchorEl: null | Element;
  setAnchorEl: (value: null | Element) => void;
  menuTitle?: string;
  menuItems: Array<{
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    label: string;
    action: () => void;
    disabled?: boolean;
  }>;
}

const FloatingMenu: React.FC<FloatingMenuProps> = ({
  anchorEl,
  setAnchorEl,
  menuTitle,
  menuItems
}) => {
  const isMenuOpened = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={isMenuOpened}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}>
      {menuTitle && (
        <MenuItem className="header-floating-menu">
          <ListItemText>
            <b>{menuTitle}</b>
          </ListItemText>
          <CloseIcon onClick={handleClose} fontSize="small" style={{ cursor: 'pointer' }} />
        </MenuItem>
      )}
      {menuTitle && <Divider />}
      {menuItems.map(item => (
        <MenuItem key={item.label} onClick={item.action} disabled={item.disabled}>
          <ListItemIcon>
            <SvgIcon component={item.icon} />
          </ListItemIcon>
          <ListItemText>{item.label}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FloatingMenu;
