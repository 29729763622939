export interface TableChangeRequest {
  id: string;
  requestType: RequestType;
  datasetName: string;
  tableId: string;
  tableName: string;
  currentValue: string;
  newValue: string;
  requestDescription: string;
  runDt: Date;
  status: RequestStatusEnum;
  statusDiagnosis: string;
  reviewedBy: string;
  requestedBy: string;
  requestedDt: Date;
  reviewedDt: Date;
}

export interface PendingTableChangeRequest extends TableChangeRequest {
  status: RequestStatusEnum.Pending;
}

export interface ReviewedTableChangeRequest extends TableChangeRequest {
  status: RequestStatusEnum.Success | RequestStatusEnum.Rejected;
}

export type RequestType =
  | 'RenameColumn'
  | 'RenameTable'
  | 'ChangeColumnType'
  | 'ChangeColumnDescription'
  | 'ChangeTableDescription'
  | 'AddOrUpdateTag';

export interface TableChangeRequestsCounter {
  count: number;
}

export enum RequestStatusEnum {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Failure = 'Failure',
  Success = 'Success'
}

export interface TableChangeRequestPagination {
  offset: number;
  limit: number;
  total: number;
}
