import React from 'react';
import './index.scss';

interface Props {
  className?: string;
  colorVariant?: 'primary' | 'secondary' | 'tertiary';
  sizeVariant?: 'sm' | 'md' | 'lg';
}

const Subtitle: React.FC<Props> = props => {
  const { className = '', colorVariant = 'secondary', sizeVariant = 'sm' } = props;

  return (
    <h2 className={`subtitle ${className} ${colorVariant} ${sizeVariant}`}>{props.children}</h2>
  );
};

export default Subtitle;
