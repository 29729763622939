import React, { CSSProperties } from 'react';
import './index.scss';

interface Props {
  className?: string;
  colorVariant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  sizeVariant?: 'sm' | 'sm-md' | 'md' | 'lg';
  onClick?: VoidFunction;
  // This should be used in a few cases, usually you are going to use size and color variants
  style?: CSSProperties;
}

const Title: React.FC<Props> = props => {
  const { colorVariant = 'tertiary', className = '', sizeVariant = 'md', onClick, style } = props;

  return (
    <h1
      onClick={onClick}
      className={`title ${sizeVariant} ${colorVariant} ${className}`}
      style={style}>
      {props.children}
    </h1>
  );
};

export default Title;
