import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import {
  RequestType,
  RequestStatusEnum
} from 'src/resources/table-change-requests/table-change-requests-model';
import { dateToShortString, dateToTimeString } from 'src/utils/date-utils';

export const requestDescriptionShape = (requestType: RequestType): string => {
  switch (requestType) {
    case 'RenameColumn':
      return 'Schema Name Edit';
    case 'RenameTable':
      return 'Table Name Edit';
    case 'ChangeColumnType':
      return 'Schema Type Edit';
    case 'ChangeColumnDescription':
      return 'Schema Description Edit';
    case 'ChangeTableDescription':
      return 'Dataset Description Edit';
    case 'AddOrUpdateTag':
      return 'Tag Edit or Addition';
    default:
      return 'Unknown Request';
  }
};

interface RequestStatusUi {
  label: string;
  icon: { path: string; color: string };
}

export const toRequestStatusUi = (status: RequestStatusEnum): RequestStatusUi => {
  const options: Record<any, RequestStatusUi> = {
    [RequestStatusEnum.Pending]: {
      label: 'Pending Analysis',
      icon: { path: mdiAlertCircle, color: 'var(--warning-orange)' }
    },
    [RequestStatusEnum.Rejected]: {
      label: 'Rejected',
      icon: { path: mdiAlertCircle, color: 'var(--warning-error)' }
    },
    [RequestStatusEnum.Approved]: {
      label: 'Approved',
      icon: { path: mdiCheckCircle, color: 'var(--warning-success)' }
    },
    [RequestStatusEnum.Failure]: {
      label: 'Failed',
      icon: { path: mdiAlertCircle, color: 'var(--warning-error)' }
    },
    [RequestStatusEnum.Success]: {
      label: 'Applied',
      icon: { path: mdiCheckCircle, color: 'var(--warning-success)' }
    },
    default: {
      label: 'Unknown status',
      icon: { path: mdiAlertCircle, color: 'var(--warning-orange)' }
    }
  };

  return status in options ? options[status] : options.default;
};

export const requesterReviewerFormatter = (username: string, date: Date): string => {
  return `${username} at ${dateToShortString(date)} ${dateToTimeString(date)}`;
};

export const tableNameFormatter = (tableName: string): string => {
  return tableName
    .split('_')
    .reduce(
      (acc, entry) =>
        `${acc.charAt(0).toUpperCase()}${acc.slice(1)} ${entry
          .charAt(0)
          .toUpperCase()}${entry.slice(1)}`
    );
};
