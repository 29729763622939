import React from 'react';
import { Accordion as AccordionBs } from 'react-bootstrap';
import './index.scss';

export interface Props {
  accordionItem: {
    eventKey: string;
    header: React.ReactNode;
    content: React.ReactNode;
    className?: string;
  };
}

const AccordionItem: React.FC<Props> = props => {
  const { accordionItem } = props;
  return (
    <AccordionBs.Item eventKey={accordionItem.eventKey} className={accordionItem.className}>
      <AccordionBs.Header>{accordionItem.header}</AccordionBs.Header>
      <AccordionBs.Body>{accordionItem.content}</AccordionBs.Body>
    </AccordionBs.Item>
  );
};

export default AccordionItem;
