import React from 'react';
import Pagination, { LimitOptions } from 'src/components/ui/pagination';
import { Table } from 'src/resources/table/models/table-model';
import NoResultsFound from '../../../../components/ui/no-results-found';
import DataListItem from './data-list-item';
import './index.scss';

export interface PaginationData {
  offset: number;
  limit: LimitOptions;
  total: number;
}

const noResultsFoundDescription =
  'Filters hasn’t returned results.\n Check the selection on the side and try again.';

interface Props {
  data: Table[];
  pagination: PaginationData;
  onTableClick: (table: Table) => void;
  onPaginationChange: (pagination: PaginationData) => void;
}

const DataList: React.FC<Props> = props => {
  const { data, pagination, onPaginationChange, onTableClick } = props;
  const { limit, offset, total } = pagination;

  return (
    <div className="data-list-container">
      {data.length === 0 ? (
        <NoResultsFound description={noResultsFoundDescription} />
      ) : (
        <ul className="fancy-scrollbar pe-2">
          {data.map(dataItem => (
            <li key={dataItem.id}>
              <DataListItem dataItem={dataItem} onNavigationClick={() => onTableClick(dataItem)} />
            </li>
          ))}
        </ul>
      )}
      <Pagination
        count={total}
        limit={limit}
        page={offset}
        onPaginationChange={({ page, limit }) => onPaginationChange({ offset: page, limit, total })}
      />
    </div>
  );
};

export default DataList;
