import { useState } from 'react';
import Input from 'src/components/ui/input';
import Typography from 'src/components/ui/typography';
import { Table } from 'src/resources/table/models/table-model';
import Dialog from '../../../../../components/core/dialog-layout';
import './index.scss';
import LoadingComponent from 'src/components/core/loading-component';
import TextArea from 'src/components/ui/textArea';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (newValue: string, requestReason: string) => void;
  selectedTable: Table;
  loading: boolean;
}

const EditDatasetDescriptionModal: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  selectedTable,
  loading
}) => {
  const [newValue, setNewValue] = useState('');
  const [requestReason, setRequestReason] = useState('');

  return (
    <Dialog
      open={open}
      title="Edit Dataset Description"
      subtitle="Your change request will be executed after approval."
      scapeActionText="CANCEL"
      confirmActionText={loading ? <LoadingComponent /> : 'REQUEST EDIT'}
      confirmActionHandler={
        newValue.length > 0 && requestReason.length > 0
          ? () => onConfirm(newValue, requestReason)
          : undefined
      }
      scapeActionHandler={onClose}
      maxWidth="xs">
      <div className="edit-dataset-description-main-container">
        <div className="edit-dataset-description-dialog-header">
          <Typography sizeVariant="sm">Current Description:</Typography>
          <Typography sizeVariant="sm" colorVariant="primary">
            {selectedTable.description}
          </Typography>
        </div>
        <div className="mt-5">
          <Typography>
            New Description: <b style={{ color: 'var(--warning-error)' }}>*</b>
          </Typography>
          <TextArea
            className="mt-2"
            placeholder="Type new description"
            onChange={e => setNewValue(e.target.value)}
          />
        </div>
        <Input
          title="Request Reason"
          required
          className="mt-4"
          placeholder="Type your request reason"
          onChange={e => setRequestReason(e.target.value)}
        />
      </div>
    </Dialog>
  );
};

export default EditDatasetDescriptionModal;
