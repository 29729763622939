import React from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

export interface Props {
  title?: string;
  value?: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  rows?: number;
}

const TextArea: React.FC<Props> = props => {
  const { title, value, onChange, placeholder, className, rows = 4 } = props;

  return (
    <Form.Group className={`our-input-block ${className}`}>
      {title && <Form.Label>{title}</Form.Label>}
      <Form.Control
        className={'our-input'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        as="textarea"
        rows={rows}
      />
    </Form.Group>
  );
};

export default TextArea;
