import {
  TableChangeRequest,
  TableChangeRequestPagination,
  TableChangeRequestsCounter
} from './table-change-requests-model';

export const tableChangeRequestsMapper = (apiData: any[]): TableChangeRequest[] => {
  // here we will shape api data to our format
  return apiData.map(
    (data: any): TableChangeRequest => ({
      id: data.id,
      requestType: data.requestType,
      datasetName: data.datasetName,
      tableId: data.tableId,
      tableName: data.tableName,
      currentValue: data.currentValue ?? '',
      newValue: data.newValue ?? '',
      requestDescription: data.requestDescription,
      runDt: new Date(data.runDt),
      status: data.status,
      statusDiagnosis: data.statusDiagnosis,
      reviewedBy: data.reviewedBy,
      requestedBy: data.requestedBy,
      requestedDt: new Date(data.requestedDt),
      reviewedDt: new Date(data.reviewedDt)
    })
  );
};

export const tableChangeRequestPaginationMapper = (apiData: any): TableChangeRequestPagination => {
  return {
    limit: apiData.pageable.pageSize,
    offset: apiData.pageable.offset,
    total: apiData.totalElements
  };
};

export const tableChangeRequestsCounterMapper = (apiData: any): TableChangeRequestsCounter => {
  return {
    count: apiData.totalElements
  };
};
