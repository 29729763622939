import { createContext } from 'react';

export interface Breadcrumb {
  label: string;
  path: string;
}

export interface BreadcrumbsType {
  breadcrumbs: Breadcrumb[];
  updatePreviousBreadcrumb: (label: string, newPath: string) => void;
}

const breadcrumbsContext = createContext<BreadcrumbsType>(null as any);

export default breadcrumbsContext;
