import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiLogoutVariant,
  mdiPlaylistPlus,
  mdiSpeedometer,
  mdiFileUpload
} from '@mdi/js';
import React, { useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { To, useLocation } from 'react-router-dom';
import { Feature } from 'src/config/features';
import { authenticatedRoutes } from 'src/config/routes';
import useFeatures from 'src/resources/features/features-hook';
import IconButton from '../../ui/icon-btn';
import './index.scss';
import SideNavLinkComponent, { Link } from './side-nav-link';

interface Props {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
}

type LinkWithoutIsActive = Omit<Link, 'isActive'>;

const links: LinkWithoutIsActive[] = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    eventKey: 'dashboard',
    icon: mdiSpeedometer
  },
  {
    to: '/requested-changes',
    label: 'Requests List',
    eventKey: 'requested-changes',
    icon: mdiPlaylistPlus
  },
  {
    to: '/upload-looker-studio-logs',
    label: 'Upload Looker Studio Logs',
    eventKey: 'upload-looker-studio-logs',
    icon: mdiFileUpload
  }
];

const isLinkActive = (pathname: string, to: To): boolean => {
  return pathname.startsWith(to as string);
};

const filterAllowedLinks = (
  hasFeature: (resourceFeature: Feature) => boolean
): LinkWithoutIsActive[] => {
  return links.filter(link => {
    const linkRoute = authenticatedRoutes.find(route => route.path === link.to)!;

    return hasFeature(linkRoute.feature);
  });
};

const SideNavComponent: React.FC<Props> = ({ open, onLogout, toggleMenu }) => {
  const location = useLocation();
  const { hasFeature } = useFeatures();
  const allowedLinks = useMemo(() => filterAllowedLinks(hasFeature), [hasFeature]);

  return (
    <Nav as="aside" id="aside" className={open ? 'open' : 'closed'}>
      <IconButton
        id="open-close-menu"
        mdiIconPath={open ? mdiChevronDoubleLeft : mdiChevronDoubleRight}
        onClick={toggleMenu}
        size="24px"
        color="var(--primary)"
        tooltip="Open menu">
        {open ? <>Close menu</> : undefined}
      </IconButton>
      {allowedLinks.map(link => (
        <SideNavLinkComponent
          id={`nav-link-${link.eventKey}`}
          isActive={isLinkActive(location.pathname, link.to)}
          key={link.eventKey}
          open={open}
          {...link}
        />
      ))}
      <IconButton
        id="logout"
        mdiIconPath={mdiLogoutVariant}
        onClick={onLogout}
        size="24px"
        color="#FF4949"
        tooltip="Logout">
        {open ? <>Logout</> : undefined}
      </IconButton>
    </Nav>
  );
};

export default SideNavComponent;
