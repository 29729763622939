import { mdiMenuUp } from '@mdi/js';
import Icon from '@mdi/react';
import React, { CSSProperties } from 'react';
import './index.scss';

export enum SortEnum {
  SAME,
  ASC,
  DESC
}

interface Props {
  title: string;
  sortable?: {
    order: SortEnum;
    onSort: (header: string, order: SortEnum) => void;
  };
  style?: CSSProperties;
}

export const BasicTableHead: React.FC<Props> = ({ title, style }) => {
  return (
    <th scope="col" style={style}>
      {title}
    </th>
  );
};

const getFancyTHeadClassName = (sortEnum: SortEnum): string => {
  if (sortEnum === SortEnum.SAME) return 'same';
  else if (sortEnum === SortEnum.ASC) return 'asc';
  return 'desc';
};

export const FancyTableHead: React.FC<Required<Props>> = ({ title, sortable, style }) => {
  const handleClick = (): void => {
    let nextOrder;

    if (sortable.order === SortEnum.SAME) nextOrder = SortEnum.ASC;
    else if (sortable.order === SortEnum.ASC) nextOrder = SortEnum.DESC;
    else nextOrder = SortEnum.SAME;

    sortable.onSort(title, nextOrder);
  };

  return (
    <th
      scope="col"
      onClick={handleClick}
      className={`fancy-thead ${getFancyTHeadClassName(sortable.order)}`}
      style={style}>
      {title}
      <Icon className="order-icon" size={'24px'} path={mdiMenuUp} />
    </th>
  );
};

export const TableHead: React.FC<Props> = (props: Props) => {
  if (props.sortable) {
    return <FancyTableHead {...(props as Required<Props>)} />;
  }

  return <BasicTableHead {...props} />;
};

export default TableHead;
