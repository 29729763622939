export interface Feature {
  label: string;
}

const FEATURES = {
  DashboardScreen: {
    label: 'Dashboard',
    DataTopicsScreen: {
      label: 'DataTopics'
    },
    SearchResultsScreen: {
      label: 'SearchResults',
      DataSetDetailsScreen: {
        label: 'DataSetDetails',
        TableDescriptionEdition: {
          label: 'TableDescriptionEdition'
        },
        PendingChangeRequestsDisplay: {
          label: 'PendingChangeRequestsDisplay'
        },
        SchemaTableEdition: {
          label: 'SchemaTableEdition'
        },
        TagsTableEdition: {
          label: 'TagsTableEdition'
        },
        DataLineageEdition: {
          label: 'DataLineageEdition'
        },
        DataDistributionScreen: {
          label: 'DataDistribution',
          RefreshDataDistribution: {
            label: 'RefreshDataDistribution'
          }
        }
      }
    }
  },
  RequestedChangesScreen: {
    label: 'RequestedChanges',
    ReviewChangeRequests: {
      label: 'ReviewChangeRequests'
    },
    ManageFeedbacks: {
      label: 'ManageFeedbacks'
    }
  },
  UploadLookerStudioLogs: {
    label: 'UploadLookerStudioLogs'
  }
};

export default FEATURES;
