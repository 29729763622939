import { AppBar, Tabs, Tab } from '@mui/material';
import './index.scss';

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
};

interface TopMenuNavProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  topMenuLabels: string[];
  className?: string;
}

const TopMenuNav = (props: TopMenuNavProps): JSX.Element => {
  const { value, handleChange, topMenuLabels, className = '' } = props;

  return (
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        className={className}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth">
        {topMenuLabels.map((label, index) => (
          <Tab key={label} label={label} {...a11yProps(index)} />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default TopMenuNav;
