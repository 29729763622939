import { createContext } from 'react';
import { Market, MarketAffiliate } from '../markets-data/markets-data-model';
import { GlobalSettings } from './global-settings-model';

export interface GlobalSettingsContextType {
  setMarketOptions: (affiliates: MarketAffiliate['code'], markets: Array<Market['code']>) => void;
  settings: GlobalSettings;
}

const globalSettingsContext = createContext<GlobalSettingsContextType>(null as any);

export default globalSettingsContext;
