import React, { SyntheticEvent, useState } from 'react';
import './index.scss';
import TopMenuNav from './top-menu-nav';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

interface TopMenuTabsProps {
  topMenuItems: Array<{ label: string; component: JSX.Element }>;
  onTabChange?: (label: string) => void;
}

const TopMenuTabs = ({ topMenuItems, onTabChange }: TopMenuTabsProps): JSX.Element => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue);
    onTabChange?.(topMenuItems[newValue].label);
  };

  return (
    <div className="top-nav-container">
      <TopMenuNav
        value={value}
        className="top-nav-header"
        handleChange={handleChange}
        topMenuLabels={topMenuItems.map(item => item.label)}
      />
      <div className="top-nav-content-container pt-3">
        {topMenuItems.map((item, index) => (
          <TabPanel key={item.label} value={value} index={index}>
            {item.component}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default TopMenuTabs;
