import React from 'react';
import './index.scss';

interface Props {
  className?: string;
}

const Hr: React.FC<Props> = props => {
  const { className } = props;
  return <hr className={`separation-line ${className}`}></hr>;
};

export default Hr;
