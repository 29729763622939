import { useEffect, useState } from 'react';
import { ExternalData } from 'src/@types/external-api';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Accordion from 'src/components/ui/accordion';
import NoResultsFound from 'src/components/ui/no-results-found';
import { PaginationData } from 'src/components/ui/pagination';
import useIntersectElement from 'src/hooks/use-intersect-element';
import { Feedback } from 'src/resources/feedback/feedback-model';
import { ListResult } from 'src/resources/feedback/feedback-types';
import FeedbackItem from '../feedback-item';

interface Props {
  data: ExternalData<ListResult<Feedback>>;
  onPaginationChange: (newPaginationData: PaginationData) => void;
}

const FeedbackList: React.FC<Props> = props => {
  const { data: externalData, onPaginationChange } = props;
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const canLoadMore = externalData.data
    ? externalData.data.pagination.limit < externalData.data.pagination.total
    : false;
  const intersectElement = useIntersectElement(() => {
    if (!externalData.loading && canLoadMore && externalData.data && !isFetchingMore) {
      setIsFetchingMore(true);
      onPaginationChange({
        page: externalData.data.pagination.offset,
        limit: (externalData.data.pagination.limit + externalData.data.pagination.limit) as any
      });
    }
  });

  useEffect(() => {
    if (!externalData.loading) {
      setIsFetchingMore(false);
    }
  }, [externalData.loading]);

  return (
    <ExternalDataRenderer
      externalData={externalData}
      makeCustomIsLoading={() => !!(externalData.loading && !isFetchingMore)}
      makeDataElement={data => (
        <section className="feedback-list-container fancy-scrollbar">
          {data.feedbacks.length === 0 ? (
            <NoResultsFound description="Filters hasn't returned results" />
          ) : (
            <>
              <Accordion
                accordionItems={data.feedbacks.map(feedback => ({
                  key: feedback.id,
                  data: FeedbackItem(feedback)
                }))}
              />
              {canLoadMore && intersectElement}
            </>
          )}
        </section>
      )}
    />
  );
};

export default FeedbackList;
