import React from 'react';
import FacetItem from '../facet-item';
import { Facet } from '../types';

interface Props {
  facets: Facet[];
  onChange: (facetsState: Facet[]) => void;
}

const FacetsList: React.FC<Props> = props => {
  const { facets, onChange } = props;

  function handleOnChange(facetName: string, facetValues: Facet['values']): void {
    onChange(
      facets.map(facet => ({
        ...facet,
        values: facet.name === facetName ? facetValues : facet.values
      }))
    );
  }

  return (
    <article>
      {facets.map(facet => (
        <FacetItem
          key={facet.name}
          // name={facet.name}
          // TEMPORARY HARD-CODED SOLUTION
          name={facet.name === 'dataTopic_s' ? 'Data Topic' : facet.name}
          values={facet.values}
          kind={facet.kind}
          onChange={values => handleOnChange(facet.name, values)}
        />
      ))}
    </article>
  );
};

export default FacetsList;
