import { array, ease, Root, Scrollbar, Tooltip } from '@amcharts/amcharts5';
import { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import {
  AxisRendererX,
  CategoryAxis,
  ColumnSeries,
  XYChart,
  XYCursor
} from '@amcharts/amcharts5/xy';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { Scale } from 'src/resources/data-topic/metrics-details/models';
import { withTheme } from '../shared/helpers/theme';
import { makeYAxis } from '../shared/helpers/yAxis';
import { Metrics } from '../shared/types';
import './index.scss';

interface Props {
  className?: string;
  style?: CSSProperties;
  data: Metrics[];
  scale?: Scale;
}

export default function VerticalBarChart(props: Props): JSX.Element {
  const { style, data, className = '', scale = Scale.Linear } = props;
  const [root, setRoot] = useState<Root>();
  const ref = useRef<HTMLDivElement>(null);

  // Get series item by category
  const getSeriesItem = useCallback((series: ColumnSeries, category: string) => {
    for (let i = 0; i < series.dataItems.length; i++) {
      const dataItem = series.dataItems[i];
      if (dataItem.get('categoryX') === category) {
        return dataItem;
      }
    }
  }, []);

  // Axis sorting
  const sortCategoryAxis = useCallback(
    (series: ColumnSeries, xAxis: CategoryAxis<AxisRenderer>) => {
      // Sort by value
      series.dataItems.sort((a, b) => {
        const aValue = a.get('valueY') ?? 0;
        const bValue = b.get('valueY') ?? 0;
        return bValue - aValue; // ascending
      });

      // Go through each axis item
      array.each(xAxis.dataItems, dataItem => {
        // get corresponding series item
        const seriesDataItem = getSeriesItem(series, dataItem.get('category')!);

        if (seriesDataItem) {
          // get index of series data item
          const index = series.dataItems.indexOf(seriesDataItem);
          // calculate delta position
          const deltaPosition = (index - dataItem.get('index', 0)) / series.dataItems.length;
          // set index to be the same as series data item index
          dataItem.set('index', index);
          // set deltaPosition instanlty
          dataItem.set('deltaPosition', -deltaPosition);
          // animate delta position to 0
          dataItem.animate({
            key: 'deltaPosition',
            to: 0,
            duration: 1000,
            easing: ease.out(ease.cubic)
          });
        }
      });

      // Sort axis items by index.
      // This changes the order instantly, but as deltaPosition is set,
      // they keep in the same places and then animate to true positions.
      xAxis.dataItems.sort((x, y) => {
        const xIndex = x.get('index') ?? 0;
        const yIndex = y.get('index') ?? 0;
        return xIndex - yIndex;
      });
    },
    [getSeriesItem]
  );

  useEffect(() => {
    if (!root && ref.current) {
      setRoot(Root.new(ref.current));
    }
  }, [ref, root]);

  useEffect(() => {
    if (root && data.length) {
      withTheme(root);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'none',
          wheelY: 'zoomY',
          wheelZoomPositionY: 0
        })
      );

      // Add cursor
      const cursor = chart.set(
        'cursor',
        XYCursor.new(root, {
          behavior: 'none'
        })
      );

      cursor.lineY.set('visible', false);

      // Add scrollbar
      chart.set(
        'scrollbarY',
        Scrollbar.new(root, {
          orientation: 'vertical'
        })
      );

      const xRenderer = AxisRendererX.new(root, {
        minGridDistance: 30
      });

      const xAxis = chart.xAxes.push(
        CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: 'key',
          renderer: xRenderer,
          tooltip: Tooltip.new(root, { themeTags: ['axis'] })
        })
      );

      const yAxis = makeYAxis(root, chart, scale);

      const series = chart.series.push(
        ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis,
          yAxis,
          categoryXField: 'key',
          valueYField: 'value',
          tooltip: Tooltip.new(root, {
            pointerOrientation: 'left',
            labelText: '{valueY}'
          })
        })
      );

      chart.set(
        'cursor',
        XYCursor.new(root, {
          behavior: 'none',
          xAxis,
          yAxis
        })
      );

      xAxis.data.setAll(data);
      series.data.setAll(data);
      sortCategoryAxis(series, xAxis);

      // Make stuff animate on load
      void series.appear(1000);
      void chart.appear(1000, 100);

      return () => chart.dispose();
    }
  }, [sortCategoryAxis, data, root, scale]);

  return <div ref={ref} className={`dashboard-sorted-bar-chart ${className}`} style={style} />;
}
