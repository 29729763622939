export const makeUrlWithParams = (
  baseUrl: string,
  dataObject: Record<string, string | number | undefined>
): string => {
  const queryParams = Object.entries(dataObject)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&');

  return `${baseUrl}${queryParams ? `?${queryParams}` : ''}`;
};
