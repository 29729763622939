import Form from 'react-bootstrap/Form';
import './index.scss';

export interface Option {
  name: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface OptionGroup {
  label: string;
  options: Option[];
}

type OptionOrOptionGroup = Option | OptionGroup;
export interface Props {
  id?: string;
  title?: string;
  variant?: 'primary' | 'secondary';
  size?: 'sm' | 'lg';
  placeholder?: string;
  className?: string;
  options: OptionOrOptionGroup[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value?: string;
  required?: boolean;
}

export const isOptionGroup = (optionOrOptionGroup: OptionOrOptionGroup): boolean => {
  return 'options' in optionOrOptionGroup;
};

const Select: React.FC<Props> = (props: Props) => {
  const {
    id,
    title,
    options,
    onChange,
    value,
    variant = 'secondary',
    size = 'sm',
    className = '',
    placeholder,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    required = false
  } = props;

  return (
    <Form.Group className={`our-select-block ${variant} ${size} ${className}`}>
      {title && (
        <Form.Label>
          {title} {required && <b style={{ color: 'var(--warning-error)' }}>*</b>}
        </Form.Label>
      )}
      <Form.Select
        id={id}
        value={value}
        onChange={onChange}
        placeholder="placeholder"
        className={'our-select'}
        aria-label={title}
        size={size}
        required={required}>
        {placeholder && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {options.map(optionOrOptionGroup =>
          isOptionGroup(optionOrOptionGroup) ? (
            <optgroup
              key={(optionOrOptionGroup as OptionGroup).label}
              label={(optionOrOptionGroup as OptionGroup).label}>
              {(optionOrOptionGroup as OptionGroup).options.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.selected}
                  disabled={option.disabled}>
                  {option.name}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              key={(optionOrOptionGroup as Option).value}
              value={(optionOrOptionGroup as Option).value}
              selected={(optionOrOptionGroup as Option).selected}
              disabled={(optionOrOptionGroup as Option).disabled}>
              {(optionOrOptionGroup as Option).name}
            </option>
          )
        )}
      </Form.Select>
    </Form.Group>
  );
};

export default Select;
