import { createContext } from 'react';
import { ExternalAction, ExternalData } from 'src/@types/external-api';
import {
  PendingTableChangeRequest,
  ReviewedTableChangeRequest,
  TableChangeRequest,
  TableChangeRequestsCounter
} from 'src/resources/table-change-requests/table-change-requests-model';
import {
  CreateChangeRequestParams,
  CreateChangeRequestReviewParams,
  FetchTableChangeRequestsWithoutStatusParams,
  FetchTableChangeRequestsParams,
  ListResult
} from './table-change-requests-types';

export interface TableChangeRequestsType {
  tableChangeRequests: ExternalData<ListResult<TableChangeRequest>>;
  pendingChangeRequestsCounter: ExternalData<TableChangeRequestsCounter>;
  pendingTableChangeRequests: ExternalData<ListResult<PendingTableChangeRequest>>;
  reviewedTableChangeRequests: ExternalData<ListResult<ReviewedTableChangeRequest>>;
  createdRequest: ExternalAction;
  createdReview: ExternalAction;
  datasetsWithRequests: ExternalData<string[]>;
  createRequest: (params: CreateChangeRequestParams) => Promise<void>;
  createReview: (params: CreateChangeRequestReviewParams) => Promise<void>;
  fetchTableChangeRequests: (params?: FetchTableChangeRequestsParams) => Promise<void>;
  fetchPendingTableChangeRequests: (
    params?: FetchTableChangeRequestsWithoutStatusParams
  ) => Promise<void>;
  fetchReviewedTableChangeRequests: (
    params?: FetchTableChangeRequestsWithoutStatusParams
  ) => Promise<void>;
  fetchPendingChangeRequestsCounter: (tableName: string) => Promise<void>;
  fetchDatasetsWithRequests: () => Promise<void>;
}

const tableChangeRequestsContext = createContext<TableChangeRequestsType>(null as any);

export default tableChangeRequestsContext;
