import { useCallback, useState } from 'react';
import GlobalSettingsContext, { GlobalSettingsContextType } from './global-settings-context';
import { DefaultOptionsSettings } from './global-settings-model';

const GlobalSettingsProvider: React.FC = ({ children }) => {
  const [defaultOptions, setDefaultOptions] = useState<DefaultOptionsSettings>({});

  const setMarketOptions: GlobalSettingsContextType['setMarketOptions'] = useCallback(
    (marketAffiliateCode, marketCodes) => {
      setDefaultOptions(prev => ({ ...prev, marketAffiliateCode, marketCodes }));
    },
    []
  );

  return (
    <GlobalSettingsContext.Provider
      value={{
        setMarketOptions,
        settings: { defaultOptions }
      }}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export default GlobalSettingsProvider;
