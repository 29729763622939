import React from 'react';
import { Stack } from 'react-bootstrap';
import './index.scss';
import ListItem, { Props as ListItemProps } from './metadata-list-item';

interface Props {
  items: ListItemProps[];
}

const MetadataList: React.FC<Props> = (props: Props) => {
  const { items } = props;

  return (
    <Stack direction="horizontal" className="justify-content-space-between">
      {items.map(item => (
        <ListItem key={item.title} {...item} />
      ))}
    </Stack>
  );
};

export default MetadataList;
