import EditIcon from '@mui/icons-material/Edit';
import { Download as DownloadIcon } from '@mui/icons-material';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PseudoLink from 'src/components/ui/pseudo-link';
import Typography from 'src/components/ui/typography';
import useIntersectElement from 'src/hooks/use-intersect-element';
import { downloadCSV } from 'src/utils/csv-utils';
import './index.scss';
import ShowMore from 'react-show-more-button';
import SearchInput from 'src/components/ui/search-input';
import TableHead, { SortEnum } from './table-head';
import { filterRows, parseTableDataToCSV, sortRows } from './table-helpers';
import { CellType, ColumnDataType, TableDownloadOptions, TableRow } from './table-types';
import { PaginationData } from 'src/components/ui/pagination';
import ReactDOM from 'react-dom';
import LogoLoadingComponent from 'src/components/core/logo-loading-component';

export interface TableProps {
  description?: string;
  headers: string[];
  // this enables a better sorting, i.g we can sort a number as a number instead of string
  dataTypes?: ColumnDataType[];
  rows: TableRow[];
  downloadOptions?: TableDownloadOptions;
  hasInfiniteScroll?: boolean;
  className?: string;
  fixedHeight?: 'sm' | 'md' | 'lg';
  onEdit?: (rowData: CellType[]) => void;
  isExpandable?: boolean;
  hasTopScrollbar?: boolean;
  isSortable?: boolean;
  hasSearchBar?: boolean;
  parentId?: string;
  parentRef?: RefObject<HTMLDivElement>;
}

const Table: React.FC<TableProps> = props => {
  const {
    description,
    headers,
    rows,
    downloadOptions,
    hasInfiniteScroll = false,
    className,
    fixedHeight,
    onEdit,
    dataTypes,
    isExpandable = false,
    isSortable = false,
    hasSearchBar = false,
    parentId,
    parentRef
  } = props;
  const [isRowExpanded, setIsRowExpanded] = useState<number[]>([]);
  const [sortState, setSortState] = useState<SortEnum[]>([]);
  const [sortedRows, setSortedRows] = useState<TableRow[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true);
  const isHeadless = useMemo(
    () => !description && !downloadOptions,
    [description, downloadOptions]
  );
  const [pagination, setPagination] = useState<PaginationData>({
    limit: 20,
    page: 0
  });
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isTableRightSideVisible, setIsTableRightSideVisible] = useState(true);

  const [isLoadingTable, setLoadingTable] = useState(true);

  useEffect(() => {
    // this code resets the sorting state as soon as we get more data
    // the under one keeps that state
    // honestly, idk what is better, maybe this one.
    setSortState(new Array(headers.length).fill(SortEnum.SAME));

    // setSortState(preSortState => {
    //   if (preSortState.length === headers.length) return preSortState;

    //   return new Array(headers.length).fill(SortEnum.SAME);
    // });
  }, [headers]);

  const handleDownloadAsCsv = useCallback(() => {
    downloadCSV(
      downloadOptions!.fileName,
      parseTableDataToCSV(headers, downloadOptions?.data ?? rows)
    );
  }, [downloadOptions, headers, rows]);

  const handleExpandRow = (index: number, showValue: boolean): void => {
    !showValue
      ? setIsRowExpanded(prev => [...prev, index])
      : setIsRowExpanded(prev => prev.filter(row => row !== index));
  };

  const handleColumnSort = useCallback(
    (columnHeader: string, order: SortEnum): void => {
      const headerIdx = headers.indexOf(columnHeader);
      setSortState(prevSortState => {
        return prevSortState.map((_, idx) => (idx === headerIdx ? order : SortEnum.SAME));
      });
    },
    [headers]
  );

  const handleSearchInputChanges = (text: string): void => {
    setSearchText(text);
  };

  const paginatedTableColumns = useMemo(() => {
    const offset = pagination.page * pagination.limit;
    return rows.slice(0, offset + pagination.limit);
  }, [rows, pagination]);

  const handleShouldFetch = useCallback((): boolean => {
    const hasNoMoreData = pagination.page * pagination.limit >= rows.length;
    if (hasNoMoreData) return false;

    setPagination({ limit: pagination.limit, page: pagination.page + 1 });

    return true;
  }, [pagination.limit, pagination.page, rows.length]);

  const handleReachedTableBottom = (): void => {
    if (!hasInfiniteScroll || !canFetchMore) return;

    setCanFetchMore(handleShouldFetch);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyFilters = useCallback(() => {
    const tableRows = hasInfiniteScroll ? paginatedTableColumns : rows;
    setSortedRows(filterRows(sortRows(tableRows, sortState, dataTypes), searchText));
  }, [dataTypes, hasInfiniteScroll, paginatedTableColumns, rows, searchText, sortState]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleHeaderVisible = (): void => {
    setIsHeaderVisible(true);
  };

  const handleHeaderNotVisible = (): void => {
    setIsHeaderVisible(false);
  };

  const observedElementBottom = useIntersectElement(handleReachedTableBottom);
  const headerVisible = useIntersectElement(handleHeaderVisible, handleHeaderNotVisible);

  const generateClickbleCell = (rowData: TableRow, item: CellType): JSX.Element => {
    if (rowData.onClick) {
      return <PseudoLink onClick={() => rowData.onClick!(rowData.data)}>{item}</PseudoLink>;
    } else {
      return (
        <a href={rowData.openUrl} target="_blank" rel="noreferrer">
          {item}
        </a>
      );
    }
  };

  const tableParent = useCallback(() => {
    return document.getElementById(parentId ?? '');
  }, [parentId]);

  const tableRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const replicateParentScrollMotion = (e: any): void => {
      if (e) {
        tableRef!.current?.scrollTo({
          left: e.target?.scrollLeft
        });
      }
    };

    const replicateTableScrollMotion = (e: any): void => {
      if (e) {
        parentRef!.current?.scrollTo({
          left: e.target?.scrollLeft
        });
        if (
          Number(e.target?.scrollWidth - e.target?.scrollLeft) <=
          Number(e.target?.clientWidth) + 100
        ) {
          setIsTableRightSideVisible(true);
        } else {
          setIsTableRightSideVisible(false);
        }
      }
    };

    if (parentRef && tableRef && !isLoadingTable) {
      parentRef!.current?.addEventListener('scroll', replicateParentScrollMotion);
      tableRef!.current?.addEventListener('scroll', replicateTableScrollMotion);

      // Sync the scroll position to add the blurred bar if its necessary
      tableRef!.current?.scrollTo({
        left: tableRef!.current.scrollLeft + 1
      });
    }

    return () => {
      if (!isLoadingTable) {
        parentRef!.current?.removeEventListener('scroll', replicateParentScrollMotion);
        tableRef!.current?.removeEventListener('scroll', replicateTableScrollMotion);
      }
    };
  }, [isLoadingTable, parentRef]);

  // this useEffect is used to sync both scrolls when the sticky header appear
  useEffect(() => {
    if (!isHeaderVisible && parentRef && tableRef) {
      tableRef!.current?.scrollTo({
        left: tableRef!.current.scrollLeft + 1
      });
      tableRef!.current?.scrollTo({
        left: tableRef!.current.scrollLeft - 1
      });
    }
  }, [isHeaderVisible, parentRef]);

  // Timer to first load all refs and listeners before show to user to avoid glitches
  useEffect(() => {
    const loadTableTime = setTimeout(() => {
      setLoadingTable(false);
    }, 1000);
    return () => clearTimeout(loadTableTime);
  }, []);

  const TableHeader = (): JSX.Element => (
    <>
      <thead
        // this styles need to be inline to be inserted on portal
        style={{
          width: '100%',
          background: 'var(--primary)',
          color: 'var(--bg-white)',
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '19px'
        }}>
        <tr>
          {headers.map((header, idx) => (
            <TableHead
              style={{ padding: '16px' }}
              key={header}
              sortable={
                isSortable
                  ? {
                      order: sortState[idx]!,
                      onSort: handleColumnSort
                    }
                  : undefined
              }
              title={header}
            />
          ))}
          {onEdit && (
            <th scope="col" style={{ padding: '16px' }}>
              Edit
            </th>
          )}
        </tr>
      </thead>
    </>
  );

  const TableBody = (props?: any): JSX.Element => (
    <>
      <tbody>
        {sortedRows.length > 0 ? (
          <>
            {sortedRows.map((rowData, index) => (
              <tr key={rowData.data[0]}>
                {rowData.data.map((item, idx) =>
                  (rowData.onClick !== undefined || rowData.openUrl !== undefined) && idx === 1 ? (
                    <td key={item}>{generateClickbleCell(rowData, item)}</td>
                  ) : (
                    <td key={`${rowData.data[0]}-${idx}`}>
                      {isExpandable ? (
                        <ShowMore
                          classNameButtonDiv="show-more-button"
                          maxHeight={150}
                          button={
                            <PseudoLink variant="tertiary">
                              Show {isRowExpanded.includes(index) ? 'less' : 'more'}
                            </PseudoLink>
                          }
                          onChange={(showValue: boolean) => handleExpandRow(index, showValue)}>
                          <span>{item}</span>
                        </ShowMore>
                      ) : (
                        item
                      )}
                    </td>
                  )
                )}
                {onEdit && (
                  <td align="right">
                    <EditIcon fontSize="small" onClick={() => onEdit(rowData.data)} />
                  </td>
                )}
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td>
              <span>Empty</span>
            </td>
          </tr>
        )}
        {!props?.inserted && <tr>{observedElementBottom}</tr>}
      </tbody>
    </>
  );

  const InsertedHeaderComponent: React.FC = () => {
    return tableParent() ? (
      ReactDOM.createPortal(
        <table>
          <TableHeader />
          {/* tableBody need to be inserted to keep the header width */}
          <TableBody inserted />
        </table>,
        tableParent()!
      )
    ) : (
      <div id="no-portal" />
    );
  };

  const stickyHeaderShouldAppear = useCallback(() => {
    // this will avoid the sticky header to appear if the fixed header is not visible beneath the screen
    return !isHeaderVisible && window.scrollY > 400;
  }, [isHeaderVisible]);

  return (
    <div className={`table-container ${isHeadless ? 'headless' : ''} ${className}`}>
      <header className="table-header">
        {description && <Typography colorVariant="secondary">{description}</Typography>}
      </header>
      <article className="table-content-container">
        <div className="table-search-download-container">
          {hasSearchBar && (
            <section className="table-controls">
              <SearchInput
                id="table-search"
                value={searchText}
                onChange={handleSearchInputChanges}
                placeholder="Type here to search"
                className="table-search"
              />
            </section>
          )}
          {downloadOptions && (
            <DownloadIcon
              fontSize="small"
              onClick={handleDownloadAsCsv}
              className="download-icon"
            />
          )}
        </div>

        {headerVisible}
        <section className={`scrollable-table fancy-scrollbar ${fixedHeight}`} ref={tableRef}>
          <div className={`blurred-bar ${isTableRightSideVisible && 'not-visible'}`} />
          {!isLoadingTable ? (
            <table>
              {stickyHeaderShouldAppear() && <InsertedHeaderComponent />}
              <TableHeader />
              {TableBody()}
            </table>
          ) : (
            <LogoLoadingComponent />
          )}
        </section>
      </article>
    </div>
  );
};

export default Table;
