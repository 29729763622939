import { useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import LoadingComponent from 'src/components/core/loading-component';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';
import Button from 'src/components/ui/button';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import tableDataService from 'src/resources/table/table.service';
import useToasts from 'src/resources/toasts/toasts-hook';

const UploadLookerStudioLogs: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [isLoading, setLoading] = useState(false);
  const toasts = useToasts();

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedFile(event?.target?.files ? event?.target?.files[0] : null);
  };

  const handleConfirmUpload = (): void => {
    if (selectedFile) {
      setLoading(true);
      void tableDataService
        .updateLookerStudioReports(selectedFile)
        .then(() => {
          toasts.push(
            'File uploaded successfully, changes will take effect in up to 40 minutes, you can leave this screen.',
            'success',
            10000
          );
        })
        .catch(() => {
          toasts.push('Failed to upload file, please try again.', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <TealiumDataLayer>
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Title className="mb-2">Upload Looker Studio Logs</Title>
              </Col>
            </Row>
            <Row className="mt-3">
              <Stack direction="vertical" gap={1}>
                <input type="file" accept=".csv" onChange={handleUploadFile} />
                <Typography>
                  Please upload the logs with .csv format, then click on upload.
                </Typography>
              </Stack>
            </Row>
            <Button
              className="mt-3"
              disabled={!selectedFile && isLoading}
              onClick={handleConfirmUpload}>
              {isLoading ? <LoadingComponent /> : 'Upload'}
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </TealiumDataLayer>
  );
};

export default UploadLookerStudioLogs;
