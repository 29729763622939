import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import WithProviders from './components/hocs/with-providers';
import AuthProvider from './resources/auth/auth-provider';
import DataTopicProvider from './resources/data-topic/data-topic-provider';
import FeaturesProvider from './resources/features/features-provider';
import FeedbackProvider from './resources/feedback/feedback-provider';
import GlobalSettingsProvider from './resources/global-settings/global-settings-provider';
import MarketsDataProvider from './resources/markets-data/markets-data-provider';
import TableChangeRequestsProvider from './resources/table-change-requests/table-change-requests-provider';
import TableProvider from './resources/table/table-provider';
import ToastProvider from './resources/toasts/toasts-provider';
import Router from './router';

const App: React.FC = () => {
  return (
    <WithProviders
      Providers={[
        BrowserRouter,
        ToastProvider,
        GlobalSettingsProvider,
        AuthProvider,
        FeaturesProvider,
        FeedbackProvider,
        TableProvider,
        TableChangeRequestsProvider,
        MarketsDataProvider,
        DataTopicProvider
      ]}>
      <Router />
    </WithProviders>
  );
};

export default App;
