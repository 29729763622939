import { mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import Accordion, { Props } from '../accordion';
import Typography from '../typography';
import './index.scss';

export const AccordionV2Header: React.FC<{ title: string }> = props => {
  const { title } = props;

  return (
    <header className="accordionv2-item-header">
      <Icon
        path={mdiChevronUp}
        color={'var(--text-gray)'}
        size="24px"
        className="header-chevron  me-2"
      />
      <Typography>{title}</Typography>
    </header>
  );
};

// This is an easy, though not scalable way of creating a styled variation of the current accordion
const AccordionV2: React.FC<Props> = props => {
  const { className = '', accordionItems } = props;

  return (
    <Accordion
      {...props}
      className={`accordionv2 fancy-scrollbar ${className}`}
      accordionItems={accordionItems.map(accordionItem => ({
        ...accordionItem,
        data: {
          ...accordionItem.data,
          className: `accordionv2-item ${accordionItem.data.className ?? ''}`
        }
      }))}
    />
  );
};

export default AccordionV2;
