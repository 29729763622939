import { useState } from 'react';
import Input from 'src/components/ui/input';
import './index.scss';
import Dialog from 'src/components/core/dialog-layout';
import { DatalineageProps } from '../node-type/node-type-with-edition';

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: ({ dataset, project }: DatalineageProps) => void;
}

const AddNewNodeModal: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const [nodeDetails, setNodeDetails] = useState<DatalineageProps>({
    dataset: '',
    project: '',
    table: ''
  });

  const verifyInput = (): boolean => {
    const { dataset, project, table } = nodeDetails;
    return dataset.length > 0 && project.length > 0 && table.length > 0;
  };

  return (
    <Dialog
      style={{ gap: '0' }}
      open={open}
      title="Add new node"
      scapeActionText="CLOSE"
      confirmActionText={'Add Node'}
      confirmActionHandler={verifyInput() ? () => onConfirm(nodeDetails) : undefined}
      scapeActionHandler={onClose}
      maxWidth="xs">
      <div className="add-node-main-container">
        <Input
          title="Table"
          className="mt-4"
          placeholder="Type the table name"
          required
          onChange={e => setNodeDetails(prev => ({ ...prev, table: e.target.value }))}
        />
        <Input
          title="Dataset"
          className="mt-4"
          placeholder="Type the dataset name"
          required
          onChange={e => setNodeDetails(prev => ({ ...prev, dataset: e.target.value }))}
        />
        <Input
          title="Project"
          className="mt-4"
          placeholder="Type the project name"
          required
          onChange={e => setNodeDetails(prev => ({ ...prev, project: e.target.value }))}
        />
      </div>
    </Dialog>
  );
};

export default AddNewNodeModal;
