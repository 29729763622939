import { Facet, FacetKind } from 'src/components/ui/filter/types';
import { PaginationData } from 'src/components/ui/pagination';
import {
  RequestStatusEnum,
  RequestType
} from 'src/resources/table-change-requests/table-change-requests-model';
import {
  FetchTableChangeRequestsWithoutStatusParams,
  FetchTableChangeRequestsParams
} from 'src/resources/table-change-requests/table-change-requests-types';

export const changeRequestStatusLabel = 'Analysis Status';
const changeRequestTypeLabel = 'Request Type';
export const changeRequestDatasetLabel = 'Data Set';

export const initialChangeRequestsFilter: Facet[] = [
  {
    name: changeRequestStatusLabel,
    kind: FacetKind.Intersection,
    values: [
      {
        name: 'Pending',
        value: RequestStatusEnum.Pending
      },
      {
        name: 'Rejected',
        value: RequestStatusEnum.Rejected
      },
      {
        name: 'Approved',
        value: RequestStatusEnum.Approved
      },
      {
        name: 'Applied',
        value: RequestStatusEnum.Success
      },
      {
        name: 'Failed',
        value: RequestStatusEnum.Failure
      }
    ]
  },
  {
    name: changeRequestTypeLabel,
    kind: FacetKind.Intersection,
    values: [
      { name: 'Dataset Description Edit', value: 'ChangeTableDescription' },
      { name: 'Schema Description Edit', value: 'ChangeColumnDescription' },
      { name: 'Tag Edit or Addition', value: 'AddOrUpdateTag' }
    ]
  }
];

export const getChangeRequestsStatusFacet = (changeRequestsFacets: Facet[]): Facet => {
  return changeRequestsFacets.find(facet => facet.name === changeRequestStatusLabel)!;
};

export const makeFetchTableChangeRequestWithoutStatusParams = (
  facets: Facet[],
  pagination: PaginationData
): FetchTableChangeRequestsWithoutStatusParams => ({
  table_name: facets
    .find(facet => facet.name === changeRequestDatasetLabel)
    ?.values.find(fv => fv.selected)?.value,
  req_type: facets
    .find(facet => facet.name === changeRequestTypeLabel)
    ?.values.find(fv => fv.selected)?.value as RequestType,
  page_number: pagination.page,
  page_size: pagination.limit
});

export const makeFetchTableChangeRequestParams = (
  facets: Facet[],
  pagination: PaginationData
): FetchTableChangeRequestsParams => ({
  ...makeFetchTableChangeRequestWithoutStatusParams(facets, pagination),
  status: facets
    .find(facet => facet.name === changeRequestStatusLabel)
    ?.values.find(fv => fv.selected)?.value as RequestStatusEnum
});
