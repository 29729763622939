import { Image } from 'react-bootstrap';
import LoadingComponent from 'src/components/core/loading-component';
import './index.scss';

const LogoLoadingComponent: React.FC = () => {
  return (
    <div className="logo-loading-component-container">
      <Image src="/assets/amway-login-logo.svg" width="49" height="55" />
      <LoadingComponent />
    </div>
  );
};

export default LogoLoadingComponent;
