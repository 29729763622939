import React from 'react';
import { Image } from 'react-bootstrap';
import Subtitle from 'src/components/ui/subtitle';
import Title from 'src/components/ui/title';
import './index.scss';

interface Props {
  description: string;
}

const NoResultsFound: React.FC<Props> = ({ description }) => {
  return (
    <article className="no-results-found">
      <Image src="/assets/no-results-found.svg" width="120" height="120" />
      <Title sizeVariant="lg" className="mt-1" colorVariant="primary">
        No Results Found
      </Title>
      <Subtitle className="pt-4 no-results-found-description" colorVariant="secondary">
        {description}
      </Subtitle>
    </article>
  );
};

export default NoResultsFound;
