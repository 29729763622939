import OktaAuth from '@okta/okta-auth-js';
import environment, { Environment } from './env';

function createOktaAuthInstance(): OktaAuth {
  return new OktaAuth({
    clientId: environment.OKTA.CLIENT_ID,
    issuer: environment.OKTA.ISSUER,
    scopes: environment.OKTA.SCOPES,
    state: window.location.search,
    pkce: true,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout/callback',
    devMode: [Environment.DEV, Environment.QA].includes(environment.ENV),
    tokenManager: {
      storage: 'localStorage'
    }
  });
}

export const oktaAuth = createOktaAuthInstance();
