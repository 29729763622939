import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useCallback, useMemo } from 'react';

interface Props extends TablePaginationActionsProps {
  currentRows?: number;
  shouldShowLastPageButton?: boolean;
}

const PaginationActions: React.FC<Props> = props => {
  const {
    count,
    currentRows,
    page,
    rowsPerPage,
    onPageChange,
    shouldShowLastPageButton = true
  } = props;
  const theme = useTheme();
  const shouldRightToLeft = useMemo<boolean>(() => theme.direction === 'rtl', [theme]);
  const atFirstPage = useMemo<boolean>(() => page === 0, [page]);
  const atLastPage = useMemo<boolean>(() => {
    // count -1 means that we are doing server side pagination and don't
    // know count, a.k.a number of items
    if (count === -1) return rowsPerPage > currentRows!;
    return page >= Math.ceil(count / rowsPerPage) - 1;
  }, [count, rowsPerPage, currentRows, page]);

  const handleFirstPageButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
    },
    [onPageChange]
  );

  const handleBackButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    },
    [onPageChange, page]
  );

  const handleNextButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    },
    [onPageChange, page]
  );

  const handleLastPageButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    },
    [onPageChange, count, rowsPerPage]
  );

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={atFirstPage}
        aria-label="first page">
        {shouldRightToLeft ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={atFirstPage} aria-label="previous page">
        {shouldRightToLeft ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={atLastPage} aria-label="next page">
        {shouldRightToLeft ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {shouldShowLastPageButton && (
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={atLastPage}
          aria-label="last page">
          {shouldRightToLeft ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export default PaginationActions;
