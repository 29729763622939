import { DataLineage } from '../models/table-data-lineage';

export const tableDataLineageMapper = (apiData: any): DataLineage => {
  return {
    id: apiData.tableLineageId,
    project: apiData.project,
    dataset: apiData.dataset,
    table: apiData.table,
    children:
      apiData.children !== null
        ? apiData.children.map((child: any) => tableDataLineageMapper(child))
        : [],
    parents:
      apiData.parents !== null
        ? apiData.parents.map((parent: any) => tableDataLineageMapper(parent))
        : []
  };
};

export const createdDataLineageEntryMapper = (apiData: any): DataLineage => {
  return {
    id: apiData.tableLineageId,
    project: apiData.targetProject,
    dataset: apiData.targetDataset,
    table: apiData.targetTable,
    children: null,
    parents:
      apiData.parents !== null
        ? apiData.parents.map((parent: any) => tableDataLineageMapper(parent))
        : []
  };
};
