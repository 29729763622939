import React from 'react';
import './index.scss';

interface Props {
  onClick?: VoidFunction;
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

const PseudoLink: React.FC<Props> = props => {
  const { onClick, variant = 'primary', className = '' } = props;

  return (
    <span onClick={onClick} className={`pseudo-link ${variant} ${className}`}>
      {props.children}
    </span>
  );
};

export default PseudoLink;
