import Dialog from 'src/components/core/dialog-layout';
import Typography from 'src/components/ui/typography';
import useTable from 'src/resources/table/table-hook';
import VerticalBarChart from 'src/components/charts/vertical-bar-chart';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import { dateToLongString } from 'src/utils/date-utils';
import './index.scss';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  columnName: string;
}

const DataDistributionModal: React.FC<Props> = ({ columnName, onClose, onConfirm, open }) => {
  const { dataDistribution } = useTable();

  return (
    <Dialog
      open={open}
      isLoading={dataDistribution.loading}
      title={
        <>
          Data Distribution: <Typography sizeVariant="xl">{columnName}</Typography>
        </>
      }
      subtitle="Verify sources, how data is distributed across databases and entity relationship diagrams for those tables."
      confirmActionText="OPEN PAGE"
      confirmActionHandler={onConfirm}
      scapeActionText="CLOSE"
      scapeActionHandler={onClose}
      maxWidth="xl"
      fullWidth={true}>
      <ExternalDataRenderer
        externalData={dataDistribution}
        makeDataElement={data => (
          <section className="data-distribution-modal-container">
            <header>
              <div>
                <Typography>Last refreshed: </Typography>
                <Typography colorVariant="primary" className="ms-2">
                  {dateToLongString(data.updatedAt)} UTC
                </Typography>
              </div>
            </header>
            <VerticalBarChart className="mt-1" data={data.distribution} />
          </section>
        )}
      />
    </Dialog>
  );
};

export default DataDistributionModal;
