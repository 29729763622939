import { createContext } from 'react';
import { AbortFunction, ExternalData } from 'src/@types/external-api';
import { AdmDemographicData, DataTopic, DataTopicMetrics } from './data-topic-model';
import { MetricsFilters, AdmDemographicFilters, DetailedMetricsFilter } from './data-topic-types';
import { DataTopicMetricsDetails } from './metrics-details/models';

export interface DataTopicsContextType {
  dataTopics: ExternalData<DataTopic[]>;
  dataTopicMetrics: ExternalData<DataTopicMetrics[]>;
  dataTopicMetricsDetails: ExternalData<DataTopicMetricsDetails>;
  globalDataTopicMetrics: ExternalData<DataTopicMetrics[]>;
  admDemographicData: ExternalData<AdmDemographicData>;
  fetchDataTopics: () => Promise<void>;
  fetchDataTopicMetrics: (filter: MetricsFilters) => AbortFunction;
  fetchGlobalDataTopicMetrics: (filter: MetricsFilters) => AbortFunction;
  fetchAdmDemographicData: (filter: AdmDemographicFilters) => Promise<void>;
  fetchDataTopicMetricsDetails: (filter: DetailedMetricsFilter) => void;
}

const DataTopicsContext = createContext<DataTopicsContextType>(null as any);

export default DataTopicsContext;
