import { makeUrlWithParams } from 'src/utils/url-utils';
import { Facet, FacetOption, Table, TablePagination } from '../models/table-model';

const makeLookerStudioUrl = (projectId: string, datasetId: string, tableId: string): string => {
  const baseUrl = 'https://lookerstudio.google.com/reporting/create';
  const dataObject = {
    'ds.connector': 'bigQuery',
    'ds.type': 'TABLE',
    'ds.billingProjectId': 'amw-dna-coe-working-dev',
    'ds.projectId': projectId,
    'ds.datasetId': datasetId,
    'ds.tableId': tableId,
    'r.reportName': tableId,
    'c.mode': 'edit'
  };

  return makeUrlWithParams(baseUrl, dataObject);
};

const editBigQueryUrl = (url: string): string => {
  const splittedUrl = url.split('&project=amw-dna-coe-curated');
  if (splittedUrl.length > 1) {
    const fixedUrl = splittedUrl[0] + '&project=amw-dna-coe-working-dev' + splittedUrl[1];
    return fixedUrl;
  }
  return url;
};

export const tablesMapper = (apiData: any[]): Table[] => {
  // here we will shape api data to our format

  return apiData.map((data: any): Table => {
    const [, projectId, , datasetId, , tableId] = data.id.split('/');

    return {
      id: data.id,
      tableName: data.tableName,
      dataset: data.dataset,
      project: data.project,
      description: data.tableDescription,
      lastUpdateTableMetadata: data.lastUpdateTableMetadata,
      type: data.dataType,
      columns: data.fieldMetadata.map((fieldMetadata: any) => ({
        id: fieldMetadata.id,
        name: fieldMetadata.name,
        type: fieldMetadata.type,
        mode: fieldMetadata.mode,
        description: fieldMetadata.description,
        distributionAvailable: fieldMetadata.distributionAvail,
        dictionaryDescription: fieldMetadata.dictionaryDescription
      })),
      tags: data.labelKeys?.map((labelKey: any) => String(labelKey)) ?? [],
      status: 'Healthy',
      certification: 'Cert. Warning',
      lastModified: new Date(data.lastModifiedDt),
      createdAt: new Date(data.createdDt),
      numRows: data.tableNumRows,
      resourceUrl: editBigQueryUrl(data.resourceUrl),
      numBytes: data.tableNumBytes,
      labels: data.labelMetadata.map((labelData: any) => ({
        id: labelData.id,
        name: labelData.labelName,
        value: labelData.labelValue
      })),
      links: data.linkMetadata.map((link: any) => ({
        label: link.linkDescription,
        href: link.linkUrl
      })),
      health: data.dataQuality,
      lastUpdateTableHealth: data.lastUpdateTableQuality,
      certified: data.certified,
      lookerStudioUrl: makeLookerStudioUrl(projectId, datasetId, tableId)
    };
  });
};

export const tablePaginationMapper = (apiData: any): TablePagination => {
  return {
    limit: apiData.pageable.pageSize,
    offset: apiData.pageable.offset,
    total: apiData.totalElements
  };
};

export const facetsMapper = (apiData: any[]): Facet[] => {
  return apiData.map((data: any) => ({
    value: data.value,
    facetOptions: (data.facetOptions as any[])
      .map<FacetOption>((facetOption: any) => ({
        name: facetOption.displayName,
        value: facetOption.name,
        total: facetOption.total,
        selected: facetOption.selected
      }))
      .sort((facetOptionA, facetOptionB) => facetOptionA.name.localeCompare(facetOptionB.name))
  }));
};
