import { Root } from '@amcharts/amcharts5';
import { PieChart, PieSeries } from '@amcharts/amcharts5/percent';
import { CSSProperties, useEffect, useState } from 'react';
import { withPieChartLegend } from '../shared/helpers/legend';
import { withTheme } from '../shared/helpers/theme';
import { PieChartMetrics } from '../shared/types';
import './index.scss';

interface Props {
  id: string;
  style?: CSSProperties;
  data: PieChartMetrics[];
  hasLegend?: boolean;
}

export default function SimplePieChart(props: Props): JSX.Element {
  const { id, style, hasLegend = true, data } = props;
  const [root, setRoot] = useState<Root>();

  // Set the chart id into the root
  useEffect(() => {
    if (!root) {
      setRoot(Root.new(id));
    }
  }, [id, root]);

  useEffect(() => {
    if (root && data.length) {
      withTheme(root);

      // Create chart
      const chart = root.container.children.push(
        PieChart.new(root, {
          layout: root.verticalLayout
        })
      );

      const series = chart.series.push(
        PieSeries.new(root, {
          name: 'Series 1',
          valueField: 'count',
          categoryField: 'label'
        })
      );

      series.slices.template.setAll({
        tooltipText: '{count}',
        themeTagsSelf: ['pie-slice']
      });

      series.data.setAll(data);

      if (hasLegend) {
        withPieChartLegend(root, chart, series);
      }

      // Make stuff animate on load
      void series.appear(1000);
      void chart.appear(1000, 100);

      return () => chart.dispose();
    }
  }, [data, hasLegend, root]);

  return <div id={id} className="pie-chart" style={style} />;
}
