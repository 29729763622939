import React from 'react';
import { TextDiff as TextDiffType, TextDiffStatusEnum } from 'src/@types/text-diff';
import Typography from '../typography';
import './index.scss';

interface Props {
  data: TextDiffType[];
}

const resolveClassName = (status: TextDiffStatusEnum): string => {
  if (status === TextDiffStatusEnum.ADDED) return 'added-content';
  else if (status === TextDiffStatusEnum.REMOVED) return 'removed-content';
  else return 'same-content';
};

const WrappedText: React.FC<{ textDiff: TextDiffType }> = ({ textDiff }) => {
  const [status, text] = textDiff;

  return <span className={resolveClassName(status)}>{text}</span>;
};

const TextDiff: React.FC<Props> = props => {
  const { data } = props;

  return (
    <Typography colorVariant="primary" className="text-diff">
      {data.map((txtDiff, idx) => (
        <WrappedText key={idx} textDiff={txtDiff} />
      ))}
    </Typography>
  );
};

export default TextDiff;
