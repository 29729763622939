import { useEffect, useMemo, useState, useCallback } from 'react';
import { matchRoutes, useLocation, useSearchParams } from 'react-router-dom';

export interface Route {
  label: string;
  /** Path described to the routing api, e.g: /search/:id */
  templatePath: string;
}

export interface RouteFrame extends Route {
  /** Path used, e.g: /search/796?something=true */
  path: string;
}

interface RouteFrameState extends RouteFrame {
  active: boolean;
}

interface UseMatchRoute {
  path: string;
  label: string;
}

type UpdateRouteFrame = (label: string, path: string) => void;

type UseNestedRoutesTracker = (routes: Route[]) => [RouteFrame[], UpdateRouteFrame];

const routesToRoutesFramesState = (routes: Route[]): RouteFrameState[] => {
  return routes.map(route => ({ ...route, path: route.templatePath, active: false }));
};

const routesFramesStateToRoutesFrames = (routesFramesState: RouteFrameState[]): RouteFrame[] => {
  return routesFramesState
    .filter(routeFrameState => routeFrameState.active)
    .map(routeFrameState => ({
      label: routeFrameState.label,
      templatePath: routeFrameState.templatePath,
      path: routeFrameState.path
    }));
};

const routesToUseMatchRoutes = (routes: Route[]): UseMatchRoute[] => {
  return routes.map(route => ({ label: route.label, path: route.templatePath }));
};

const buildPath = (pathWithRouteParam: string, queryParams: string): string => {
  return `${pathWithRouteParam}${queryParams === '' ? '' : `?${queryParams}`}`;
};

const extractMatchData = (matchData: any): { pathWithRouteParam: string; templatePath: string } => {
  const {
    pathname: pathWithRouteParam,
    route: { path: templatePath }
  } = matchData;

  return { pathWithRouteParam, templatePath };
};

const buildHydratedPath = (pathTemplate: string, nestedPath: string): string => {
  const pathTemplateSlices = pathTemplate.split('/');
  const nestedPathSlices = nestedPath.split('/');

  const isUrlPlaceholder = (part: string): boolean => part.startsWith(':');

  for (let idx = 0; idx < pathTemplateSlices.length; idx++) {
    if (isUrlPlaceholder(pathTemplateSlices[idx])) {
      pathTemplateSlices[idx] = nestedPathSlices[idx];
    }
  }

  return pathTemplateSlices.join('/');
};

const useNestedRoutesTracker: UseNestedRoutesTracker = routes => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [routesFramesState, setRoutesFramesState] = useState<RouteFrameState[]>(
    routesToRoutesFramesState(routes)
  );

  const match = useMemo(
    () => matchRoutes(routesToUseMatchRoutes(routes), location),
    [routes, location]
  );
  const routesFrames = useMemo(
    () => routesFramesStateToRoutesFrames(routesFramesState),
    [routesFramesState]
  );

  useEffect(() => {
    if (match?.[0]) {
      const { templatePath, pathWithRouteParam } = extractMatchData(match[0]);
      const currentRoutePath = buildPath(pathWithRouteParam, searchParams.toString());

      setRoutesFramesState(prevRouteFrames => {
        return prevRouteFrames.map(routeFrame => {
          const hasFramePathIncluded = templatePath.includes(routeFrame.templatePath);

          if (!hasFramePathIncluded) {
            return { ...routeFrame, active: false };
          } else {
            const isCurrentFrame = templatePath === routeFrame.templatePath;

            if (isCurrentFrame) {
              return { ...routeFrame, active: true, path: currentRoutePath };
            }

            return {
              ...routeFrame,
              active: true,
              path: buildHydratedPath(routeFrame.path, currentRoutePath)
            };
          }
        });
      });
    }
  }, [match, searchParams]);

  const updateRouteFrame: UpdateRouteFrame = useCallback((label, path) => {
    setRoutesFramesState(currentFramesState => {
      const routeFrameIdx = currentFramesState.findIndex(rFrame => rFrame.label === label);

      if (routeFrameIdx === -1) return currentFramesState;

      const routeFrame = currentFramesState[routeFrameIdx]!;

      routeFrame.path = path;

      return [
        ...currentFramesState.slice(0, routeFrameIdx),
        routeFrame,
        ...currentFramesState.slice(routeFrameIdx + 1)
      ];
    });
  }, []);

  return [routesFrames, updateRouteFrame];
};

export default useNestedRoutesTracker;
