import { MultipleCategoriesChartData } from '../types';

const isCategoryPresent = (label: string, data: MultipleCategoriesChartData[]): number => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].category === label) {
      return i;
    }
  }
  return -1;
};

const sortDataByDate = (arr: MultipleCategoriesChartData[]): MultipleCategoriesChartData[] => {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];
  const monthSortedArr = arr.sort(
    (a, b) => months.indexOf(a.category.split(' ')[0]) - months.indexOf(b.category.split(' ')[0])
  );
  return monthSortedArr.sort(
    (a, b) => Number(a.category.split(' ')[1]) - Number(b.category.split(' ')[1])
  );
};

const getAge = (age: string): number => {
  return Number(`${age.slice(0, 2)}`);
};

const sortDataByNumber = (arr: MultipleCategoriesChartData[]): MultipleCategoriesChartData[] => {
  return arr.sort((a, b) => {
    return Number(getAge(a.category)) - Number(getAge(b.category));
  });
};

export const multipleValueChartMapper = (separateData: {
  [category: string]: Array<{ count: number; label: string }>;
}): MultipleCategoriesChartData[] => {
  const stackedBarChartData: MultipleCategoriesChartData[] = [];

  Object.entries(separateData).forEach(([key, value]) => {
    value.forEach(data => {
      const categoryIndex = isCategoryPresent(data.label, stackedBarChartData);
      if (categoryIndex >= 0) {
        stackedBarChartData[categoryIndex].metrics.push({
          key,
          value: data.count
        });
      } else {
        stackedBarChartData.push({
          category: data.label,
          metrics: [
            {
              key,
              value: data.count
            }
          ]
        });
      }
    });
  });

  if (stackedBarChartData[0]?.category.includes('-')) {
    return sortDataByNumber(stackedBarChartData);
  }

  return sortDataByDate(stackedBarChartData);
};
