import React, { useCallback, useState } from 'react';
import useAuth from 'src/resources/auth/auth-hook';
import NavBarComponent from '../navbar';
import SideNavComponent from '../side-nav';
import './index.scss';

const AuthenticatedLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();
  const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setIsSideNavOpen(!isSideNavOpen);
  }, [setIsSideNavOpen, isSideNavOpen]);

  return (
    <>
      <NavBarComponent />
      <div className="aside-main">
        <SideNavComponent open={isSideNavOpen} toggleMenu={toggleMenu} onLogout={signOut} />
        <main>{children}</main>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
