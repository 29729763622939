import React from 'react';
import DialogLayout from 'src/components/core/dialog-layout';
import CodeSnippet from '../code-snippet';
import './index.scss';

interface Props {
  isOpen: boolean;
  title: string;
  previousDataLabel?: string;
  currentDataSQL: string;
  previousDataSQL?: string;
  onClose: VoidFunction;
}

const MetricsDetailsDialog: React.FC<Props> = props => {
  const {
    isOpen,
    title,
    previousDataLabel = 'Previous',
    currentDataSQL,
    previousDataSQL,
    onClose
  } = props;

  return (
    <DialogLayout
      open={isOpen}
      variant="outlined"
      scapeActionHandler={onClose}
      scapeActionText="Close"
      title={title}
      subtitle={`Check the SQL of ${title} below`}
      confirmActionHandler={onClose}>
      <section className="metrics-details-dialog-container">
        <CodeSnippet
          title="Current data SQL:"
          text={currentDataSQL}
          onCopySuccessText="SQL copied"
        />
        {previousDataSQL && (
          <CodeSnippet
            title={`${previousDataLabel} data SQL:`}
            text={previousDataSQL}
            onCopySuccessText="SQL copied"
            className="mt-5"
          />
        )}
      </section>
    </DialogLayout>
  );
};

export default MetricsDetailsDialog;
