import { Accordion as AccordionBs } from 'react-bootstrap';
import { AccordionSelectCallback } from 'react-bootstrap/esm/AccordionContext';
import AccordionItem, { Props as AccordionItemComponentProps } from './accordionItem';

export type AccordionItemProps = Omit<AccordionItemComponentProps['accordionItem'], 'eventKey'>;

export interface AccordionControls {
  activeKey?: string | string[];
  onSelect: AccordionSelectCallback;
}

export interface Props {
  className?: string;
  isAlwaysOpen?: boolean;
  controls?: AccordionControls;
  accordionItems: Array<{
    key: string;
    data: AccordionItemProps;
  }>;
}

const Accordion: React.FC<Props> = props => {
  const { className = '', isAlwaysOpen, accordionItems, controls } = props;

  return (
    <AccordionBs
      alwaysOpen={isAlwaysOpen}
      className={className}
      activeKey={controls?.activeKey}
      onSelect={controls?.onSelect ?? (() => {})}>
      {accordionItems.map(item => (
        <AccordionItem key={item.key} accordionItem={{ ...item.data, eventKey: item.key }} />
      ))}
    </AccordionBs>
  );
};

export default Accordion;
