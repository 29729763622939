import { Bullet, Circle, Root, Scrollbar, Tooltip } from '@amcharts/amcharts5';
import {
  AxisRendererX,
  CategoryAxis,
  SmoothedXLineSeries,
  XYChart,
  XYCursor
} from '@amcharts/amcharts5/xy';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Scale } from 'src/resources/data-topic/metrics-details/models';
import { withLegend } from '../shared/helpers/legend';
import { withTheme } from '../shared/helpers/theme';
import { makeYAxis } from '../shared/helpers/yAxis';
import { MultipleCategoriesChartData } from '../shared/types';
import { multipleValueChartMapper } from '../shared/utils';
import './index.scss';

interface Props {
  chartMetrics: { [key: string]: Array<{ label: string; count: number }> };
  className?: string;
  hasLegend?: boolean;
  scale?: Scale;
}

interface DataDict {
  category: string;
  [key: string]: number | any;
}

const LineChart: React.FC<Props> = props => {
  const { className, chartMetrics, hasLegend = false, scale = Scale.Linear } = props;
  const [root, setRoot] = useState<Root>();
  const ref = useRef<HTMLDivElement>(null);

  const data = useCallback(() => {
    return multipleValueChartMapper(chartMetrics);
  }, [chartMetrics]);

  const getMetricsByKey = (key: string, values: MultipleCategoriesChartData[]): DataDict[] => {
    return values.reduce<DataDict[]>((previous, { category, metrics }) => {
      const metric = metrics.find(metric => metric.key === key);
      if (metric) {
        previous.push({
          category,
          [key]: metric.value
        });
      }
      return previous;
    }, []);
  };

  useEffect(() => {
    if (ref.current && !root) {
      setRoot(Root.new(ref.current));
    }
  }, [ref, root]);

  useLayoutEffect(() => {
    if (root) {
      withTheme(root);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panY',
          wheelY: 'zoomY',
          layout: root.verticalLayout
        })
      );

      // Add cursor
      chart.set(
        'cursor',
        XYCursor.new(root, {
          behavior: 'none'
        })
      );

      // Add scrollbar
      chart.set(
        'scrollbarX',
        Scrollbar.new(root, {
          orientation: 'horizontal'
        })
      );

      const xAxis = chart.xAxes.push(
        CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: AxisRendererX.new(root, {
            minGridDistance: 90
          }),
          tooltip: Tooltip.new(root, {})
        })
      );

      const yAxis = makeYAxis(root, chart, scale);

      chart.series.clear();

      Object.keys(chartMetrics).forEach(key => {
        const series = chart.series.push(
          SmoothedXLineSeries.new(root, {
            name: key,
            xAxis,
            yAxis,
            valueYField: key,
            categoryXField: 'category',
            tooltip: Tooltip.new(root, {
              labelText: '{name}: {valueY}'
            }),
            legendLabelText: '[{stroke}]{name}[/]:',
            legendValueText: '[bold {stroke}]{valueY}[/]',
            legendRangeLabelText: '[{stroke}]{name}[/]',
            legendRangeValueText: '[{stroke}]{valueYClose}[/]'
          })
        );

        series.data.setAll(getMetricsByKey(key, data()));

        // Make stuff animate on load
        void series.appear();

        series.bullets.push(() =>
          Bullet.new(root, {
            locationY: 0,
            sprite: Circle.new(root, {
              radius: 1,
              fill: series.get('fill')
            })
          })
        );
      });

      xAxis.data.setAll(data());

      if (hasLegend) {
        withLegend(root!, chart);
      }

      void chart.appear(1000, 100);

      return () => chart.dispose();
    }
  }, [chartMetrics, data, hasLegend, root, scale]);

  return <div ref={ref} className={`line-chart ${className}`} />;
};

export default LineChart;
