import { useEffect, useState } from 'react';
import FEATURES from 'src/config/features';
import useFeatures from 'src/resources/features/features-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import EditSchemaDescriptionModal, {
  Props as EditSchemaDescriptionModalProps
} from '../../modals/edit-schema-description';
import SchemaTable, { Props as SchemaTableProps } from './index';

type Props = Omit<SchemaTableProps, 'onEdit'>;

const ContextualizedSchemaTable: React.FC<Props> = props => {
  const { table, onClickRow } = props;
  const [toEditColumn, setToEditColumn] = useState<string | undefined>();
  const { hasFeature } = useFeatures();
  const { createRequest, createdRequest } = useTableChangeRequests();

  useEffect(() => {
    if (createdRequest.success) {
      setToEditColumn(undefined);
    }
  }, [createdRequest.success]);

  if (
    hasFeature(FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.SchemaTableEdition)
  ) {
    const handleOnEdit: SchemaTableProps['onEdit'] = rowData => {
      const [column] = rowData;
      setToEditColumn(column);
    };

    const handleConfirmChanges: EditSchemaDescriptionModalProps['onConfirm'] = (
      newValue: string,
      currentValue: string,
      columnName: string,
      requestDescription: string
    ) => {
      void createRequest({
        requestType: 'ChangeColumnDescription',
        datasetName: table.dataset,
        newValue,
        tableName: table.tableName,
        requestDescription,
        currentValue,
        columnName
      });
    };

    const handleCloseEditModal = (): void => {
      setToEditColumn(undefined);
    };

    return (
      <>
        <SchemaTable {...props} table={table} onClickRow={onClickRow} onEdit={handleOnEdit} />
        <EditSchemaDescriptionModal
          open={!!toEditColumn}
          onClose={handleCloseEditModal}
          onConfirm={handleConfirmChanges}
          loading={!!createdRequest.loading}
          column={toEditColumn!}
          data={table}
        />
      </>
    );
  }

  return <SchemaTable table={table} onClickRow={onClickRow} />;
};

export default ContextualizedSchemaTable;
