import Icon from '@mdi/react';
import { useMemo } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LinkProps, NavLink } from 'react-router-dom';
import './index.scss';

export interface Link extends LinkProps {
  label: string;
  eventKey: string;
  isActive: boolean;
  icon: string;
  open?: boolean;
}

const SideNavLinkComponent: React.FC<Link> = ({
  id,
  to,
  label,
  eventKey,
  isActive,
  icon,
  open = true,
  ...props
}) => {
  const navLink = useMemo(
    () => (
      <Nav.Link
        id={id}
        as={NavLink}
        className={`${isActive ? 'active' : ''} ${open ? 'open' : 'closed'}`}
        to={to}
        {...props}>
        <div className="icon">
          <Icon path={icon} color="var(--detail-gray)" size={'24px'} />
        </div>
        {open ? <>{label}</> : <></>}
      </Nav.Link>
    ),
    [id, isActive, open, to, props, icon, label]
  );

  return open ? (
    navLink
  ) : (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip>
          <>{label}</>
        </Tooltip>
      }>
      {navLink}
    </OverlayTrigger>
  );
};

export default SideNavLinkComponent;
