import StepInstruction, { Props as StepInstructionProps } from './step-instruction';
import './index.scss';

interface Props {
  className?: string;
  steps: Array<Omit<StepInstructionProps, 'step'>>;
}

const StepInstructions: React.FC<Props> = props => {
  const { steps, className } = props;

  return (
    <section className={`step-instructions ${className ?? ''}`}>
      {steps.map((step, idx) => (
        <StepInstruction
          key={step.title}
          step={idx + 1}
          title={step.title}
          description={step.description}
        />
      ))}
    </section>
  );
};

export default StepInstructions;
