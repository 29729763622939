import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import './index.scss';
import DotsVertical from '@mui/icons-material/MoreVert';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewNodeModal from '../add-node-modal';
import { MouseEventHandler, useState } from 'react';
import useNodeClick from 'src/components/core/workflow-builder/hooks/useNodeClick';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FloatingMenu from 'src/components/core/floating-menu';
import useToasts from 'src/resources/toasts/toasts-hook';
import useTable from 'src/resources/table/table-hook';
import tablesDataService from '../../../../../../resources/table/table.service';

export interface DatalineageProps {
  dataset: string;
  project: string;
  table: string;
}

const DataLineageNodeTypeWithEdition: React.FC<NodeProps> = ({ id, data }) => {
  const [isModalOpened, setOpenModal] = useState(false);
  const onClick = useNodeClick(id);
  const { createLineageEntry, deleteLineageEntry } = useTable();
  const { deleteElements, getNode, getEdges } = useReactFlow();
  const sourceNode = getNode(id);
  const toasts = useToasts();
  const [createFromRootDirection, setCreateFromRootDirection] = useState<'left' | 'right'>('left');

  const isParent = (getEdges().filter(edge => edge.source === id) ?? []).length > 0;
  const isRootNode = id === '0';

  const loadMoreLineage = async (): Promise<void> => {
    const loadedDataLineages = await tablesDataService.dataLineage(
      sourceNode?.data.table,
      sourceNode?.data.dataset,
      sourceNode?.data.project,
      1
    );
    const nodeChildren =
      sourceNode?.data.direction === 'right'
        ? loadedDataLineages.children
        : loadedDataLineages.parents;

    if (nodeChildren !== null && nodeChildren !== undefined && nodeChildren.length > 0) {
      nodeChildren.forEach(dataLineage => {
        onClick('dataLineage', {
          ...dataLineage,
          direction: sourceNode?.data.direction ?? 'right'
        });
      });
    } else {
      toasts.push('No more data lineage to load', 'info');
    }
  };

  const onConfirmCreateNode = async (dataLineageDetails: DatalineageProps): Promise<void> => {
    setOpenModal(false);
    setAnchorEl(null);
    let createdNode = null;

    if (!isParent) {
      toasts.push('Expanding data lineage children before adding', 'info');
      void loadMoreLineage();
    }

    const sourceDirection = isRootNode ? createFromRootDirection : sourceNode?.data.direction;

    if (sourceDirection === 'right') {
      createdNode = await createLineageEntry(
        sourceNode?.data.table,
        sourceNode?.data.dataset,
        sourceNode?.data.project,
        dataLineageDetails.table,
        dataLineageDetails.dataset,
        dataLineageDetails.project
      );
    } else {
      createdNode = await createLineageEntry(
        dataLineageDetails.table,
        dataLineageDetails.dataset,
        dataLineageDetails.project,
        sourceNode?.data.table,
        sourceNode?.data.dataset,
        sourceNode?.data.project
      );
    }

    if (createdNode !== undefined && createdNode) {
      onClick('dataLineage', {
        id: createdNode?.id ?? 0,
        direction: sourceDirection ?? 'right',
        ...dataLineageDetails
      });
    } else {
      toasts.push(
        'Error occurred creating lineage, please verify if the table informations are correct.',
        'error',
        10000
      );
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const handleClickOptions: MouseEventHandler<Element> = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteNode = (): void => {
    const deletedNode = getNode(id);

    if (!isParent && deletedNode && Number(deletedNode.id) > 0) {
      void deleteLineageEntry(deletedNode.id).then(() => {
        deleteElements({ nodes: [deletedNode] });
        toasts.push('Lineage deleted successfully', 'success');
      });
    } else if (deletedNode && Number(deletedNode.id) <= 0) {
      toasts.push('Cannot delete root lineage', 'error');
      setAnchorEl(null);
    } else {
      toasts.push('Cannot delete lineage with children', 'error');
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Handle
        className="handle-dot"
        type="target"
        id="target-a"
        position={Position.Left}
        isConnectable={false}
      />
      <Handle
        className="handle-dot"
        type="source"
        position={Position.Left}
        id="source-a"
        isConnectable={false}
      />
      <div className="data-linage-box">
        <div>
          <Title
            sizeVariant="sm"
            colorVariant="quaternary"
            style={{ borderBottom: '1px solid var(--warning-blue)', width: 'max-content' }}>
            {data.table}
          </Title>
          <DotsVertical className="options-button" onClick={handleClickOptions} />
        </div>
        <Typography sizeVariant="sm">project: {data.project}</Typography>
        <Typography sizeVariant="sm">dataset: {data.dataset}</Typography>
        {!isParent && (
          <AddCircleIcon
            className={`add-button ${sourceNode?.data.direction}`}
            onClick={() => loadMoreLineage()}
          />
        )}
      </div>
      <Handle
        className="handle-dot"
        type="target"
        id="target-b"
        position={Position.Right}
        isConnectable={false}
      />
      <Handle
        className="handle-dot"
        type="source"
        position={Position.Right}
        id="source-b"
        isConnectable={false}
      />
      <AddNewNodeModal
        open={isModalOpened}
        onClose={() => setOpenModal(false)}
        onConfirm={onConfirmCreateNode}
      />
      {!isRootNode ? (
        <FloatingMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuTitle="Action Menu"
          menuItems={[
            {
              icon: AddIcon,
              label: 'Add Lineage',
              action: () => setOpenModal(true)
            },
            {
              icon: DeleteOutlineIcon,
              label: 'Delete Lineage',
              action: () => handleDeleteNode(),
              disabled: true // TEMPORARY: Delete disables for now
            }
          ]}
        />
      ) : (
        <FloatingMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuTitle="Action Menu"
          menuItems={[
            {
              icon: AddIcon,
              label: 'Add Lineage Left',
              action: () => {
                setCreateFromRootDirection('left');
                setOpenModal(true);
              }
            },
            {
              icon: AddIcon,
              label: 'Add Lineage Right',
              action: () => {
                setCreateFromRootDirection('right');
                setOpenModal(true);
              }
            }
            // {
            //   icon: DeleteOutlineIcon,
            //   label: 'Delete Lineage',
            //   action: () => handleDeleteNode()
            // }
          ]}
        />
      )}
    </>
  );
};

export default DataLineageNodeTypeWithEdition;
