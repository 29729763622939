import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { User } from '../../../../resources/auth/user-model';
import './index.scss';

interface Props {
  user: User;
}

const UserDetailsComponent: React.FC<Props> = ({ user }) => {
  const name = useMemo(() => user?.name || '', [user]);
  const email = useMemo(() => user?.email || '', [user]);

  const initials = useMemo(() => {
    const letters = name
      .split(' ')
      .filter(word => word.length)
      .map(word => word.charAt(0).toUpperCase())
      .join('');

    return letters.length > 2 ? letters.charAt(0) + letters.charAt(letters.length - 1) : letters;
  }, [name]);

  return (
    <Row className="user-details">
      <Col>
        <h5 className="name">{name}</h5>
        <span className="email">{email}</span>
      </Col>
      <Col className="picture-container">
        <div className="picture">{initials}</div>
      </Col>
    </Row>
  );
};

export default UserDetailsComponent;
