import { useCallback, useEffect, useState } from 'react';
import { Feature } from 'src/config/features';
import PERMISSIONS from 'src/config/permissions';
import FeaturesContext from 'src/resources/features/features-context';
import useAuth from '../auth/auth-hook';
import permissionsService from './permissions.service';

const FeaturesProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [features, setFeatures] = useState<Feature[]>([]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      void permissionsService.list().then(permissions => {
        // for the time being, we are gonna be giving the basic access features to all users
        setFeatures([
          ...permissions.flatMap(permission => PERMISSIONS[permission]),
          ...PERMISSIONS.BasicAccess
        ]);

        setLoading(false);
      });
    }
  }, [isAuthenticated]);

  const hasFeature = useCallback(
    (feature: Feature): boolean => {
      return !!features.find(userFeature => userFeature.label === feature.label);
    },
    [features]
  );

  return (
    <FeaturesContext.Provider
      value={{
        loading,
        hasFeature
      }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
