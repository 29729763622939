import React from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

export interface Props {
  title: string;
  name: string;
  value: string;
  isChecked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const { title, name, value, isChecked, onChange } = props;

  return (
    <Form.Check type="checkbox">
      <Form.Check.Input
        name={name}
        value={value}
        id={name}
        type="checkbox"
        checked={!!isChecked}
        onChange={onChange}
      />
      <Form.Check.Label className="checkbox-label" htmlFor={name}>
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default Checkbox;
