import AuthenticatedLayout from 'src/components/core/authenticated-layout';
import ScrollableAuthenticatedLayout from 'src/components/core/scrollable-authenticated-layout';
import { FeedbackEventFlow } from 'src/resources/feedback/feedback-model';
import DashboardScreen from 'src/screens/authenticated/dashboard';
import DataSetDetailsScreen from 'src/screens/authenticated/data-set-details';
import DataDistributionDetails from 'src/screens/authenticated/data-set-details/full-page-details/data-distribution-details';
import DataTopicScreen from 'src/screens/authenticated/data-topic';
import RequestedChanges from 'src/screens/authenticated/requested-changes';
import SearchScreen from 'src/screens/authenticated/search';
import FEATURES, { Feature } from './features';
import UploadLookerStudioLogs from 'src/screens/authenticated/upload-ls-logs';

export interface RouteData {
  label: string;
  path: string;
  feature: Feature;
  eventFlow: FeedbackEventFlow;
  feedbackUnavailable?: boolean;
  element: JSX.Element;
  Layout: any;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    eventFlow: FeedbackEventFlow.Dashboard,
    feature: FEATURES.DashboardScreen,
    element: <DashboardScreen />,
    Layout: ScrollableAuthenticatedLayout
  },
  {
    label: 'Search Results',
    path: '/dashboard/search',
    eventFlow: FeedbackEventFlow.Search,
    feature: FEATURES.DashboardScreen.SearchResultsScreen,
    element: <SearchScreen />,
    Layout: ScrollableAuthenticatedLayout
  },
  {
    label: 'Data Set Details',
    path: '/dashboard/search/:id',
    eventFlow: FeedbackEventFlow.DataSetDetails,
    feature: FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen,
    element: <DataSetDetailsScreen />,
    Layout: ScrollableAuthenticatedLayout
  },
  {
    label: 'Data Distribution',
    path: '/dashboard/search/:id/data-distribution/:columnName',
    eventFlow: FeedbackEventFlow.DataDistribution,
    feature:
      FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataDistributionScreen,
    element: <DataDistributionDetails />,
    Layout: ScrollableAuthenticatedLayout
  },
  {
    label: 'Data Topic',
    path: '/dashboard/data-topic/:facetValue/:facetOptionValue',
    eventFlow: FeedbackEventFlow.DataTopic,
    feature: FEATURES.DashboardScreen.DataTopicsScreen,
    element: <DataTopicScreen />,
    Layout: ScrollableAuthenticatedLayout
  },
  {
    label: 'Request List',
    path: '/requested-changes',
    eventFlow: FeedbackEventFlow.RequestedChanges,
    feature: FEATURES.RequestedChangesScreen,
    element: <RequestedChanges />,
    Layout: AuthenticatedLayout
  },
  {
    label: 'Request List',
    path: '/requested-changes/:id',
    eventFlow: FeedbackEventFlow.RequestedChanges,
    feature: FEATURES.RequestedChangesScreen,
    element: <RequestedChanges />,
    Layout: AuthenticatedLayout
  },
  {
    label: 'Upload Looker Studio Logs',
    path: '/upload-looker-studio-logs',
    eventFlow: FeedbackEventFlow.UploadLookerStudioLogs,
    feature: FEATURES.UploadLookerStudioLogs,
    element: <UploadLookerStudioLogs />,
    Layout: AuthenticatedLayout
  }
];
