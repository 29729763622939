/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { TealiumView } from '../../../@types/tealium';
import environment from '../../../config/env';
import useAuth from '../../../resources/auth/auth-hook';

// Same type of TealiumView without the constant types and types that depend on user or market
type TealiumDataLayerProps = Omit<
  TealiumView,
  | 'site_country'
  | 'site_currencyCode'
  | 'site_language'
  | 'site_prefix'
  | 'site_region'
  | 'site_subRegion'
  | 'site_type'
  | 'site_type_short'
  | 'site_webProperty'
  | 'site_webProperty_mod'
  | 'visitor_aiu'
  | 'visitor_userProfile'
>;

const TealiumDataLayer: React.FC<TealiumDataLayerProps> = props => {
  const { children, search_category, search_keyword, search_type, search_results } = props;

  const { user } = useAuth();

  // This is a retryer to send data to Tealium because utag can take a while to be loaded on the application
  const withUtag = async (): Promise<any> => {
    if (window.utag) return window.utag;
    let i = 0;
    while (i < 50) {
      await new Promise(resolve => setTimeout(resolve, 200));
      if (window.utag) return window.utag;
      i = i + 1;
    }
    return { link: () => {}, view: () => {} };
  };

  const defineEnv = (): string => {
    switch (environment.ENV) {
      case 'DEV':
        return '-dev';
      case 'QA':
        return '-dev';
      case 'PROD':
        return '';
      default:
        return '-dev';
    }
  };

  useEffect(() => {
    void withUtag().then(utag =>
      utag.view({
        site_country: 'us',
        site_currencyCode: 'usd',
        site_language: 'en',
        site_prefix: 'amway',
        site_region: 'corporate',
        site_subRegion: 'global',
        site_type: 'responsive',
        site_type_short: 'web',
        site_webProperty: 'Amway Data Catalog',
        site_webProperty_mod: `adc-rt-na${defineEnv()}.amway.net | ADC`,
        visitor_aiu: user?.id ?? '',
        visitor_userProfile: user?.userGroup ?? ''
      })
    );

    // Clean up the Tealium tag on component unmount
    return () => {};
  }, [search_category, search_keyword, search_results, search_type, user?.id, user?.userGroup]);

  return <>{children}</>;
};

export default TealiumDataLayer;
