import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Frozen from '@amcharts/amcharts5/themes/Frozen';
import { Root } from '@amcharts/amcharts5';

export const withTheme = (root: Root): void => {
  root.setThemes([
    am5themes_Animated.new(root),
    am5themes_Responsive.new(root),
    am5themes_Frozen.new(root)
  ]);
};
