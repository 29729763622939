export const dateToShortString = (date: Date): string => {
  return date
    ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    : 'Invalid date';
};

export const dateToStringRelative = (date: Date): string => {
  return dateToShortString(date);
};

export const dateToLongString = (date: Date): string => {
  return date
    ? date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    : 'Invalid date';
};

export const dateToTimeString = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};
