import React, { useEffect, useState } from 'react';
import FEATURES from 'src/config/features';
import useFeatures from 'src/resources/features/features-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import AddTag, { Props as AddTagModalProps } from '../../modals/add-tag';
import UpdateTagModal, { Props as UpdateTagModalProps } from '../../modals/update-tag';
import { CellType } from '../table/table-types';
import TagsTable, { Props as TagsTableProps } from './index';

type Props = Omit<TagsTableProps, 'onEdit'>;

const ContextualizedTagsTable: React.FC<Props> = props => {
  const { table } = props;
  const { hasFeature } = useFeatures();
  const { createRequest, createdRequest } = useTableChangeRequests();
  const [toEditTag, setToEditTag] = useState<string | undefined>();
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);

  useEffect(() => {
    if (createdRequest.success) {
      setToEditTag('');
      setIsAddTagModalOpen(false);
    }
  }, [createdRequest.success]);

  if (
    hasFeature(FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.TagsTableEdition)
  ) {
    const handleOnAdd = (): void => {
      setIsAddTagModalOpen(true);
    };

    const handleAddNewTag: UpdateTagModalProps['onConfirm'] = (data): void => {
      const { displayName, value, requestReason } = data;
      void createRequest({
        requestType: 'AddOrUpdateTag',
        datasetName: table.dataset,
        tableName: table.tableName,
        requestDescription: requestReason,
        newValue: `${displayName}:${value}`,
        currentValue: toEditTag
      });
    };

    const handleCloseAddModal = (): void => {
      setIsAddTagModalOpen(false);
    };

    const handleOnEdit = (rowData: CellType[]): void => {
      setToEditTag(`${rowData[1]}:${rowData[2]}`);
    };

    const handleEditNewTag: AddTagModalProps['onConfirm'] = (data): void => {
      const { displayName, value, requestReason } = data;
      void createRequest({
        requestType: 'AddOrUpdateTag',
        datasetName: table.dataset,
        tableName: table.tableName,
        requestDescription: requestReason,
        newValue: `${displayName}:${value}`,
        currentValue: toEditTag
      });
    };

    const handleCloseEditModal = (): void => {
      setToEditTag('');
    };

    return (
      <>
        <TagsTable table={table} onEdit={handleOnEdit} onAdd={handleOnAdd} />
        <AddTag
          loading={createdRequest.loading ?? false}
          onClose={handleCloseAddModal}
          open={isAddTagModalOpen}
          onConfirm={handleAddNewTag}
        />
        <UpdateTagModal
          open={!!toEditTag}
          loading={createdRequest.loading ?? false}
          onClose={handleCloseEditModal}
          onConfirm={handleEditNewTag}
          tagsInfo={toEditTag ?? ''}
        />
      </>
    );
  }

  return <TagsTable table={table} />;
};

export default ContextualizedTagsTable;
