import './index.scss';

const NotFoundScreen: React.FC = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Page not found</h2>
      <h4>We couldn&apos;t find the page you were looking for.</h4>
    </div>
  );
};

export default NotFoundScreen;
