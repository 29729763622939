import * as yup from 'yup';

const gcpLabelNamingConventionRegexp = /^[a-z]([-a-z0-9_]*[a-z0-9])?/;
const gcpKeyNamingConventionRegexp = /^[a-z0-9_-]*$/;

const validateGcpNamingConvention = (str: string): boolean => {
  const data = gcpLabelNamingConventionRegexp.exec(str);

  return !!(data && data[0] === str);
};

export const AddTagValidationSchema = yup.object().shape({
  displayName: yup
    .string()
    .min(1)
    .max(63)
    .test(
      'Naming-convention',
      () =>
        'It must follow the requirements: https://cloud.google.com/bigquery/docs/labels-intro#requirements',
      value => !!(value && validateGcpNamingConvention(value))
    )
    .required(),
  value: yup
    .string()
    .matches(
      gcpKeyNamingConventionRegexp,
      'It must follow the requirements: https://cloud.google.com/bigquery/docs/labels-intro#requirements'
    )
    .max(63),
  requestReason: yup.string().required()
});
