import { mdiBrightness1, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useCallback, useMemo, useState } from 'react';
import { DataTopicMetrics } from 'src/resources/data-topic/data-topic-model';
import PseudoLink from '../pseudo-link';
import Title from '../title';
import Typography from '../typography';
import './index.scss';
import MetricsDetailsDialog from '../metrics-details-dialog';

interface Props {
  metrics: DataTopicMetrics;
  previousDataLabel?: string;
  onRequestDetails?: VoidFunction;
  className?: string;
}

interface RateIconProps {
  rate: number;
}

const RateIcon: React.FC<RateIconProps> = props => {
  const { rate } = props;

  if (rate < 0) {
    return (
      <Icon
        path={mdiMenuDown}
        className="garbage-icon"
        size={'24px'}
        color={'var(--warning-error)'}
      />
    );
  } else if (rate > 0) {
    return (
      <Icon
        path={mdiMenuDown}
        className="garbage-icon"
        size={'24px'}
        rotate={180}
        color={'var(--warning-success)'}
      />
    );
  } else {
    return <Icon path={mdiBrightness1} size={'12px'} color={'var(--detail-gray)'} />;
  }
};

const MetricsDisplay: React.FC<Props> = props => {
  const {
    metrics: {
      title,
      currentDataText,
      previousDataText,
      percentRatio,
      currentDataSQL,
      previousDataSQL
    },
    previousDataLabel,
    className = ''
  } = props;
  const transformedRatio = useMemo(() => (percentRatio - 1) * 100, [percentRatio]);
  const formattedPercentRatio = useMemo(() => transformedRatio.toFixed(2), [transformedRatio]);
  const [isSqlDialogOpen, setIsSqlDialogOpen] = useState(false);

  const handleToggleSqlDialog = useCallback(() => {
    setIsSqlDialogOpen(prev => !prev);
  }, []);

  return (
    <>
      <article className={`metrics-display ${className}`}>
        <header>
          <Title sizeVariant="sm" colorVariant="tertiary" className="mb-1 me-5">
            {title}
          </Title>
        </header>
        <main className="metrics-display-container">
          <span className="data-value">{currentDataText}</span>
          <RateIcon rate={transformedRatio} />
          <Typography colorVariant="primary" sizeVariant="sm" weight="bold">
            {formattedPercentRatio}%
          </Typography>
        </main>
        <footer>
          <Typography colorVariant="secondary" sizeVariant="sm">
            {previousDataText}
          </Typography>
          <div>
            <PseudoLink onClick={handleToggleSqlDialog} variant="tertiary">
              view SQL
            </PseudoLink>
          </div>
        </footer>
      </article>
      <MetricsDetailsDialog
        isOpen={isSqlDialogOpen}
        title={title}
        previousDataLabel={previousDataLabel}
        currentDataSQL={currentDataSQL}
        previousDataSQL={previousDataSQL}
        onClose={handleToggleSqlDialog}
      />
    </>
  );
};

export default MetricsDisplay;
