import {
  ChartComponent as ChartSectionModel,
  Component as ComponentModel,
  DataDisplay as DataDisplayModel,
  DataDisplaySection as DataDisplaySectionModel,
  DividerComponent as DividerComponentModel,
  HeaderComponent as HeaderModel
} from 'src/resources/data-topic/metrics-details/models';
import { makeChart } from './chart-components-factory';
import ChartComponent from './components/chart-component';
import Divider from './components/divider';
import HeaderComponent from './components/header-component';
import MetricsDisplay from 'src/components/ui/metrics-display';
import DataDisplaySection from './components/data-display-section';

export const makeImplementedComponent = (component: ComponentModel): JSX.Element => {
  if (component instanceof HeaderModel) {
    return <HeaderComponent component={component} />;
  } else if (component instanceof ChartSectionModel) {
    return <ChartComponent component={component} />;
  } else if (component instanceof DividerComponentModel) {
    return <Divider />;
  } else if (component instanceof DataDisplayModel) {
    return (
      <div className="d-flex justify-content-center">
        <MetricsDisplay
          metrics={{
            title: component.title,
            currentDataText: component.currentDataText,
            previousDataText: component.previousDataText,
            currentDataSQL: component.currentDataSQL,
            previousDataSQL: component.previousDataSQL,
            currentData: component.currentData,
            previousData: component.previousData,
            percentRatio: component.percentRatio
          }}
        />
      </div>
    );
  } else if (component instanceof DataDisplaySectionModel) {
    return <DataDisplaySection component={component} />;
  } else {
    return makeChart(component);
  }
};
