import { ObjectSchema, ValidationError } from 'yup';

export type ErrorsMap<T> = Partial<Record<keyof T, string>>;

export const validateSchema = <T = any>(schema: ObjectSchema<any>, data: T): ErrorsMap<T> => {
  const errorsMap: ErrorsMap<T> = {};

  try {
    schema.validateSync(data, {
      abortEarly: false
    });
  } catch (err: any) {
    if (err instanceof ValidationError) {
      err.inner.forEach(error => {
        errorsMap[error.path as keyof T] = error.message;
      });
    }
  }

  return errorsMap;
};
