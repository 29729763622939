import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DrawerLayout from 'src/components/core/drawer-layout';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Pagination, { PaginationData } from 'src/components/ui/pagination';
import PseudoLink from 'src/components/ui/pseudo-link';
import Subtitle from 'src/components/ui/subtitle';
import Title from 'src/components/ui/title';
import useTable from 'src/resources/table/table-hook';
import { ListProps } from 'src/resources/table/table-types';
import { encodeTableId } from '../../utils';
import DataSetList from './data-set-list';
import './index.scss';

interface Props {
  isOpen: boolean;
  facetValue: string;
  facetOptionValue: string;
  close: VoidFunction;
}

const buildListProps = (
  facetValue: string,
  facetOptionValue: string,
  pagination: PaginationData
): ListProps => {
  return {
    query: '',
    filters: [
      {
        filterBy: facetValue,
        filterTerms: [facetOptionValue]
      }
    ],
    pageSize: pagination.limit,
    pageNumber: pagination.page,
    orderBy: 'todo'
  };
};

const buildSearchParams = (
  facetValue: string,
  facetOptionValue: string,
  pagination: PaginationData
): string => {
  return encodeURI(
    `?${facetValue}=${facetOptionValue}` +
      `&offset=${pagination.page}` +
      `&limit=${pagination.limit}`
  );
};

const buildSearchScreenUrl = (
  facetValue: string,
  facetOptionValue: string,
  pagination: PaginationData
): string => {
  return encodeURI(
    '/dashboard/search' + buildSearchParams(facetValue, facetOptionValue, pagination)
  );
};

const DataSetDrawer: React.FC<Props> = (props: Props) => {
  const { isOpen, close, facetValue, facetOptionValue } = props;
  const { tables, fetchTables } = useTable();
  const [pagination, setPagination] = useState<PaginationData>({ page: 0, limit: 5 });
  const navigate = useNavigate();
  const facetOption = useMemo(
    () =>
      (tables.data?.facets ?? [])
        .find(facet => facet.value === facetValue)
        ?.facetOptions.find(facetOption => facetOption.value === facetOptionValue),
    [facetOptionValue, facetValue, tables.data]
  );

  useEffect(() => {
    const abort = fetchTables(buildListProps(facetValue, facetOptionValue, pagination));

    return () => abort?.();
  }, [facetOptionValue, facetValue, fetchTables, pagination]);

  const handlePaginationChange = (newPagination: PaginationData): void => {
    setPagination(newPagination);
  };

  const handleSearchScreenNavigation = (): void => {
    navigate(buildSearchScreenUrl(facetValue, facetOptionValue, pagination));
  };

  const handleTableScreenNavigation = (tableId: string): void => {
    // we definitely should abstract this logic here, the same on we have o search pages's data-list
    navigate(`/dashboard/search/${encodeTableId(tableId)}`, {
      state: { search: buildSearchParams(facetValue, facetOptionValue, pagination) }
    });
  };

  return (
    <DrawerLayout
      isOpen={isOpen}
      close={close}
      HeaderActions={
        <PseudoLink variant="secondary" onClick={handleSearchScreenNavigation}>
          VIEW-DETAILS
        </PseudoLink>
      }
      className="data-set-container">
      <header>
        <Title>Data Topic Results: </Title>
        <Subtitle>
          {facetOption?.name} ({facetOption?.total ?? 0})
        </Subtitle>
      </header>
      <section>
        <ExternalDataRenderer
          externalData={tables}
          makeDataElement={data => (
            <DataSetList onNavClick={handleTableScreenNavigation} items={data.tables} />
          )}
        />
      </section>
      <footer>
        <Pagination
          count={facetOption?.total ?? 0}
          page={pagination.page}
          limit={pagination.limit}
          onPaginationChange={handlePaginationChange}
        />
      </footer>
    </DrawerLayout>
  );
};

export default DataSetDrawer;
