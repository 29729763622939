interface Props {
  Providers: React.FC[];
  children: JSX.Element;
}

function WithProviders({ children, Providers }: Props): JSX.Element {
  return Providers.reduceRight((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children);
}

export default WithProviders;
