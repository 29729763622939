import AddIcon from '@mui/icons-material/Add';
import LinksDisplay from 'src/components/ui/links-display';
import PseudoLink from 'src/components/ui/pseudo-link';
import { Table as TableModel } from 'src/resources/table/models/table-model';
import Table from '../table';
import { CellType } from '../table/table-types';

import './index.scss';
import useTable from 'src/resources/table/table-hook';
import { RefObject, useCallback, useEffect, useState } from 'react';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import AddUsefulLink, { AddUsefulLinkProps } from '../../modals/add-useful-link';

export interface Props {
  table: TableModel;
  onEdit?: (rowData: CellType[]) => void;
  onAdd?: VoidFunction;
  parentId?: string;
  parentRef?: RefObject<HTMLDivElement>;
}

const TagsTable: React.FC<Props> = ({ table, onEdit, onAdd, parentId, parentRef }) => {
  const { getTableRules, tableRules, selectedTable, addNewTableUsefulLink } = useTable();
  const [isAddUsefulLinkModalOpen, setIsAddUsefulLinkModalOpen] = useState(false);
  const [isCreatingUsefulLink, setIsCreatingUsefulLink] = useState(false);

  const fetchTableRules = useCallback((): void => {
    if (selectedTable.data) {
      void getTableRules({
        tableName: selectedTable.data.tableName,
        dataset: selectedTable.data.dataset,
        project: selectedTable.data.project
      });
    }
  }, [getTableRules, selectedTable.data]);

  useEffect(() => {
    fetchTableRules();
  }, [fetchTableRules]);

  const handleCloseAddUsefulLinkModal = (): void => {
    setIsAddUsefulLinkModalOpen(false);
  };

  const handleAddNewUsefulLink: AddUsefulLinkProps['onConfirm'] = ({
    documentName,
    documentLink,
    documentType
  }) => {
    if (selectedTable.data) {
      setIsCreatingUsefulLink(true);
      void addNewTableUsefulLink({
        description: documentName,
        linkUrl: documentLink,
        type: documentType
      }).then(() => {
        setIsCreatingUsefulLink(false);
        handleCloseAddUsefulLinkModal();
      });
    }
  };

  return (
    <>
      <div className="top-table-menu-items">
        <ExternalDataRenderer
          externalData={tableRules}
          makeDataElement={tableRules => (
            <div className="useful-links-container">
              {tableRules.tableLinks.length > 0 && (
                <LinksDisplay
                  title="Document Links:"
                  items={tableRules.tableLinks}
                  onAdd={() => setIsAddUsefulLinkModalOpen(true)}
                />
              )}
              <div
                onClick={() => setIsAddUsefulLinkModalOpen(true)}
                className="add-document-button">
                {tableRules.tableLinks.length <= 0 && (
                  <>
                    <AddIcon /> <PseudoLink variant="tertiary">ADD DOCUMENT LINK</PseudoLink>
                  </>
                )}
              </div>
            </div>
          )}
        />
        {onAdd && <AddIcon fontSize="small" onClick={onAdd} className="add-tag-icon" />}
      </div>
      <Table
        className="tags-table"
        rows={table.labels?.map((label, index) => ({
          data: [String(index + 1), label.name, label.value]
        }))}
        hasInfiniteScroll
        headers={['#', 'Display Name', 'Value']}
        onEdit={onEdit}
        parentId={parentId}
        parentRef={parentRef}
      />
      <AddUsefulLink
        loading={isCreatingUsefulLink}
        onClose={handleCloseAddUsefulLinkModal}
        open={isAddUsefulLinkModalOpen}
        onConfirm={handleAddNewUsefulLink}
      />
    </>
  );
};

export default TagsTable;
