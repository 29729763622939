import { FeedbackStatus } from './../../../resources/feedback/feedback-model';
import { FeedbackEventFlow, FeedbackSubject } from 'src/resources/feedback/feedback-model';
import { Facet, FacetKind } from 'src/components/ui/filter/types';
import {
  FetchFeedbacksRequestWithoutStatusParams,
  FetchFeedbacksRequestParams
} from 'src/resources/feedback/feedback-types';
import { PaginationData } from 'src/components/ui/pagination';

interface FeedbackEventFlowOption {
  name: string;
  value: FeedbackEventFlow;
}

interface FeedbackSubjectOption {
  name: string;
  value: FeedbackSubject;
}

interface FeedbackStatusOption {
  name: string;
  value: FeedbackStatus;
}

export const feedbackStatusLabel = 'Status';

const feedbackEventFlowLabel = 'Event Flow';

const feedbackSubjectLabel = 'Subject';

const feedbackEventFlowOptions: FeedbackEventFlowOption[] = [
  { name: 'Dashboard / Global Metrics', value: FeedbackEventFlow.Dashboard },
  { name: 'Requests List', value: FeedbackEventFlow.RequestedChanges },
  { name: 'Search Results', value: FeedbackEventFlow.Search },
  { name: 'Data Set Details', value: FeedbackEventFlow.DataSetDetails },
  { name: 'Data distributions', value: FeedbackEventFlow.DataDistribution },
  { name: 'Data Topic / Account', value: FeedbackEventFlow.DataTopic }
];

export const feedbackSubjectOptions: FeedbackSubjectOption[] = [
  { name: 'Data Inconsistency', value: FeedbackSubject.DataInconsistency },
  { name: 'Layout issues', value: FeedbackSubject.LayoutIssues },
  { name: 'User experience', value: FeedbackSubject.UserExperience },
  { name: 'Others', value: FeedbackSubject.Others }
];

const feedbackStatusOptions: FeedbackStatusOption[] = [
  { name: 'Unread', value: FeedbackStatus.Unseen },
  { name: 'Pinned', value: FeedbackStatus.Pinned },
  { name: 'Read', value: FeedbackStatus.Seen }
];

export const initialFeedbacksFilter: Facet[] = [
  {
    name: feedbackStatusLabel,
    kind: FacetKind.Intersection,
    values: feedbackStatusOptions
  },
  {
    name: feedbackEventFlowLabel,
    kind: FacetKind.Intersection,
    values: feedbackEventFlowOptions
  },
  {
    name: feedbackSubjectLabel,
    kind: FacetKind.Intersection,
    values: feedbackSubjectOptions
  }
];

export const getFeedbackStatusLabel = (feedbackStatus: FeedbackStatus): string => {
  return feedbackStatusOptions.find(opt => opt.value === feedbackStatus)?.name ?? '';
};

export const getFeedbackEventFlowLabel = (feedbackEventFlow: FeedbackEventFlow): string => {
  return feedbackEventFlowOptions.find(opt => opt.value === feedbackEventFlow)?.name ?? '';
};

export const getFeedbackSubjectLabel = (feedbackSubject: FeedbackSubject): string => {
  return feedbackSubjectOptions.find(opt => opt.value === feedbackSubject)?.name ?? '';
};

export const getFeedbacksStatusFacet = (feedbackFacets: Facet[]): Facet => {
  return feedbackFacets.find(facet => facet.name === feedbackStatusLabel)!;
};

export const makeFetchFeedbacksWithoutStatusParams = (
  facets: Facet[],
  pagination: PaginationData
): FetchFeedbacksRequestWithoutStatusParams => ({
  page_number: pagination.page,
  page_size: pagination.limit,
  event_flow: facets
    .find(facet => facet.name === feedbackEventFlowLabel)
    ?.values.find(fv => fv.selected)?.value as FeedbackEventFlow,
  subject: facets.find(facet => facet.name === feedbackSubjectLabel)?.values.find(fv => fv.selected)
    ?.value as FeedbackSubject
});

export const makeFetchFeedbacksParams = (
  facets: Facet[],
  pagination: PaginationData
): FetchFeedbacksRequestParams => ({
  ...makeFetchFeedbacksWithoutStatusParams(facets, pagination),
  status: facets.find(facet => facet.name === feedbackStatusLabel)?.values.find(fv => fv.selected)
    ?.value as FeedbackStatus
});
