import React from 'react';
import { Image } from 'react-bootstrap';
import Subtitle from '../subtitle';
import Title from '../title';
import './index.scss';

const ComingSoon: React.FC = () => {
  return (
    <article className="coming-soon-container">
      <Image src="/assets/coming-soon-logo.svg" width="160" height="160" />
      <Title className="mb-3 mt-1" sizeVariant="lg">
        Coming Soon
      </Title>
      <Subtitle sizeVariant="md">We will celebrate the launch of a new feature very soon!</Subtitle>
    </article>
  );
};

export default ComingSoon;
