import React, { useMemo } from 'react';
import {
  DataTopicMetricsDetails,
  PageComponent as PageComponentModel
} from 'src/resources/data-topic/metrics-details/models';
import SideMenuNav, { SideMenuNavProps } from 'src/components/core/side-menu-nav';
import PageComponent from './components/page';
import './index.scss';
import { makePageComponentLink } from './utils';

interface Props {
  view: DataTopicMetricsDetails;
}

const makeSideMenuItems = (pages: PageComponentModel[]): SideMenuNavProps['sideMenuItems'] => {
  return pages.map(page => ({ label: page.title, link: makePageComponentLink(page) }));
};

const DetailsRenderer: React.FC<Props> = props => {
  const { view } = props;
  const pages = useMemo(
    () => view.pages.map((page, idx) => <PageComponent key={idx} page={page} />),
    [view.pages]
  );
  const sideMenuItems = useMemo(() => makeSideMenuItems(view.pages), [view.pages]);

  return (
    <section className="details-renderer-container">
      <SideMenuNav className="side-menu-nav" sideMenuItems={sideMenuItems} />
      <article>{pages}</article>
    </section>
  );
};

export default DetailsRenderer;
