import { apiWrapper } from 'src/config/api-wrapper';
import { feedbacksMapper, feedbacksPaginationMapper } from './feedback-mapper';
import { FeedbackStatus } from './feedback-model';
import { CreateFeedback, FetchFeedbacksRequestParams, ListResult } from './feedback-types';

class FeedbackService {
  public create(createFeedback: CreateFeedback): Promise<void> {
    return apiWrapper.api.post('/feedback', createFeedback);
  }

  public updateStatus(feedbackId: string, status: FeedbackStatus): Promise<void> {
    return apiWrapper.api.patch(`/feedback/${feedbackId}`, null, {
      params: { status }
    });
  }

  public list<T>(params: FetchFeedbacksRequestParams): Promise<ListResult<T>> {
    return apiWrapper.api.get('/feedback', { params }).then(res => ({
      feedbacks: feedbacksMapper<T>(res.data.content),
      pagination: feedbacksPaginationMapper(res.data)
    }));
  }
}

export default new FeedbackService();
