import { useCallback, useState } from 'react';
import useExternalApiErrorHandler from 'src/hooks/use-external-api-error-handler';
import { DataDistribution } from 'src/resources/table/models/table-data-distribution-model';
import { Table } from 'src/resources/table/models/table-model';
import { TableSample } from 'src/resources/table/models/table-sample-model';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData
} from 'src/helpers/external-data';
import { AbortFunction, ExternalData, FetchFunction } from '../../@types/external-api';
import DataContext from './table-context';
import { ListResult, ListProps } from './table-types';
import tablesDataService from './table.service';
import { DataLineage } from './models/table-data-lineage';
import { LookerStudioReport } from './models/looker-studio-reports-model';
import { AddUsefulLinkRequest, GetTableRulesRequest, TableRules } from './models/table-rules-model';

export const TableProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [tables, setTables] = useState<ExternalData<ListResult>>(makeExternalDataInitialData());
  const [selectedTable, setSelectedTable] = useState<ExternalData<Table>>(
    makeExternalDataInitialData()
  );
  const [tableSample, setTableSample] = useState<ExternalData<TableSample>>(
    makeExternalDataInitialData()
  );
  const [dataDistribution, setDataDistribution] = useState<ExternalData<DataDistribution>>(
    makeExternalDataInitialData()
  );
  const [dataLineage, setDataLineage] = useState<ExternalData<DataLineage>>(
    makeExternalDataInitialData()
  );
  const [lookerStudioReports, setLookerStudioReports] = useState<
    ExternalData<LookerStudioReport[]>
  >(makeExternalDataInitialData());
  const [tableRules, setTableRules] = useState<ExternalData<TableRules>>(
    makeExternalDataInitialData()
  );

  const fetchTables: FetchFunction<ListProps> = useCallback(
    (props: ListProps): AbortFunction => {
      const { promise, abort } = tablesDataService.list(props);

      setTables(prev => makeExternalDataInitialKeepData(prev, abort));

      promise
        .then(data => {
          setTables(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setTables(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setTables(makeExternalDataInitialData());
            }
          });
        });

      return abort;
    },
    [errorHandler]
  );

  const findTable = useCallback(
    async (tableName: string): Promise<void> => {
      try {
        setSelectedTable(makeExternalDataInitialData);
        const data = await tablesDataService.show(tableName);
        setSelectedTable(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setSelectedTable(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchDataDistribution = useCallback(
    async (datasetName: string, tableName: string, columnName: string): Promise<void> => {
      try {
        setDataDistribution(makeExternalDataInitialData);
        const data = await tablesDataService.dataDistribution(datasetName, tableName, columnName);
        setDataDistribution(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setDataDistribution(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const refreshDataDistribution = useCallback(
    async (datasetName: string, tableName: string, columnName: string): Promise<void> => {
      try {
        setDataDistribution(makeExternalDataInitialData());
        const data = await tablesDataService.refreshDataDistribution(
          datasetName,
          tableName,
          columnName
        );
        setDataDistribution(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setDataDistribution(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const findTableSample = useCallback(
    async (dataset: string, tableName: string): Promise<void> => {
      try {
        setTableSample(makeExternalDataInitialData());
        const data = await tablesDataService.samples(dataset, tableName);
        setTableSample(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setTableSample(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchDataLineage = useCallback(
    async (table: string, dataset: string, project: string, depth = 1): Promise<void> => {
      try {
        setDataLineage(makeExternalDataInitialData());
        const data = await tablesDataService.dataLineage(table, dataset, project, depth);
        setDataLineage(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setDataLineage(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const createLineageEntry = useCallback(
    async (
      sourceTable: string,
      sourceDataset: string,
      sourceProject: string,
      targetTable: string,
      targetDataset: string,
      targetProject: string
    ): Promise<DataLineage | undefined> => {
      try {
        const res = await tablesDataService.createLineageEntry(
          sourceTable,
          sourceDataset,
          sourceProject,
          targetTable,
          targetDataset,
          targetProject
        );
        return res;
      } catch (err: any) {
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const deleteLineageEntry = useCallback(
    async (id: string): Promise<void> => {
      try {
        await tablesDataService.deleteLineageEntry(id);
      } catch (err: any) {
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchLookerStudioReports = useCallback(
    async (tableName: string): Promise<void> => {
      try {
        setLookerStudioReports(makeExternalDataInitialData());
        const data = await tablesDataService.fetchLookerStudioReports(tableName);
        setLookerStudioReports(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setLookerStudioReports(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const getTableRules = useCallback(
    async (tableRulesRequest: GetTableRulesRequest): Promise<void> => {
      try {
        setTableRules(makeExternalDataInitialData());
        const data = await tablesDataService.getTableRules(tableRulesRequest);
        setTableRules(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setTableRules(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const addNewTableUsefulLink = useCallback(
    async ({ description, linkUrl, type }: AddUsefulLinkRequest): Promise<void> => {
      try {
        await tablesDataService.addNewTableUsefulLink({
          description,
          linkUrl,
          type,
          tableName: selectedTable.data?.tableName ?? '',
          dataset: selectedTable.data?.dataset ?? '',
          project: selectedTable.data?.project ?? ''
        });
        const data = await tablesDataService.getTableRules({
          tableName: selectedTable.data?.tableName ?? '',
          dataset: selectedTable.data?.dataset ?? '',
          project: selectedTable.data?.project ?? ''
        });
        setTableRules(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setTableRules(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [
      errorHandler,
      selectedTable.data?.dataset,
      selectedTable.data?.project,
      selectedTable.data?.tableName
    ]
  );

  const deleteUsefulLink = useCallback(
    async (usefulLinkId: number): Promise<void> => {
      try {
        await tablesDataService.deleteTableUsefulLink(usefulLinkId);
        const data = await tablesDataService.getTableRules({
          tableName: selectedTable.data?.tableName ?? '',
          dataset: selectedTable.data?.dataset ?? '',
          project: selectedTable.data?.project ?? ''
        });
        setTableRules(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setTableRules(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [
      errorHandler,
      selectedTable.data?.dataset,
      selectedTable.data?.project,
      selectedTable.data?.tableName
    ]
  );

  return (
    <DataContext.Provider
      value={{
        tables,
        selectedTable,
        tableSample,
        dataDistribution,
        dataLineage,
        lookerStudioReports,
        tableRules,
        fetchTables,
        findTable,
        findTableSample,
        fetchDataDistribution,
        refreshDataDistribution,
        fetchDataLineage,
        createLineageEntry,
        deleteLineageEntry,
        fetchLookerStudioReports,
        getTableRules,
        deleteUsefulLink,
        addNewTableUsefulLink
      }}>
      {children}
    </DataContext.Provider>
  );
};

export default TableProvider;
