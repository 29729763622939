import debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';
import Breadcrumbs from 'src/components/ui/breadcrumbs';
import Title from 'src/components/ui/title';
import useBreadcrumbs from 'src/resources/breadcrumbs/breadcrumbs-hook';
import Filter, { Props as FilterProps } from '../../../components/ui/filter';
import SearchInput, { Props as SearchProps } from '../../../components/ui/search-input';
import useTable from '../../../resources/table/table-hook';
import useQueryParams from '../../../hooks/use-query-params';
import DataList, { PaginationData } from './data-list';
import './index.scss';
import NoResultsFound from '../../../components/ui/no-results-found';
import {
  buildListProps,
  facetsToFilterFacets,
  hasFacetApplied,
  queryParamsToState,
  stateToQueryParams
} from './utils';
import Subtitle from 'src/components/ui/subtitle';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import { useNavigate } from 'react-router-dom';
import { encodeTableId } from '../utils';
import { Table } from 'src/resources/table/models/table-model';
import { searchMaxLength } from 'src/helpers/search-validators';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';

const DebounceTime = 500;
const initialPaginationQueryParam = { limit: '5', offset: '0' };
const noResultsFoundDescription = 'Input fields in filter hasn’t returned results';

const SearchScreen: React.FC = () => {
  const { breadcrumbs } = useBreadcrumbs();
  const { tables, fetchTables } = useTable();
  const navigate = useNavigate();
  const [params, setParams] = useQueryParams(initialPaginationQueryParam);
  const { searchText, filterFacets, pagination } = queryParamsToState(
    params,
    facetsToFilterFacets(tables.data?.facets ?? [])
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTables = useCallback(debounce(fetchTables, DebounceTime), [fetchTables]);

  useEffect(() => {
    const abortFetchTables = debouncedFetchTables(buildListProps(params));
    return () => abortFetchTables?.();
  }, [debouncedFetchTables, params]);

  const handleSearchChanges: SearchProps['onChange'] = (newSearchText: string) => {
    tables.abort?.();
    setParams(
      stateToQueryParams({
        searchText: newSearchText,
        filterFacets,
        pagination: { offset: 0, limit: pagination.limit }
      })
    );
  };

  const handleFacetsChanges: FilterProps['onFacetsChange'] = newFilterFacets => {
    tables.abort?.();
    setParams(
      stateToQueryParams({
        searchText,
        filterFacets: newFilterFacets,
        pagination: { offset: 0, limit: pagination.limit }
      })
    );
  };

  const handleOrderByChanges: FilterProps['onOrderByChange'] = () => {};

  const handleOnPaginationChanges = (newPagination: PaginationData): void => {
    tables.abort?.();
    setParams(
      stateToQueryParams({
        searchText,
        filterFacets,
        pagination: newPagination
      })
    );
  };

  const handleTableClick = (table: Table): void => {
    navigate(`/dashboard/search/${encodeTableId(table.id)}`);
  };

  return (
    <TealiumDataLayer>
      <section className="search-container card">
        <header className="search-header">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Title className="mt-2 mb-3">Search Results</Title>
          <Subtitle>Search for logical names, physical names or descriptions from tables</Subtitle>
          <SearchInput
            id="search"
            value={searchText}
            onChange={handleSearchChanges}
            placeholder="Search"
            className="mt-4 mb-3"
            maxLength={searchMaxLength}
          />
        </header>
        {!tables.loading &&
        !hasFacetApplied(tables.data?.facets ?? []) &&
        tables.data?.tables.length === 0 ? (
          <NoResultsFound description={noResultsFoundDescription} />
        ) : (
          <article className="search-data-container">
            <Filter
              className="search-filter fancy-scrollbar"
              facets={filterFacets}
              // appliedFacets={facetsToFilterFacets(tables.data.facets)}
              onFacetsChange={handleFacetsChanges}
              onOrderByChange={handleOrderByChanges}
              orderBy={[
                {
                  name: 'Relevance',
                  value: 'relevance',
                  selected: true
                }
              ]}
            />
            <ExternalDataRenderer
              externalData={tables}
              makeDataElement={tablesData => (
                <DataList
                  data={tablesData.tables}
                  onPaginationChange={handleOnPaginationChanges}
                  onTableClick={handleTableClick}
                  pagination={
                    { ...pagination, total: tables.data?.pagination.total ?? 0 } as PaginationData
                  }
                />
              )}
            />
          </article>
        )}
      </section>
    </TealiumDataLayer>
  );
};

export default SearchScreen;
