import { useCallback, useMemo } from 'react';
import useNestedRoutesTracker, {
  RouteFrame,
  Route as RoutesTrackerRoute
} from 'src/hooks/use-nested-routes-tracker';
import BreadcrumbsContext, { BreadcrumbsType, Breadcrumb } from './breadcrumbs-context';

interface Route {
  label: string;
  path: string;
}

interface Props {
  routes: Route[];
}

const routesToRoutesTrackerRoutes = (routes: Route[]): RoutesTrackerRoute[] => {
  return routes.map(route => ({ label: route.label, templatePath: route.path }));
};

const routesFramesToBreadcrumbs = (routesFrames: RouteFrame[]): Breadcrumb[] => {
  return routesFrames.map(routeFrame => ({
    label: routeFrame.label,
    path: routeFrame.path
  }));
};

const BreadcrumbsProvider: React.FC<Props> = ({ children, routes }) => {
  const routesTrackerRoutes = useMemo(() => routesToRoutesTrackerRoutes(routes), [routes]);
  const [routesFrames, updateRouteFrame] = useNestedRoutesTracker(routesTrackerRoutes);
  const breadcrumbs = useMemo(() => routesFramesToBreadcrumbs(routesFrames), [routesFrames]);

  const updatePreviousBreadcrumb: BreadcrumbsType['updatePreviousBreadcrumb'] = useCallback(
    (label, newPath) => {
      updateRouteFrame(label, newPath);
    },
    [updateRouteFrame]
  );

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, updatePreviousBreadcrumb }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
