import React, { useCallback } from 'react';
import Button from 'src/components/ui/button';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import FEATURES from 'src/config/features';
import { WithFeaturesProxy } from 'src/helpers/with-features-proxy';
import { DataDistribution } from 'src/resources/table/models/table-data-distribution-model';
import { Table } from 'src/resources/table/models/table-model';
import useTable from 'src/resources/table/table-hook';
import { dateToLongString } from 'src/utils/date-utils';

export interface Props {
  table: Table;
  columnName: string;
  dataDistribution: DataDistribution;
  onRequestRefresh?: VoidFunction;
}

export type ExposedProps = Omit<Props, 'onRequestRefresh'>;

const DataDistributionHeader: React.FC<Props> = props => {
  const { table, dataDistribution, onRequestRefresh } = props;

  return (
    <header>
      <Title sizeVariant="md">{table.tableName}</Title>
      <div>
        <Typography>Last refreshed: </Typography>
        <Typography colorVariant="primary" className="ms-2">
          {dateToLongString(dataDistribution.updatedAt)} UTC
        </Typography>
        {onRequestRefresh && (
          <Button className="ms-5" onClick={onRequestRefresh}>
            Refresh data
          </Button>
        )}
      </div>
    </header>
  );
};

export default WithFeaturesProxy<ExposedProps>(
  FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataDistributionScreen
    .RefreshDataDistribution
)((props, hasFeature) => {
  const { table, columnName } = props;
  const { refreshDataDistribution } = useTable();

  const handleRefreshButton = useCallback((): void => {
    void refreshDataDistribution(table.dataset, table.tableName, columnName);
  }, [columnName, refreshDataDistribution, table.dataset, table.tableName]);

  if (hasFeature) {
    return <DataDistributionHeader {...props} onRequestRefresh={handleRefreshButton} />;
  }

  return <DataDistributionHeader {...props} />;
});
