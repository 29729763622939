import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import './index.scss';
import tablesDataService from '../../../../../../resources/table/table.service';
import useNodeClick from 'src/components/core/workflow-builder/hooks/useNodeClick';
import useToasts from 'src/resources/toasts/toasts-hook';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const DataLineageNodeType: React.FC<NodeProps> = ({ id, data }) => {
  const onClick = useNodeClick(id);
  const { getNode, getEdges } = useReactFlow();
  const sourceNode = getNode(id);
  const toasts = useToasts();
  const isParent = (getEdges().filter(edge => edge.source === id) ?? []).length > 0;

  const loadMoreLineage = async (): Promise<void> => {
    const loadedDataLineages = await tablesDataService.dataLineage(
      sourceNode?.data.table,
      sourceNode?.data.dataset,
      sourceNode?.data.project,
      1
    );
    const nodeChildren =
      sourceNode?.data.direction === 'right'
        ? loadedDataLineages.children
        : loadedDataLineages.parents;

    if (nodeChildren !== null && nodeChildren !== undefined && nodeChildren.length > 0) {
      nodeChildren.forEach(dataLineage => {
        onClick('dataLineage', {
          ...dataLineage,
          direction: sourceNode?.data.direction ?? 'right'
        });
      });
    } else {
      toasts.push('No more data lineage to load', 'info');
    }
  };

  return (
    <>
      <Handle
        className="handle-dot"
        type="target"
        id="target-a"
        position={Position.Left}
        isConnectable={false}
      />
      <Handle
        className="handle-dot"
        type="source"
        position={Position.Left}
        id="source-a"
        isConnectable={false}
      />
      <div className="data-linage-box">
        <Title
          sizeVariant="sm"
          colorVariant="quaternary"
          style={{ borderBottom: '1px solid var(--warning-blue)', width: 'max-content' }}>
          {data.table}
        </Title>
        <Typography sizeVariant="sm">project: {data.project}</Typography>
        <Typography sizeVariant="sm">dataset: {data.dataset}</Typography>
        {!isParent && (
          <AddCircleIcon
            className={`add-button ${sourceNode?.data.direction}`}
            onClick={() => loadMoreLineage()}
          />
        )}
      </div>
      <Handle
        className="handle-dot"
        type="target"
        id="target-b"
        position={Position.Right}
        isConnectable={false}
      />
      <Handle
        className="handle-dot"
        type="source"
        position={Position.Right}
        id="source-b"
        isConnectable={false}
      />
    </>
  );
};

export default DataLineageNodeType;
