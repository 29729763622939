import AlertCircle from '@mui/icons-material/Error';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Hr from 'src/components/ui/hr';
import Typography from 'src/components/ui/typography';
import FEATURES from 'src/config/features';
import useFeatures from 'src/resources/features/features-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import { Table } from 'src/resources/table/models/table-model';
import { encodeTableId } from '../../utils';

interface Props {
  table: Table;
}

const ContextualizedPendingChangeRequestsDisplay: React.FC<Props> = ({ table }) => {
  const { hasFeature } = useFeatures();
  const navigate = useNavigate();
  const { createdRequest, pendingChangeRequestsCounter, fetchPendingChangeRequestsCounter } =
    useTableChangeRequests();

  useEffect(() => {
    if (createdRequest.success) {
      void fetchPendingChangeRequestsCounter(table.tableName);
    }
  }, [createdRequest.success, fetchPendingChangeRequestsCounter, table.tableName]);

  useEffect(() => {
    void fetchPendingChangeRequestsCounter(table.tableName);
  }, [fetchPendingChangeRequestsCounter, table]);

  if (
    hasFeature(
      FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.PendingChangeRequestsDisplay
    )
  ) {
    const onRequestDetailsClick = (): void => {
      navigate(`/requested-changes/${encodeTableId(table.id)}`);
    };

    return (
      <ExternalDataRenderer
        externalData={pendingChangeRequestsCounter}
        makeDataElement={pendingChangeRequestsCounterData =>
          pendingChangeRequestsCounterData.count > 0 ? (
            <>
              <Row className="mt-4 g-0 align-items-center justify-content-between pending-changes-container">
                <Col className="d-flex align-items-end">
                  <AlertCircle style={{ fill: 'var(--warning-blue)' }} />{' '}
                  <Typography colorVariant="primary" className="ms-1">
                    {pendingChangeRequestsCounterData.count} Pending Changes Executions
                  </Typography>
                </Col>
                <Col>
                  <button
                    onClick={onRequestDetailsClick}
                    className="metadata-button ms-auto"
                    style={{ color: 'var(--primary)' }}>
                    REQUEST DETAILS
                  </button>
                </Col>
              </Row>
              <Hr className="my-4" />
            </>
          ) : (
            <span></span>
          )
        }
      />
    );
  }

  return null;
};

export default ContextualizedPendingChangeRequestsDisplay;
