import { percent, Root, Scrollbar, Tooltip } from '@amcharts/amcharts5';
import { AxisRendererX, CategoryAxis, ColumnSeries, XYChart } from '@amcharts/amcharts5/xy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Scale } from 'src/resources/data-topic/metrics-details/models';
import { withLegend } from '../shared/helpers/legend';
import { withTheme } from '../shared/helpers/theme';
import { makeYAxis } from '../shared/helpers/yAxis';
import { Metrics } from '../shared/types';
import { multipleValueChartMapper } from '../shared/utils';
import './index.scss';

interface Props {
  chartMetrics: { [key: string]: Array<{ label: string; count: number }> };
  className?: string;
  hasLegend?: boolean;
  scale?: Scale;
}

interface DataDict {
  category: string;
  [key: string]: number | any;
}

const StackedColumnsChart: React.FC<Props> = props => {
  const { className, chartMetrics, hasLegend, scale = Scale.Linear } = props;
  const [root, setRoot] = useState<Root>();
  const ref = useRef<HTMLDivElement>(null);

  const data = useCallback(() => {
    return multipleValueChartMapper(chartMetrics);
  }, [chartMetrics]);

  const buildDataDict = useCallback((metrics: Metrics[], category: string) => {
    return metrics.reduce<DataDict>(
      (previous, { key, value }) => {
        previous[key] = value;
        return previous;
      },
      {
        category
      }
    ) as DataDict;
  }, []);

  useEffect(() => {
    if (ref.current && !root) {
      setRoot(Root.new(ref.current));
    }
  }, [ref, root]);

  useEffect(() => {
    if (root && data().length) {
      withTheme(root);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panY',
          wheelY: 'zoomY',
          layout: root.verticalLayout
        })
      );

      // Add scrollbar
      chart.set(
        'scrollbarY',
        Scrollbar.new(root, {
          orientation: 'vertical'
        })
      );

      const xRenderer = AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      });

      const xAxis = chart.xAxes.push(
        CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: xRenderer,
          tooltip: Tooltip.new(root, {})
        })
      );

      const yAxis = makeYAxis(root, chart, scale);

      chart.series.clear();

      const dataDicts = data().map(({ category, metrics }) => buildDataDict(metrics, category));

      const createdSeries: string[] = [];
      dataDicts
        .map(dict => ({
          dict,
          keys: Object.keys(dict).filter(k => k !== 'category')
        }))
        .forEach(({ keys }) => {
          keys.forEach((k: string, i) => {
            if (!createdSeries.includes(k)) {
              createdSeries.push(k);

              const series = chart.series.push(
                ColumnSeries.new(root, {
                  name: k,
                  stacked: true,
                  xAxis,
                  yAxis,
                  valueYField: k,
                  categoryXField: 'category'
                })
              );

              series.columns.template.setAll({
                tooltipText: '{category} | {name}: {valueY}',
                tooltipY: percent(10)
              });

              series.data.setAll(dataDicts);

              // Make stuff animate on load
              void series.appear();
            }
          });
        });

      xAxis.data.setAll(data());

      if (hasLegend) {
        withLegend(root!, chart);
      }

      void chart.appear(1000, 100);

      return () => chart.dispose();
    }
  }, [buildDataDict, data, hasLegend, root, scale]);

  return <div ref={ref} className={`stacked-columns-chart ${className}`} />;
};

export default StackedColumnsChart;
