import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import Input, { Props as InputProps } from 'src/components/ui/input';
import Select, { Props as SelectProps } from 'src/components/ui/select';
import { authenticatedRoutes, RouteData } from 'src/config/routes';
import useFeedback from 'src/resources/feedback/feedback-hook';
import { FeedbackEventFlow, FeedbackSubject } from 'src/resources/feedback/feedback-model';
import { feedbackSubjectOptions } from 'src/screens/authenticated/requested-changes/feedback-utils';
import { getFirstOptionValue } from 'src/utils/select-option-utils';
import DialogLayout from '../dialog-layout';
import './index.scss';

export interface FeedbackData {
  title: string;
  subject: string;
  body: string;
}

interface Props {
  isOpen: boolean;
  onCancel: VoidFunction;
}

const routesToSelectOptions = (
  routes: RouteData[],
  currentPath?: string
): SelectProps['options'] => {
  return routes
    .reduce<RouteData[]>((acc, item) => {
      if (!item.feedbackUnavailable && !acc.find(i => i.eventFlow === item.eventFlow)) {
        acc.push(item);
      }

      return acc;
    }, [])
    .map(route => ({
      name: route.label,
      value: route.eventFlow,
      selected: currentPath === route.path
    }));
};

const FeedbackDialog: React.FC<Props> = props => {
  const feedback = useFeedback();
  const { isOpen, onCancel } = props;
  const location = useLocation();
  const currentRoute = useMemo(
    () => matchRoutes(authenticatedRoutes, location)?.[0].route,
    [location]
  );
  const routesOption = useMemo(
    () => routesToSelectOptions(authenticatedRoutes, currentRoute?.path),
    [currentRoute]
  );
  const [eventFlow, setEventFlow] = useState<FeedbackEventFlow | undefined>();
  const [subject, setSubject] = useState<FeedbackSubject>(
    getFirstOptionValue(feedbackSubjectOptions) as FeedbackSubject
  );
  const [body, setBody] = useState('');
  const isValid = useMemo(() => body !== '', [body]);

  useEffect(() => {
    setEventFlow(
      (currentRoute?.feedbackUnavailable
        ? getFirstOptionValue(routesOption)
        : currentRoute?.eventFlow) as FeedbackEventFlow
    );
  }, [currentRoute, routesOption]);

  const clearChanges = useCallback((): void => {
    setEventFlow(
      (currentRoute?.feedbackUnavailable
        ? getFirstOptionValue(routesOption)
        : currentRoute?.eventFlow) as FeedbackEventFlow
    );
    setSubject(getFirstOptionValue(feedbackSubjectOptions) as FeedbackSubject);
    setBody('');
  }, [currentRoute?.eventFlow, currentRoute?.feedbackUnavailable, routesOption]);

  useEffect(() => {
    clearChanges();
  }, [clearChanges, isOpen]);

  const handleEventFlowChanges: SelectProps['onChange'] = e => {
    const { value } = e.target;
    setEventFlow(value as FeedbackEventFlow);
  };

  const handleSubjectChanges: SelectProps['onChange'] = e => {
    const { value } = e.target;
    setSubject(value as FeedbackSubject);
  };

  const handleBodyChanges: InputProps['onChange'] = e => {
    const { value } = e.target;
    setBody(value);
  };

  const handleConfirmAction = async (): Promise<void> => {
    await feedback.create({
      eventFlow: eventFlow as FeedbackEventFlow,
      subject: subject as FeedbackSubject,
      body
    });
  };

  return (
    <DialogLayout
      open={isOpen}
      title="We'd welcome your feedback!"
      subtitle="Let us know how we can improve your experience!"
      confirmActionText="SEND"
      confirmActionHandler={isValid ? handleConfirmAction : undefined}
      scapeActionText="CANCEL"
      scapeActionHandler={onCancel}
      className="feedback-container"
      isLoading={feedback.createStatus.loading}>
      <Select
        title="Event Flow"
        variant="primary"
        value={eventFlow}
        onChange={handleEventFlowChanges}
        size="lg"
        options={routesOption}
        required
      />
      <Select
        title="What is the subject"
        placeholder="Select Subject"
        value={subject}
        onChange={handleSubjectChanges}
        variant="primary"
        size="lg"
        options={feedbackSubjectOptions}
        required
      />
      <Input
        title="Tell us more about your experience"
        placeholder="Type your message"
        value={body}
        onChange={handleBodyChanges}
        required
      />
    </DialogLayout>
  );
};

export default FeedbackDialog;
