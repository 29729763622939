import { NodeTypes } from 'reactflow';
import ReactFlowWrapper from 'src/components/core/workflow-builder';
import FEATURES from 'src/config/features';
import { WithFeaturesProxy } from 'src/helpers/with-features-proxy';
import { DataLineage } from '../../../../../resources/table/models/table-data-lineage';
import DataLineageNodeType from './node-type/node-type';
import DataLineageNodeTypeWithEdition from './node-type/node-type-with-edition';

interface ExposedProps {
  dataLineage: DataLineage;
}

interface Props extends ExposedProps {
  nodeTypes: NodeTypes;
}

const DataLineageComponent: React.FC<Props> = props => {
  return (
    <ReactFlowWrapper
      type="dataLineage"
      data={props.dataLineage}
      nodeTypes={props.nodeTypes}
      style={{
        background: 'var(--tertiary)',
        border: '1px solid var(--detail-border)'
      }}
    />
  );
};

export default WithFeaturesProxy<ExposedProps>(
  FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataLineageEdition
)((props, hasFeature) => {
  if (hasFeature) {
    return (
      <DataLineageComponent
        {...props}
        nodeTypes={{
          dataLineage: DataLineageNodeTypeWithEdition
        }}
      />
    );
  }

  return (
    <DataLineageComponent
      {...props}
      nodeTypes={{
        dataLineage: DataLineageNodeType
      }}
    />
  );
});
