import { useCallback } from 'react';
import Button from 'src/components/ui/button';
import Typography from 'src/components/ui/typography';
import FEATURES from 'src/config/features';
import { WithFeaturesProxy } from 'src/helpers/with-features-proxy';
import useFeedback from 'src/resources/feedback/feedback-hook';
import { Feedback, FeedbackStatus } from 'src/resources/feedback/feedback-model';
import './index.scss';

interface ExposedProps {
  feedback: Feedback;
}

interface Props extends ExposedProps {
  onChangeStatus?: (newStatus: FeedbackStatus) => void;
}

const FeedbackStatusActionsContainer: React.FC<Required<Props>> = props => {
  const { feedback, onChangeStatus } = props;

  if (feedback.status === FeedbackStatus.Unseen) {
    return (
      <footer>
        <Button variant="quaternary" onClick={() => onChangeStatus(FeedbackStatus.Pinned)}>
          PIN FEEDBACK
        </Button>
        <Button onClick={() => onChangeStatus(FeedbackStatus.Seen)}>MARK AS READ</Button>
      </footer>
    );
  } else if (feedback.status === FeedbackStatus.Pinned) {
    return (
      <footer>
        <Button onClick={() => onChangeStatus(FeedbackStatus.Seen)}>MARK AS READ</Button>
      </footer>
    );
  } else {
    return (
      <footer>
        <Button variant="quaternary" onClick={() => onChangeStatus(FeedbackStatus.Pinned)}>
          PIN FEEDBACK
        </Button>
      </footer>
    );
  }
};

const FeedbackItemBody: React.FC<Props> = props => {
  const { feedback, onChangeStatus } = props;

  return (
    <section className="feedback-item-body">
      <Typography>Message: </Typography>
      <Typography colorVariant="primary">{feedback.body}</Typography>
      {onChangeStatus && <FeedbackStatusActionsContainer {...(props as Required<Props>)} />}
    </section>
  );
};

export default WithFeaturesProxy<ExposedProps>(FEATURES.RequestedChangesScreen.ManageFeedbacks)(
  (props, hasFeature) => {
    const { feedback } = props;
    const { updateStatus } = useFeedback();

    const handleChangeFeedbackStatus = useCallback(
      (newStatus: FeedbackStatus) => {
        void updateStatus(feedback.id, newStatus);
      },
      [feedback.id, updateStatus]
    );

    if (hasFeature) {
      return <FeedbackItemBody {...props} onChangeStatus={handleChangeFeedbackStatus} />;
    }

    return <FeedbackItemBody {...props} />;
  }
);
