import { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingComponent from 'src/components/core/loading-component';
import Input, { Props as InputProps } from 'src/components/ui/input';
import Dialog from '../../../../../components/core/dialog-layout';
import './index.scss';
import Select from 'src/components/ui/select';
import { LinkType, linkTypes } from 'src/resources/table/models/table-rules-model';

interface Values {
  documentName: string;
  documentLink: string;
  documentType: LinkType;
}

export interface AddUsefulLinkProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: Values) => void;
  loading: boolean;
}

const initialValues: Values = {
  documentName: '',
  documentLink: '',
  documentType: 'Other' as LinkType
};

const AddUsefulLink: React.FC<AddUsefulLinkProps> = ({ open, onClose, onConfirm, loading }) => {
  const [data, setData] = useState<Values>({ ...initialValues });

  const resetForm = useCallback((): void => {
    setData({ ...initialValues });
  }, []);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const handleConfirm = (): void => {
    onConfirm(data);
  };

  const handleChange: InputProps['onChange'] = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const selectOptions = useMemo(() => {
    return linkTypes.map(type => ({
      name: type,
      value: type
    }));
  }, []);

  return (
    <Dialog
      open={open}
      title="Add Document Link"
      subtitle="This document link will be displayed for this dataset."
      scapeActionText="CANCEL"
      confirmActionText={loading ? <LoadingComponent /> : 'ADD DOCUMENT'}
      confirmActionHandler={
        data.documentName.length > 0 && data.documentLink.length > 0 ? handleConfirm : undefined
      }
      scapeActionHandler={onClose}
      maxWidth="xs">
      <div className="add-tag-main-container">
        <Input
          title="Document name:"
          className="mt-2"
          placeholder="Type name"
          required
          name="documentName"
          value={data.documentName}
          onChange={handleChange}
        />
        <Input
          title="Document Link:"
          className="mt-4"
          placeholder="Type document link"
          name="documentLink"
          value={data.documentLink}
          required
          onChange={handleChange}
        />
        <Select
          title="Document Type"
          variant="primary"
          value={data.documentType}
          onChange={e => setData({ ...data, documentType: e.target.value as LinkType })}
          size="lg"
          options={selectOptions}
          required
        />
      </div>
    </Dialog>
  );
};

export default AddUsefulLink;
