import { PaginationData } from '../data-list/index';
import { Facet as FilterFacet } from '../../../../components/ui/filter/types';
import { QueryParams } from 'src/hooks/use-query-params';
import { Facet, FacetOption } from 'src/resources/table/models/table-model';
import { ListProps } from 'src/resources/table/table-types';

// the first level represents the facet, the second, the value
type SelectedFacetsValueMap = Record<string, Record<string, true>>;

export interface State {
  searchText: string;
  pagination: { offset: number; limit: number };
  filterFacets: FilterFacet[];
}

const SearchTextQueryParamKey = 's';
const PaginationLimitQueryParamKey = 'limit';
const PaginationOffsetQueryParamKey = 'offset';

const hydratedFacetOptionName = (facetOption: FacetOption): string => {
  const { name, total } = facetOption;
  return `${name} (${total})`;
};

export const stateToQueryParams = (state: State): QueryParams => {
  const { searchText, pagination, filterFacets: facets } = state;

  const initialQueryParams: QueryParams = {
    [SearchTextQueryParamKey]: searchText,
    [PaginationLimitQueryParamKey]: pagination.limit.toString(),
    [PaginationOffsetQueryParamKey]: pagination.offset.toString()
  };

  return facets
    .map(facet => ({
      key: facet.name,
      value: facet.values
        .filter(facetValue => facetValue.selected)
        .map(facetValue => facetValue.value)
    }))
    .reduce<QueryParams>((accumulator, entry) => {
      const { key, value } = entry;
      accumulator[key] = value;
      return accumulator;
    }, initialQueryParams);
};

export const queryParamsToState = (
  queryParams: QueryParams,
  currentFacets: FilterFacet[]
): State => {
  const searchText = (queryParams[SearchTextQueryParamKey] ?? '') as string;
  const pagination = {
    offset: parseInt(queryParams[PaginationOffsetQueryParamKey] as string, 0),
    limit: parseInt(
      queryParams[PaginationLimitQueryParamKey] as string,
      0
    ) as PaginationData['limit']
  };
  const selectedFacetsMap: SelectedFacetsValueMap = Object.entries(
    queryParams
  ).reduce<SelectedFacetsValueMap>((accumulator, entry) => {
    const [key, facetValueOrFacetValues] = entry;

    accumulator[key] = {};

    if (Array.isArray(facetValueOrFacetValues)) {
      for (const facetValue of facetValueOrFacetValues) {
        accumulator[key][facetValue] = true;
      }
    } else {
      accumulator[key][facetValueOrFacetValues] = true;
    }

    return accumulator;
  }, {});

  return {
    searchText,
    pagination,
    filterFacets: currentFacets.map(facet => ({
      ...facet,
      values: facet.values.map(facetValue => ({
        ...facetValue,
        selected: !!selectedFacetsMap[facet.name]?.[facetValue.value]
      }))
    }))
  };
};

export const facetsToFilterFacets = (facets: Facet[]): FilterFacet[] => {
  return facets.map(facet => ({
    name: facet.value,
    values: facet.facetOptions.map(facetOption => ({
      name: hydratedFacetOptionName(facetOption),
      value: facetOption.value,
      selected: facetOption.selected
    }))
  }));
};

export const buildListProps = (params: QueryParams): ListProps => {
  const filters: QueryParams[] = [];
  let searchText, limit: number, offset: number;

  for (const [key, value] of Object.entries(params)) {
    if (key === SearchTextQueryParamKey) {
      searchText = value as string;
    } else if (key === PaginationLimitQueryParamKey) {
      limit = parseInt(value as string);
    } else if (key === PaginationOffsetQueryParamKey) {
      offset = parseInt(value as string);
    } else {
      filters.push({
        filterBy: key,
        filterTerms: Array.isArray(value) ? value : [value]
      });
    }
  }

  return {
    query: searchText ?? '',
    filters,
    pageSize: limit!,
    pageNumber: offset!,
    orderBy: 'todo'
  };
};

export const hasFacetApplied = (facets: Facet[]): boolean => {
  for (const facet of facets) {
    for (const facetOption of facet.facetOptions) {
      if (facetOption.selected) return true;
    }
  }

  return false;
};
