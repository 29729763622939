import { mdiPowerOff } from '@mdi/js';
import React, { useCallback } from 'react';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Card from 'src/components/ui/card';
import IconButton from 'src/components/ui/icon-btn';
import Title from 'src/components/ui/title';
import useAuth from 'src/resources/auth/auth-hook';
import { resolveLocationFromState } from 'src/utils/location-utils';
import './index.scss';

const LoginScreen: React.FC = () => {
  const { signIn } = useAuth();
  const location = useLocation();

  const signInWithOkta = useCallback(() => {
    signIn({
      originalUri: resolveLocationFromState(location)
    });
  }, [location, signIn]);

  return (
    <div className="login">
      <Image src="assets/amway-login-logo.svg" width="149" height="167" />
      <Title className="mt-3" sizeVariant="lg" colorVariant="tertiary">
        Data Catalog
      </Title>
      <Card className="mt-5 card">
        <IconButton
          mdiIconPath={mdiPowerOff}
          color="#FFFFFF"
          size="40px"
          className="okta-login-btn"
          onClick={signInWithOkta}>
          Sign in with Okta
        </IconButton>
      </Card>
    </div>
  );
};

export default LoginScreen;
