import React, { useMemo } from 'react';
import { PageComponent as PageComponentModel } from 'src/resources/data-topic/metrics-details/models';
import Component from '../component';
import './index.scss';
import { makePageComponentLink } from '../../utils';

interface Props {
  page: PageComponentModel;
}

const PageComponent: React.FC<Props> = props => {
  const { page } = props;
  const components = useMemo(
    () => page.components.map((component, idx) => <Component component={component} key={idx} />),
    [page.components]
  );

  return (
    <article className="page-section" id={makePageComponentLink(page)}>
      {components.map((component, idx) => (
        <section key={idx} className="component-section">
          {component}
        </section>
      ))}
    </article>
  );
};

export default PageComponent;
