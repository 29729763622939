import React, { CSSProperties } from 'react';
import './index.scss';

// Reminder: the definitions are found on the scss file
// later, we would describe on storybook
// when this time comes, please, remove this reminder.
interface Props {
  sizeVariant?: 'xl' | 'lg' | 'md' | 'sm';
  colorVariant?: 'primary' | 'secondary' | 'tertiary';
  weight?: 'normal' | 'bold';
  className?: string;
  onClick?: VoidFunction;
  // This should be used in a few cases, usually you are going to use size and color variants
  style?: CSSProperties;
}

const Typography: React.FC<Props> = props => {
  const {
    sizeVariant = 'md',
    weight = 'normal',
    colorVariant = 'secondary',
    className = '',
    style,
    onClick
  } = props;

  return (
    <span
      className={`typography ${sizeVariant} ${colorVariant} ${weight} ${className}`}
      onClick={onClick}
      style={style}>
      {props.children}
    </span>
  );
};

export default Typography;
