import React, { useMemo } from 'react';
import Typography from '../typography';
import './index.scss';
import { TableLink } from 'src/resources/table/models/table-rules-model';
import Chips, { Props as ChipsProps } from '../chips';
import useToasts from 'src/resources/toasts/toasts-hook';
import useTable from 'src/resources/table/table-hook';

interface Props {
  title: string;
  items: TableLink[];
  onAdd?: () => void;
}

const LinksDisplay: React.FC<Props> = ({ title, items, onAdd }) => {
  const { deleteUsefulLink } = useTable();
  const toasts = useToasts();
  const formatLink = (url: string): string => {
    if (!url.includes('http')) {
      return 'http://' + url;
    }
    return url;
  };

  const handleChipsChange: ChipsProps['deleteChip'] = deletedLinkId => {
    void deleteUsefulLink(Number(deletedLinkId))
      .then(() => {
        toasts.push('Link successful deleted!', 'success');
      })
      .catch(() => {
        toasts.push('Error deleting link!', 'error');
      });
  };

  const linksToFacets = useMemo(
    (): ChipsProps['chips'] =>
      items.map(item => ({
        title: (
          <a href={formatLink(item.linkUrl)} target="_blank" rel="noreferrer">
            {item.description}
          </a>
        ),
        value: String(item.tableLinkId)
      })),
    [items]
  );

  return (
    <article className="links-display-container">
      <Typography>{title}</Typography>
      <div className="links-display">
        <Chips
          onAdd={onAdd}
          isReadOnly={false}
          chips={linksToFacets}
          deleteChip={handleChipsChange}
        />
      </div>
    </article>
  );
};

export default LinksDisplay;
