import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SimplePieChart from 'src/components/charts/pie-chart';
import LineChart from 'src/components/charts/smoothed-line-chart';
import MultipleColumnsChart from 'src/components/charts/multiple-columns-chart';
import SideMenuNav from 'src/components/core/side-menu-nav';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Breadcrumbs from 'src/components/ui/breadcrumbs';
import Button from 'src/components/ui/button';
import ComingSoon from 'src/components/ui/coming-soon';
import MetricsDetailsDialog from 'src/components/ui/metrics-details-dialog';
import MetricsDisplay from 'src/components/ui/metrics-display';
import MetricsFilter from 'src/components/ui/metrics-filter';
import {
  datesOptions as metricsDatesOptions,
  Filter
} from 'src/components/ui/metrics-filter/utils';
import PseudoLink from 'src/components/ui/pseudo-link';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import useBreadcrumbs from 'src/resources/breadcrumbs/breadcrumbs-hook';
import useDataTopic from 'src/resources/data-topic/data-topic-hook';
import { DetailedMetricsFilter } from 'src/resources/data-topic/data-topic-types';
import useMarketsData from 'src/resources/markets-data/markets-data-hook';
import { setBoldText } from '../utils';
import DataSetDrawer from './data-set-drawer';
import DetailsRenderer from './details-renderer';
import './index.scss';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';

const sideMenuItems = [
  { label: 'Adm. & Dem.', link: 'adm-demographic' }
  // { label: 'Contact', link: 'contact' },
  // { label: 'Account View', link: 'account-view' },
  // { label: 'Bonus', link: 'bonus' },
  // { label: 'LOS', link: 'los' },
  // { label: 'Clickstream Behavior', link: 'clickstream-behavior' }
];

interface SelectedMetrics {
  title: string;
  currentDataSQL: string;
  previousDataSQL?: string;
}

const DataTopicScreen: React.FC = props => {
  const { breadcrumbs } = useBreadcrumbs();
  const { marketsData, fetchMarketsData } = useMarketsData();
  const {
    dataTopics,
    dataTopicMetrics,
    dataTopicMetricsDetails,
    admDemographicData,
    fetchDataTopics,
    fetchDataTopicMetrics,
    fetchAdmDemographicData,
    fetchDataTopicMetricsDetails
  } = useDataTopic();
  const { facetValue, facetOptionValue } = useParams();
  const [metricsFilterDateName, setMetricsFilterDateName] = useState<string>('');
  const [isDataSetDrawerOpen, setDataSetDrawer] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectedMetrics | null>(null);
  const [isSelectedMetricsDialogOpen, setIsSelectedMetricsDialogOpen] = useState(false);
  const dataTopic = useMemo(
    () => (dataTopics.data ?? []).find(dTopic => dTopic.value === facetOptionValue),
    [dataTopics.data, facetOptionValue]
  );
  const [metricsFilter, setMetricsFilter] = useState<DetailedMetricsFilter | null>();

  useEffect(() => {
    if (!marketsData.data) {
      void fetchMarketsData();
    }
  }, [marketsData.data, fetchMarketsData]);

  useEffect(() => {
    if (!dataTopics.data) {
      void fetchDataTopics();
    }
  }, [dataTopics.data, fetchDataTopics]);

  useEffect(() => {
    let abortFetchDataTopicMetrics: VoidFunction;

    if (metricsFilter) {
      abortFetchDataTopicMetrics = fetchDataTopicMetrics(metricsFilter);
    }

    return () => abortFetchDataTopicMetrics?.();
  }, [fetchDataTopicMetrics, metricsFilter]);

  useEffect(() => {
    if (metricsFilter?.dataTopicValue) {
      void fetchAdmDemographicData({
        countryCodes: metricsFilter.countriesCode,
        dataTopicValue: metricsFilter.dataTopicValue
      });
    }
  }, [fetchAdmDemographicData, metricsFilter]);

  useEffect(() => {
    if (metricsFilter?.dataTopicValue) {
      fetchDataTopicMetricsDetails({
        countriesCode: metricsFilter.countriesCode,
        dataTopicValue: metricsFilter.dataTopicValue,
        datePeriod: metricsFilter.datePeriod
      });
    }
  }, [fetchDataTopicMetricsDetails, metricsFilter]);

  const handleOpenDataSetDrawer = (): void => {
    setDataSetDrawer(true);
  };

  const handleCloseDataSetDrawer = (): void => {
    setDataSetDrawer(false);
  };

  const handleSelectMetric = (selectedMetrics: SelectedMetrics): void => {
    setSelectedMetrics(selectedMetrics);
    setIsSelectedMetricsDialogOpen(true);
  };

  const onMetricsFilterChanges = useCallback(
    (filters: Filter): void => {
      const { market, date } = filters;

      setMetricsFilterDateName(metricsDatesOptions.find(opt => opt.value === date)!.name);

      setMetricsFilter({
        countriesCode: market,
        datePeriod: date,
        dataTopicValue: facetOptionValue as string
      });
    },
    [facetOptionValue]
  );

  return (
    <TealiumDataLayer>
      <Container className="data-topic">
        <Card>
          <Card.Body>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Row className="mt-2">
              <Col xs={11}>
                <Stack direction="horizontal">
                  <Title>Data Topic:</Title>
                  <Title colorVariant="secondary" className="ms-2">
                    {dataTopic?.name}
                  </Title>
                </Stack>
                <Typography>{dataTopic?.description}</Typography>
              </Col>
            </Row>
            <ExternalDataRenderer
              externalData={marketsData}
              makeCustomIsLoading={() => !marketsData.data}
              makeDataElement={data => (
                <MetricsFilter
                  className="mt-4 mb-3"
                  marketsData={data}
                  onChange={onMetricsFilterChanges}
                />
              )}
            />
            <Row>
              <Col>
                <Stack direction="horizontal" className="mt-4 justify-content-between">
                  <ExternalDataRenderer
                    externalData={dataTopicMetrics}
                    makeDataElement={data =>
                      data.map(metrics => (
                        <MetricsDisplay
                          key={metrics.title}
                          previousDataLabel={metricsFilterDateName}
                          metrics={metrics}
                        />
                      ))
                    }
                  />
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-5">
                <Button onClick={handleOpenDataSetDrawer}>VIEW DATA TOPIC</Button>
              </Col>
              <DataSetDrawer
                isOpen={isDataSetDrawerOpen}
                facetValue={facetValue!}
                facetOptionValue={facetOptionValue!}
                close={handleCloseDataSetDrawer}
              />
            </Row>
          </Card.Body>
        </Card>

        {facetOptionValue !== 'account' ? (
          <Card>
            <Card.Body>
              <ExternalDataRenderer
                externalData={dataTopicMetricsDetails}
                makeErrorElement={error =>
                  error.statusCode === 404 ? (
                    <ComingSoon />
                  ) : (
                    <span>Error on fetching data: {error.message}...</span>
                  )
                }
                makeDataElement={dataTopicMetricDetailsData => (
                  <DetailsRenderer view={dataTopicMetricDetailsData} />
                )}
              />
            </Card.Body>
          </Card>
        ) : (
          <Card>
            <Card.Body>
              <ExternalDataRenderer
                externalData={dataTopicMetricsDetails}
                makeErrorElement={() => (
                  <ExternalDataRenderer
                    externalData={admDemographicData}
                    makeErrorElement={error =>
                      error.statusCode === 404 ? (
                        <ComingSoon />
                      ) : (
                        <span>Error on fetching data: {error.message}...</span>
                      )
                    }
                    makeDataElement={data => (
                      <Stack direction="horizontal" className="mt-5 align-items-start">
                        <SideMenuNav
                          style={{ position: 'sticky', top: '10rem' }}
                          sideMenuItems={sideMenuItems}
                        />
                        <div className="nav-content-container">
                          <section id="adm-demographic">
                            <Title>Administrative & Demographic</Title>
                            <Typography weight="bold">
                              Information on the account attributes Amway maintains for
                              administrative management. Contains things like unique identifiers,
                              status, sponsoring relationship, date of signup with Amway, date of
                              birth, the individual’s name, etc.
                            </Typography>
                            <Stack direction="horizontal" className="mt-5">
                              <Stack direction="vertical" gap={5}>
                                <Stack direction="horizontal" gap={5} className="mt-3">
                                  <Stack direction="vertical" gap={5} style={{ width: '30%' }}>
                                    <Typography
                                      sizeVariant="lg"
                                      colorVariant="primary"
                                      weight="bold">
                                      12-month new accounts
                                    </Typography>
                                    <Stack direction="vertical" gap={5}>
                                      <MetricsDisplay
                                        metrics={data?.aboSignup}
                                        className={'me-5'}
                                        previousDataLabel={metricsFilterDateName}
                                      />
                                      <MetricsDisplay
                                        metrics={data?.newRegs}
                                        className={'me-5'}
                                        previousDataLabel={metricsFilterDateName}
                                      />
                                    </Stack>
                                  </Stack>

                                  <Stack direction="vertical" gap={5} style={{ width: '70%' }}>
                                    <MultipleColumnsChart
                                      chartMetrics={{
                                        ABO: data.aboGraph,
                                        'Member/Customer': data.memberCustGraph
                                      }}
                                    />
                                  </Stack>
                                </Stack>

                                <Stack direction="horizontal" gap={5} className="mt-3">
                                  <Stack gap={4} style={{ width: '30%' }}>
                                    <Typography
                                      sizeVariant="lg"
                                      colorVariant="primary"
                                      weight="bold">
                                      Gender
                                    </Typography>
                                    <Typography colorVariant="primary">
                                      Account-related gender statistics.
                                    </Typography>
                                    <Typography colorVariant="primary">
                                      {data?.genderText}
                                    </Typography>
                                    <PseudoLink
                                      onClick={() =>
                                        handleSelectMetric({
                                          title: 'Gender',
                                          currentDataSQL: data.genderSQL
                                        })
                                      }
                                      variant="tertiary">
                                      view SQL
                                    </PseudoLink>
                                  </Stack>

                                  <Stack direction="vertical" gap={5} style={{ width: '70%' }}>
                                    <SimplePieChart id="gender-pie-chart" data={data.genderGraph} />
                                  </Stack>
                                </Stack>

                                <Stack direction="horizontal" gap={5} className="mt-3">
                                  <Stack gap={4} style={{ width: '30%' }}>
                                    <Typography
                                      sizeVariant="lg"
                                      colorVariant="primary"
                                      weight="bold">
                                      Age by Gender
                                    </Typography>
                                    <Stack direction="horizontal" gap={2}>
                                      <Typography
                                        sizeVariant="md"
                                        colorVariant="primary"
                                        weight="bold">
                                        Average Age:
                                      </Typography>
                                      <Typography sizeVariant="md" colorVariant="primary">
                                        {data?.ageByGenderAvg} years
                                      </Typography>
                                    </Stack>
                                    <Stack direction="horizontal" gap={2}>
                                      <Typography
                                        sizeVariant="md"
                                        colorVariant="primary"
                                        weight="bold">
                                        Males:
                                      </Typography>
                                      <Typography sizeVariant="md" colorVariant="primary">
                                        {data?.ageByGenderMale} years
                                      </Typography>
                                    </Stack>
                                    <Stack direction="horizontal" gap={2}>
                                      <Typography
                                        sizeVariant="md"
                                        colorVariant="primary"
                                        weight="bold">
                                        Females:
                                      </Typography>
                                      <Typography sizeVariant="md" colorVariant="primary">
                                        {data?.ageByGenderFml} years
                                      </Typography>
                                    </Stack>
                                    <Typography sizeVariant="md" colorVariant="primary">
                                      {setBoldText(data?.ageByGenderText)}
                                    </Typography>
                                    <PseudoLink
                                      onClick={() =>
                                        handleSelectMetric({
                                          title: 'Gender',
                                          currentDataSQL: data.genderSQL
                                        })
                                      }
                                      variant="tertiary">
                                      view SQL
                                    </PseudoLink>
                                  </Stack>

                                  <Stack direction="vertical" gap={5} style={{ width: '70%' }}>
                                    <LineChart
                                      chartMetrics={{
                                        Male: data.ageByGenderGraphMale,
                                        Female: data.ageByGenderGraphFml
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          </section>
                          {/* MOCKED LAYOUT */}
                          {/* <section className="pt-5" style={{ height: '100vh', width: '100%' }} id="contact">
                      <h1>Contact</h1>
                    </section>
                    <section style={{ height: '100vh', width: '100%' }} id="account-view">
                      <h1>Account View</h1>
                    </section>
                    <section style={{ height: '100vh', width: '100%' }} id="bonus">
                      <h1>Bonus</h1>
                    </section>
                    <section style={{ height: '100vh', width: '100%' }} id="los">
                      <h1>LOS</h1>
                    </section>
                    <section style={{ height: '100vh', width: '100%' }} id="clickstream-behavior">
                      <h1>Clickstream Behavior</h1>
                    </section> */}
                        </div>
                      </Stack>
                    )}
                  />
                )}
                makeDataElement={dataTopicMetricDetailsData => (
                  <DetailsRenderer view={dataTopicMetricDetailsData} />
                )}
              />
            </Card.Body>
          </Card>
        )}
        <MetricsDetailsDialog
          isOpen={isSelectedMetricsDialogOpen}
          title={selectedMetrics?.title ?? ''}
          previousDataLabel={metricsFilterDateName}
          currentDataSQL={selectedMetrics?.currentDataSQL ?? ''}
          previousDataSQL={selectedMetrics?.previousDataSQL ?? ''}
          onClose={() => setIsSelectedMetricsDialogOpen(false)}
        />
      </Container>
    </TealiumDataLayer>
  );
};

export default DataTopicScreen;
