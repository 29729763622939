import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LogoLoadingComponent from 'src/components/core/logo-loading-component';
import TopMenuTabs from 'src/components/core/top-menu-tabs';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Breadcrumbs from 'src/components/ui/breadcrumbs';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import useQueryParams from 'src/hooks/use-query-params';
import useBreadcrumbs from 'src/resources/breadcrumbs/breadcrumbs-hook';
import { Table } from 'src/resources/table/models/table-model';
import useTable from 'src/resources/table/table-hook';
import { dateToShortString, dateToTimeString } from 'src/utils/date-utils';
import { reduceNumber } from 'src/utils/number-formatter-utils';
import { decodeTableId } from '../utils';
import DataSectionTable from './components/data-section-table';
import MetadataList from './components/metadata-list';
import ContextualizedSchemaTable from './components/schema-table/contextualized-index';
import ContextualizedTagsTable from './components/tags-table/contextualized-index';
import ContextualizedPendingChangeRequestsDisplay from './contextualized-hocs/contextualized-pending-change-requests-display';
import ContextualizedTableDescription from './components/table-description/contextualized-index';
import './index.scss';
import DataDistributionModal from './modals/data-distribution-modal';
import TableStatus from 'src/components/ui/table-status';
import DataLineageComponent from './components/data-lineage/';
import DashboardsTable from './components/dashboards-table';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';

const dataDistributionParam = 'data-distribution';

const initialQueryParams = {
  [dataDistributionParam]: 'closed'
};

interface TableMetadata {
  title: string;
  value: string;
}

const tableMetadataMapper = (table: Table): TableMetadata[] => {
  const { lastModified, columns, numRows, numBytes } = table;

  const tableMetadata: TableMetadata[] = [
    {
      title: 'Last Updated',
      value: dateToShortString(lastModified)
    },
    { title: 'Columns', value: columns.length.toString() },
    { title: 'Records', value: numRows.toLocaleString('en-US') },
    { title: 'Size', value: `${reduceNumber(numBytes, 2)}B` }
  ];

  return tableMetadata;
};

let lastColumnName: string;

const DataSetDetailsScreen = (): JSX.Element => {
  const { breadcrumbs, updatePreviousBreadcrumb } = useBreadcrumbs();
  const {
    selectedTable,
    tableSample,
    dataLineage,
    lookerStudioReports,
    findTable,
    findTableSample,
    fetchDataDistribution,
    fetchDataLineage,
    fetchLookerStudioReports
  } = useTable();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [params, setParams] = useQueryParams(initialQueryParams, { state: location.state });
  // Since we are applying a few query params, the navigation state is lost overtime.
  // This enables us to change query params but persist the navigation state.
  const [metadataList, setMetadataList] = useState<Array<{ title: string; value: string }>>([]);
  const [isDataDistributionModalOpen, setIsDataDistributionModalOpen] = useState(false);

  useEffect(() => {
    const decodedId = decodeTableId(id!);
    if (selectedTable.data?.id !== decodedId) {
      void findTable(decodedId);
    }
  }, [id, findTable, selectedTable.data?.id]);

  useEffect(() => {
    if (location.state?.search) {
      // This means that we've got here from elsewhere out of the stack common navigation and
      // still want to update the previous search screen filters
      updatePreviousBreadcrumb(
        'Search Results',
        `/dashboard/search${location.state.search as string}`
      );
    }
  }, [location.state?.search, updatePreviousBreadcrumb]);

  useEffect(() => {
    if (selectedTable.data) {
      void findTableSample(selectedTable.data.dataset, selectedTable.data.tableName);
    }
  }, [findTableSample, selectedTable.data, selectedTable.loading]);

  useEffect(() => {
    if (!selectedTable.loading && selectedTable.data) {
      setMetadataList(tableMetadataMapper(selectedTable.data));
    }
  }, [selectedTable]);

  useEffect(() => {
    if (selectedTable.data) {
      void fetchDataLineage(
        selectedTable.data.tableName,
        selectedTable.data.dataset,
        selectedTable.data.project
      );
    }
  }, [fetchDataLineage, selectedTable.data]);

  useEffect(() => {
    if (selectedTable.data) {
      void fetchLookerStudioReports(selectedTable.data.tableName);
    }
  }, [fetchLookerStudioReports, selectedTable.data]);

  useEffect(() => {
    if (params[dataDistributionParam] !== 'closed') {
      const columnName = String(params[dataDistributionParam]);

      // thats definitely needs refactoring
      if (columnName !== lastColumnName && selectedTable.data) {
        lastColumnName = columnName;

        void fetchDataDistribution(
          selectedTable.data.dataset,
          selectedTable.data.tableName,
          lastColumnName
        );
      }
      setIsDataDistributionModalOpen(true);
    } else {
      setIsDataDistributionModalOpen(false);
    }
  }, [fetchDataDistribution, params, selectedTable.data, setParams]);

  const handleCloseDialog = (): void => {
    setParams(initialQueryParams);
  };

  const handleOpenTableDetails = (columnName: string): void => {
    setParams({ ...params, [dataDistributionParam]: columnName });
  };

  const portalContainerRef = useRef<HTMLDivElement>(null);
  const portalContainerId = 'dataset-details-portal-container';

  return (
    <TealiumDataLayer>
      <Container className="data-set-details">
        <ExternalDataRenderer
          externalData={selectedTable}
          makeLoadingElement={() => (
            <Card>
              <Card.Body>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <LogoLoadingComponent />
              </Card.Body>
            </Card>
          )}
          makeDataElement={selectedTableData => (
            <>
              <Card>
                <Card.Body>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <Col className="mt-2">
                    <Title>
                      Data Distribution:{' '}
                      <Typography sizeVariant="xl">{selectedTableData.tableName}</Typography>
                    </Title>
                    <Stack direction="horizontal" className="my-2">
                      <Title sizeVariant="sm">{selectedTableData.dataset}</Title>
                      <Typography className="ms-2">
                        Created{' '}
                        {`${dateToShortString(selectedTableData.createdAt)} ${dateToTimeString(
                          selectedTableData.createdAt
                        )}`}
                      </Typography>
                      <a
                        href={selectedTableData.resourceUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="metadata-button ms-auto">
                        View BigQuery
                        <InsertLinkIcon fontSize="small" />
                      </a>
                      <a
                        href={selectedTableData.lookerStudioUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="metadata-button ms-4">
                        Open in Looker Studio
                        <InsertLinkIcon fontSize="small" />
                      </a>
                    </Stack>

                    <ContextualizedPendingChangeRequestsDisplay table={selectedTableData} />
                    <Row>
                      <ContextualizedTableDescription table={selectedTableData} />
                    </Row>
                    <Row className="mt-4">
                      <a
                        href="https://lookerstudio.google.com/reporting/1a75ace4-0d31-48c6-9b39-79953d775b96/page/p_zbmrdzvo4c"
                        target="_blank"
                        rel="noreferrer"
                        className="metadata-button mb-2">
                        Quality Dashboard
                        <InsertLinkIcon fontSize="small" />
                      </a>
                      <MetadataList
                        items={[
                          {
                            title: 'Status',
                            value: <TableStatus className="my-1" table={selectedTableData} />
                          },
                          ...metadataList
                        ]}
                      />
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <div
                    ref={portalContainerRef}
                    className="details-portal-container fancy-scrollbar"
                    id={portalContainerId}
                  />
                  <TopMenuTabs
                    topMenuItems={[
                      {
                        label: 'SCHEMA',
                        component: (
                          <>
                            <ContextualizedSchemaTable
                              table={selectedTableData}
                              onClickRow={handleOpenTableDetails}
                              parentId={portalContainerId}
                              parentRef={portalContainerRef}
                            />
                          </>
                        )
                      },
                      {
                        label: 'DATA SECTION',
                        component: (
                          <ExternalDataRenderer
                            externalData={tableSample}
                            makeDataElement={tableSampleData => (
                              <DataSectionTable
                                tableSample={tableSampleData}
                                parentId={portalContainerId}
                                parentRef={portalContainerRef}
                              />
                            )}
                          />
                        )
                      },
                      {
                        label: 'TAGS',
                        component: (
                          <ContextualizedTagsTable
                            table={selectedTableData}
                            parentId={portalContainerId}
                            parentRef={portalContainerRef}
                          />
                        )
                      },
                      {
                        label: 'LOOKER STUDIO DASHBOARDS',
                        component: (
                          <ExternalDataRenderer
                            externalData={lookerStudioReports}
                            makeDataElement={lookerStudioReports => (
                              <DashboardsTable
                                reports={lookerStudioReports}
                                parentId={portalContainerId}
                                parentRef={portalContainerRef}
                              />
                            )}
                          />
                        )
                      },
                      {
                        label: 'DATA LINEAGE',
                        component: (
                          <ExternalDataRenderer
                            externalData={dataLineage}
                            makeDataElement={dataLineage => (
                              <DataLineageComponent dataLineage={dataLineage} />
                            )}
                          />
                        )
                      }
                    ]}
                  />
                </Card.Body>
              </Card>
            </>
          )}
        />
        <DataDistributionModal
          open={isDataDistributionModalOpen}
          onClose={handleCloseDialog}
          onConfirm={() => {
            navigate(
              `/dashboard/search/${String(id)}/data-distribution/${String(
                params[dataDistributionParam]
              )}`
            );
          }}
          columnName={String(params[dataDistributionParam])}
        />
      </Container>
    </TealiumDataLayer>
  );
};

export default DataSetDetailsScreen;
