import { createContext } from 'react';
import { ToastType } from 'src/@types/toasts';

export interface ToastContextType {
  push: (text: string, type?: ToastType, delay?: number) => void;
}

const toastContext = createContext<ToastContextType>(null as any);

export default toastContext;
