import { Permission } from 'src/resources/features/features-types';
import Features, { Feature } from './features';

const PERMISSIONS: Record<Permission, Feature[]> = Object.seal({
  [Permission.BasicAccess]: [
    Features.DashboardScreen,
    Features.DashboardScreen.DataTopicsScreen,
    Features.DashboardScreen.SearchResultsScreen,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataDistributionScreen,
    Features.RequestedChangesScreen
  ],
  [Permission.RefreshData]: [
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataDistributionScreen
      .RefreshDataDistribution
  ],
  [Permission.RequestChanges]: [
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.PendingChangeRequestsDisplay,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.TableDescriptionEdition,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.SchemaTableEdition,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.TagsTableEdition,
    Features.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.DataLineageEdition
  ],
  [Permission.ReviewChangeRequests]: [
    Features.RequestedChangesScreen.ReviewChangeRequests,
    Features.RequestedChangesScreen.ManageFeedbacks
  ],
  [Permission.ManagePermissions]: [],
  [Permission.UploadLookerStudioLogs]: [Features.UploadLookerStudioLogs]
});

export default PERMISSIONS;
