import { TableSample } from '../models/table-sample-model';

export const tableSampleMapper = (apiData: any): TableSample => {
  const { header, values, dataTypes } = apiData;

  return {
    header: ['#', ...header],
    values,
    formattedValues: values.map((value: any, idx: number) => [idx + 1, ...value]),
    dataTypes: ['number', ...dataTypes]
  };
};
