import React from 'react';
import { Table } from 'src/resources/table/models/table-model';
import DataSetItem from './data-set-item';
import './index.scss';

interface Props {
  items: Table[];
  onNavClick: (id: string) => void;
}

const DataSetList: React.FC<Props> = props => {
  const { items, onNavClick } = props;

  return (
    <ul className="data-set-list fancy-scrollbar">
      {items.map(table => (
        <DataSetItem key={table.id} table={table} onClick={() => onNavClick(table.id)} />
      ))}
    </ul>
  );
};

export default DataSetList;
