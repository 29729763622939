import { AccordionItemProps } from 'src/components/ui/accordion';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import {
  Feedback,
  FeedbackStatus as FeedbackStatusEnum
} from 'src/resources/feedback/feedback-model';
import { dateToStringRelative } from 'src/utils/date-utils';
import {
  getFeedbackEventFlowLabel,
  getFeedbackStatusLabel,
  getFeedbackSubjectLabel
} from '../../feedback-utils';
import FeedbackItemBody from './feedback-item-body';
import { mdiAlertCircle, mdiAlertRhombus, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import './index.scss';

const feedbackStatusMap: Record<Feedback['status'], JSX.Element> = {
  [FeedbackStatusEnum.Pinned]: (
    <span className="feedback-status pinned">
      <Icon color="var(--warning-blue)" path={mdiAlertRhombus} size="16px" />
      {getFeedbackStatusLabel(FeedbackStatusEnum.Pinned)}
    </span>
  ),
  [FeedbackStatusEnum.Seen]: (
    <span className="feedback-status seen">
      <Icon color="var(--warning-success)" path={mdiCheckCircle} size="16px" />
      {getFeedbackStatusLabel(FeedbackStatusEnum.Seen)}
    </span>
  ),
  [FeedbackStatusEnum.Unseen]: (
    <span className="feedback-status unseen">
      <Icon color="var(--warning-orange)" path={mdiAlertCircle} size="16px" />
      {getFeedbackStatusLabel(FeedbackStatusEnum.Unseen)}
    </span>
  )
};

function FeedbackStatus(feedback: Feedback): JSX.Element {
  return feedbackStatusMap[feedback.status];
}

function FeedbackItem(feedback: Feedback): AccordionItemProps {
  return {
    header: (
      <header className="feedback-header">
        <div className="info-container">
          <Title sizeVariant="sm" colorVariant="tertiary">
            {getFeedbackSubjectLabel(feedback.subject)}
          </Title>
          <Typography sizeVariant="sm">{getFeedbackEventFlowLabel(feedback.eventFlow)}</Typography>
          <FeedbackStatus {...feedback} />
        </div>
        <div className="metadata-container">
          <Typography sizeVariant="sm" colorVariant="secondary">
            Sent by:
          </Typography>
          <Typography sizeVariant="sm" colorVariant="primary">
            {feedback.sentBy} at {dateToStringRelative(feedback.createdAt)}
          </Typography>
        </div>
      </header>
    ),
    content: <FeedbackItemBody feedback={feedback} />
  };
}

export default FeedbackItem;
