import { mdiCursorDefaultClickOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyToClipboard from '../copy-to-clipboard';
import Title from '../title';
import './index.scss';

interface Props {
  title: string;
  text: string;
  onCopySuccessText?: string;
  className?: string;
}

const CodeSnippet: React.FC<Props> = props => {
  const { title, text, onCopySuccessText, className = '' } = props;

  return (
    <article className={`code-snippet ${className}`}>
      <header>
        <Title sizeVariant="sm" colorVariant="secondary">
          {title}
        </Title>
      </header>
      <section>
        <CopyToClipboard
          text={text}
          successText={onCopySuccessText}
          className="cpy-icon"
          tooltip={{
            title: (
              <>
                Click to copy{' '}
                <Icon
                  className="ms-1"
                  path={mdiCursorDefaultClickOutline}
                  size="16px"
                  color="var(--bg-white)"
                />
              </>
            ),
            placement: 'bottom-end'
          }}
        />
        <pre className="fancy-scrollbar">
          <code>{text}</code>
        </pre>
      </section>
    </article>
  );
};

export default CodeSnippet;
