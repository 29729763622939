import React, { useCallback, useMemo, useState } from 'react';
import {
  ChartComponent as ChartComponentModel,
  Orientation
} from 'src/resources/data-topic/metrics-details/models';
import './index.scss';
import { makeChart } from '../../chart-components-factory';
import PseudoLink from 'src/components/ui/pseudo-link';
import MetricsDetailsDialog from 'src/components/ui/metrics-details-dialog';

interface Props {
  component: ChartComponentModel;
}

const ChartComponent: React.FC<Props> = props => {
  const { component } = props;
  const chart = useMemo(() => makeChart(component.chart), [component.chart]);
  const [isSqlDialogOpen, setIsSqlDialogOpen] = useState(false);

  const handleToggleSqlDialog = useCallback(() => {
    setIsSqlDialogOpen(prev => !prev);
  }, []);

  return (
    <>
      <article
        className={`chart-component ${
          component.orientation === Orientation.Horizontal ? 'horizontal' : 'vertical'
        }`}>
        <header>
          <h4>{component.title}</h4>
          <h6>{component.description}</h6>
          <PseudoLink onClick={handleToggleSqlDialog} variant="tertiary">
            view SQL
          </PseudoLink>
        </header>
        <section>{chart}</section>
      </article>
      <MetricsDetailsDialog
        isOpen={isSqlDialogOpen}
        title={component.title}
        currentDataSQL={component.sql}
        onClose={handleToggleSqlDialog}
      />
    </>
  );
};

export default ChartComponent;
