import MetricsDisplay from 'src/components/ui/metrics-display';
import {
  DataDisplaySection as DataDisplaySectionModel,
  Orientation
} from 'src/resources/data-topic/metrics-details/models';
import './index.scss';

interface Props {
  component: DataDisplaySectionModel;
}

const DataDisplaySection: React.FC<Props> = props => {
  const { component } = props;

  return (
    <article
      className={`data-display-section ${
        component.orientation === Orientation.Horizontal ? 'horizontal' : 'vertical'
      }`}>
      <header>
        <h4>{component.title}</h4>
        <h6>{component.description}</h6>
      </header>
      <section className="items-container">
        {component.items.map((item, idx) => (
          <article key={item.currentData + idx} className="data-display-item">
            <MetricsDisplay metrics={item} />
          </article>
        ))}
      </section>
    </article>
  );
};

export default DataDisplaySection;
