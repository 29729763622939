import React, { RefObject, useMemo } from 'react';
import { LookerStudioReport } from 'src/resources/table/models/looker-studio-reports-model';
import Table from '../table';
import { ColumnDataType, TableRow } from '../table/table-types';

interface Props {
  reports: LookerStudioReport[];
  parentId?: string;
  parentRef?: RefObject<HTMLDivElement>;
}
const header = ['#', 'Report Name', 'Owner', 'Creation Date'];
const dataTypes = ['number', 'string', 'string', 'string'] as ColumnDataType[];

const DashboardsTable: React.FC<Props> = ({ reports, parentId, parentRef }: Props) => {
  const formattedValues = useMemo(() => {
    return reports.map((report, idx) => [
      idx,
      report.reportName,
      report.owner,
      String(report.reportCreationDate).replace('T', ' ')
    ]);
  }, [reports]);

  return (
    <Table
      description=""
      headers={header}
      dataTypes={dataTypes}
      hasTopScrollbar
      hasInfiniteScroll
      rows={formattedValues.map(
        (items, idx) =>
          ({
            data: items,
            openUrl: `https://lookerstudio.google.com/reporting/${reports[idx].reportId}/page/TMFBD`
          } as TableRow)
      )}
      isSortable
      hasSearchBar
      parentId={parentId}
      parentRef={parentRef}
    />
  );
};

export default DashboardsTable;
