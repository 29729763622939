import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Col } from 'react-bootstrap';
import Typography from 'src/components/ui/typography';
import { Table } from 'src/resources/table/models/table-model';

export interface Props {
  table: Table;
  onEdit?: VoidFunction;
}

const TableDescription: React.FC<Props> = props => {
  const { table, onEdit } = props;

  return (
    <Col className="description-container">
      <Typography>{table.description}</Typography>
      <div>{onEdit && <EditIcon className="ms-2" onClick={onEdit} />}</div>
    </Col>
  );
};

export default TableDescription;
