import { Props as ChipsProps } from '../chips';
import { Facet } from './types';

interface ActiveFacetsRecord {
  [facetName: string]: {
    [facetValue: string]: true;
  };
}

const facetValueDataToChipValue = (facetName: string, facetValueValue: string): string => {
  return `${facetName}/${facetValueValue}`;
};

const chipValueToFacetValueData = (
  chipValue: string
): { facetName: string; facetValueValue: string } => {
  const [facetName, facetValueValue] = chipValue.split('/');

  return { facetName, facetValueValue };
};

export function syncFacets(facets: Facet[], chips: ChipsProps['chips']): Facet[] {
  const activeIntersectionFacetsRecord: ActiveFacetsRecord = chips
    .map(chip => chipValueToFacetValueData(chip.value))
    .reduce<ActiveFacetsRecord>((acc, next) => {
      const { facetName, facetValueValue } = next;

      if (!(facetName in acc)) acc[facetName] = {};

      acc[facetName][facetValueValue] = true;

      return acc;
    }, {});

  return facets.map(facet => {
    // if (facet.kind === FacetKind.Intersection) return facet;

    return {
      ...facet,
      values: facet.values.map(facetValue => ({
        ...facetValue,
        selected: !!activeIntersectionFacetsRecord[facet.name]?.[facetValue.value]
      }))
    };
  });
}

export function facetsToChips(facets: Facet[]): ChipsProps['chips'] {
  return (
    facets
      // .filter(facet => facet.kind !== FacetKind.Intersection)
      .map(facet => ({
        facetName: facet.name,
        selectedFacetValues: facet.values.filter(facetValue => facetValue.selected)
      }))
      .flatMap(({ selectedFacetValues, facetName }) =>
        selectedFacetValues.map(({ name, value }) => ({
          title: name,
          value: facetValueDataToChipValue(facetName, value)
        }))
      )
  );
}
