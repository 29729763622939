import LastPageIcon from '@mui/icons-material/LastPage';
import Card from 'src/components/ui/card';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import { DataTopic } from 'src/resources/data-topic/data-topic-model';
import './index.scss';

interface Props extends DataTopic {
  onClick: VoidFunction;
}

const DataTopicItem: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, description, onClick, disabled } = props;

  return (
    <Card onClick={onClick} className={`category-item-container ${disabled ? 'disabled' : ''}`}>
      <aside>
        <LastPageIcon />
      </aside>
      <div>
        <Title sizeVariant="sm" colorVariant="tertiary">
          {name}
        </Title>
        <Typography className="mt-2" colorVariant="secondary">
          {description}
        </Typography>
      </div>
    </Card>
  );
};

export default DataTopicItem;
