import { Root, Tooltip } from '@amcharts/amcharts5';
import {
  AxisRenderer,
  AxisRendererY,
  IValueAxisSettings,
  ValueAxis,
  XYChart
} from '@amcharts/amcharts5/xy';
import { Scale } from 'src/resources/data-topic/metrics-details/models';

const getScaleConfig = (scale: Scale): Partial<IValueAxisSettings<AxisRenderer>> => {
  if (scale === Scale.Logarithmic) {
    return { extraMax: 0.1, logarithmic: true, treatZeroAs: 0.01, maxPrecision: 0 };
  }

  return {
    maxDeviation: 0,
    min: 0,
    extraMax: 0.1,
    maxPrecision: 0
  };
};

export const makeYAxis = (root: Root, chart: XYChart, scale: Scale): ValueAxis<AxisRenderer> => {
  const yAxis = chart.yAxes.push(
    ValueAxis.new(root, {
      ...getScaleConfig(scale),
      renderer: AxisRendererY.new(root, {})
    })
  );

  yAxis.set(
    'tooltip',
    Tooltip.new(root, {
      themeTags: ['axis']
    })
  );

  return yAxis;
};
