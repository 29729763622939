import React, { useCallback, useState } from 'react';
import { AbortFunction, ExternalData } from 'src/@types/external-api';
import DataTopicsContext from 'src/resources/data-topic/data-topic-context';
import useExternalApiErrorHandler from 'src/hooks/use-external-api-error-handler';
import {
  AdmDemographicData,
  DataTopic,
  DataTopicMetrics
} from 'src/resources/data-topic/data-topic-model';
import dataTopicsService from 'src/resources/data-topic/data-topic.service';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData
} from 'src/helpers/external-data';
import { MetricsFilters, AdmDemographicFilters, DetailedMetricsFilter } from './data-topic-types';
import axios from 'axios';
import { DataTopicMetricsDetails } from './metrics-details/models';

const DataTopicsProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [dataTopics, setDataTopics] = useState<ExternalData<DataTopic[]>>(
    makeExternalDataInitialData()
  );
  const [dataTopicMetrics, setDataTopicMetrics] = useState<ExternalData<DataTopicMetrics[]>>(
    makeExternalDataInitialData()
  );
  const [dataTopicMetricsDetails, setDataTopicMetricsDetails] = useState<
    ExternalData<DataTopicMetricsDetails>
  >(makeExternalDataInitialData());
  const [globalDataTopicMetrics, setGlobalDataTopicMetrics] = useState<
    ExternalData<DataTopicMetrics[]>
  >(makeExternalDataInitialData());

  const [admDemographicData, setAdmDemographicData] = useState<ExternalData<AdmDemographicData>>(
    makeExternalDataInitialData()
  );

  const fetchDataTopics = useCallback(async (): Promise<void> => {
    try {
      setDataTopics(makeExternalDataInitialKeepData);
      const data = await dataTopicsService.list();
      setDataTopics(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setDataTopics(makeExternalCallErrorData(err));
      errorHandler(err);
    }
  }, [errorHandler]);

  const fetchDataTopicMetrics = useCallback(
    (filter: MetricsFilters): AbortFunction => {
      const { promise, abort } = dataTopicsService.metrics(filter);

      setDataTopicMetrics(prev => makeExternalDataInitialKeepData(prev, abort));

      promise
        .then(data => {
          setDataTopicMetrics(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setDataTopicMetrics(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setDataTopicMetrics(makeExternalDataInitialData());
            }
          });
        });

      return abort;
    },
    [errorHandler]
  );

  const fetchGlobalDataTopicMetrics = useCallback(
    (filter: MetricsFilters): AbortFunction => {
      const { promise, abort } = dataTopicsService.metrics(filter);

      setGlobalDataTopicMetrics(prev => makeExternalDataInitialKeepData(prev, abort));

      promise
        .then(data => {
          setGlobalDataTopicMetrics(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setGlobalDataTopicMetrics(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setGlobalDataTopicMetrics(makeExternalDataInitialData());
            }
          });
        });

      return abort;
    },
    [errorHandler]
  );

  const fetchAdmDemographicData = useCallback(
    async (filter: AdmDemographicFilters): Promise<void> => {
      try {
        setAdmDemographicData(makeExternalDataInitialData());
        const data = await dataTopicsService.admDemographic(filter);
        setAdmDemographicData(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setAdmDemographicData(makeExternalCallErrorData(err));

        // this statement gotta be removed, as soon as we have all data topics covered here
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          return;
        }

        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchDataTopicMetricsDetails = useCallback(
    async (filter: DetailedMetricsFilter): Promise<void> => {
      try {
        setDataTopicMetricsDetails(makeExternalDataInitialData());
        const data = await dataTopicsService.metricDetails(filter);
        setDataTopicMetricsDetails(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setDataTopicMetricsDetails(makeExternalCallErrorData(err));

        // this statement gotta be removed, as soon as we have all data topics covered here
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          return;
        }

        errorHandler(err);
      }
    },
    [errorHandler]
  );

  return (
    <DataTopicsContext.Provider
      value={{
        dataTopics,
        dataTopicMetrics,
        globalDataTopicMetrics,
        admDemographicData,
        dataTopicMetricsDetails,
        fetchDataTopics,
        fetchDataTopicMetrics,
        fetchGlobalDataTopicMetrics,
        fetchAdmDemographicData,
        fetchDataTopicMetricsDetails
      }}>
      {children}
    </DataTopicsContext.Provider>
  );
};

export default DataTopicsProvider;
