import { useState } from 'react';
import Input from 'src/components/ui/input';
import Typography from 'src/components/ui/typography';
import Dialog from '../../../../../components/core/dialog-layout';
import './index.scss';
import LoadingComponent from 'src/components/core/loading-component';
import { Table } from 'src/resources/table/models/table-model';

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (
    newValue: string,
    currentValue: string,
    columnName: string,
    requestReason: string
  ) => void;
  loading: boolean;
  column: string;
  data: Table;
}

const EditSchemaDescriptionModal: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  loading,
  column,
  data
}) => {
  const [newValue, setNewValue] = useState('');
  const [requestReason, setRequestReason] = useState('');

  const currentValue = data.columns.find(c => c.name === column)?.description ?? '';

  return (
    <Dialog
      open={open}
      title="Edit Schema Description"
      subtitle="Your change request will be executed after approval."
      scapeActionText="CLOSE"
      confirmActionText={loading ? <LoadingComponent /> : 'REQUEST EDIT'}
      confirmActionHandler={
        newValue.length > 0 && requestReason.length > 0
          ? () => onConfirm(newValue, currentValue, column, requestReason)
          : undefined
      }
      scapeActionHandler={onClose}
      maxWidth="xs">
      <div className="edit-schema-description-main-container">
        <div className="edit-schema-description-dialog-header">
          <Typography colorVariant="primary" weight="bold">
            {column}
          </Typography>
          <Typography sizeVariant="sm">Current Description:</Typography>
          <Typography sizeVariant="sm" colorVariant="primary">
            {currentValue}
          </Typography>
        </div>
        <div className="mt-5">
          <Input
            className="mt-2"
            title="New description:"
            required
            placeholder="Type new description"
            onChange={e => setNewValue(e.target.value)}
          />
        </div>
        <Input
          title="Request Reason"
          required
          className="mt-4"
          placeholder="Type your request reason"
          onChange={e => setRequestReason(e.target.value)}
        />
      </div>
    </Dialog>
  );
};

export default EditSchemaDescriptionModal;
