import { createContext } from 'react';
import { MarketsData } from 'src/resources/markets-data/markets-data-model';
import { ExternalData } from 'src/@types/external-api';

export interface MarketsDataCtxType {
  marketsData: ExternalData<MarketsData>;
  fetchMarketsData: () => Promise<void>;
}

const MarketsDataContext = createContext<MarketsDataCtxType>(null as any);

export default MarketsDataContext;
