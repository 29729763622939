import { useEffect, useRef } from 'react';

type OnIntersectFunction = () => void;

type ObserverElement = JSX.Element;

type UseIntersectElementParams = (
  onIntersect: OnIntersectFunction,
  leftIntersection?: OnIntersectFunction
) => ObserverElement;

const useIntersectElement: UseIntersectElementParams = (
  onIntersect: OnIntersectFunction,
  leftIntersection?: OnIntersectFunction
) => {
  const observerElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        onIntersect();
      } else {
        if (leftIntersection) {
          leftIntersection();
        }
      }
    });
    if (observerElementRef.current) {
      intersectionObserver.observe(observerElementRef.current);
    }
    return () => intersectionObserver.disconnect();
  }, [leftIntersection, onIntersect]);

  return <div style={{ height: '5px' }} ref={observerElementRef} />;
};

export default useIntersectElement;
