import { createContext } from 'react';
import { Feedback, FeedbackStatus } from 'src/resources/feedback/feedback-model';
import { ExternalAction } from '../../@types/external-api';
import { ExternalData } from './../../@types/external-api';
import { PinnedFeedback, SeenFeedback, UnseenFeedback } from './feedback-model';
import {
  CreateFeedback,
  FetchFeedbacksRequestParams,
  FetchFeedbacksRequestWithoutStatusParams,
  ListResult
} from './feedback-types';

export interface FeedbackCtxType {
  createStatus: ExternalAction;
  updateStatusStatus: ExternalAction;
  feedbacks: ExternalData<ListResult<Feedback>>;
  unseenFeedbacks: ExternalData<ListResult<UnseenFeedback>>;
  pinnedFeedbacks: ExternalData<ListResult<PinnedFeedback>>;
  seenFeedbacks: ExternalData<ListResult<SeenFeedback>>;
  create: (createFeedback: CreateFeedback) => Promise<void>;
  updateStatus: (feedbackId: string, status: FeedbackStatus) => Promise<void>;
  fetchFeedbacks: (params: FetchFeedbacksRequestParams) => Promise<void>;
  fetchUnseenFeedbacks: (params: FetchFeedbacksRequestWithoutStatusParams) => Promise<void>;
  fetchPinnedFeedbacks: (params: FetchFeedbacksRequestWithoutStatusParams) => Promise<void>;
  fetchSeenFeedbacks: (params: FetchFeedbacksRequestWithoutStatusParams) => Promise<void>;
}

const feedbackContext = createContext<FeedbackCtxType>(null as any);

export default feedbackContext;
