import './index.scss';

export interface Props {
  step: number;
  title: string;
  description: string;
}

const StepInstruction: React.FC<Props> = props => {
  const { step, title, description } = props;

  return (
    <article className="step-instruction">
      <div className="step">{step}</div>
      <div className="content">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </article>
  );
};

export default StepInstruction;
