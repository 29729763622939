import { PropsWithChildren, ReactElement, ValidationMap, WeakValidationMap } from 'react';
import { Feature } from 'src/config/features';
import useFeatures from '../resources/features/features-hook';

interface ContextualizedFunctionComponent<P = {}> {
  (props: PropsWithChildren<P>, hasFeature: boolean): ReactElement<any, any> | null;
  propTypes?: WeakValidationMap<P> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<P> | undefined;
  displayName?: string | undefined;
}

export const WithFeaturesProxy =
  <P extends unknown>(resourceFeature: Feature) =>
  (applyContext: ContextualizedFunctionComponent<P>): React.FC<P> => {
    return props => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { hasFeature } = useFeatures();

      return applyContext(props, hasFeature(resourceFeature));
    };
  };
