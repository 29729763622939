import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ExternalData } from '../../../../../@types/external-api';
import DataTopicItem from '../data-topic-item';
import './index.scss';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import { DataTopic } from 'src/resources/data-topic/data-topic-model';

interface Props {
  dataTopics: ExternalData<DataTopic[]>;
  className?: string;
}

interface ListItem extends DataTopic {
  to: string;
}

const dataTopicFacetValue = 'dataTopic_s';

const dataTopicToListItem = (dataTopics: DataTopic[]): ListItem[] => {
  return dataTopics.map(dataTopic => ({
    ...dataTopic,
    to: `data-topic/${dataTopicFacetValue}/${dataTopic.value}`
  }));
};

const DataTopicList: React.FC<Props> = ({ dataTopics: facetOptions, className }) => {
  const navigate = useNavigate();

  const handleItemClick = (item: ListItem): void => {
    if (item.disabled) return;

    navigate(item.to);
  };

  return (
    <Col className={`data-topic-list-container ${className ?? ''}`}>
      <ExternalDataRenderer
        externalData={facetOptions}
        makeDataElement={data => (
          <ul>
            {/* I think we may create a link component to abstract the styles below, not sure */}
            {dataTopicToListItem(data).map(dataTopic => (
              <DataTopicItem
                key={dataTopic.value}
                onClick={() => handleItemClick(dataTopic)}
                {...dataTopic}
              />
            ))}
          </ul>
        )}
      />
    </Col>
  );
};

export default DataTopicList;
