import SimplePieChart from 'src/components/charts/pie-chart';
import LineChart from 'src/components/charts/smoothed-line-chart';
import MultipleColumnsChart from 'src/components/charts/multiple-columns-chart';
import VerticalBarChart from 'src/components/charts/vertical-bar-chart';
import {
  Chart as ChartModel,
  ColumnChart as ColumnChartModel,
  LineChart as LineChartModel,
  PieChart as PieChartModel,
  StackedColumnChart as StackedColumnChartModel
} from 'src/resources/data-topic/metrics-details/models';
import { LOW_PERFORMANCE_Hash } from 'src/utils/hash-utils';
import StackedColumnsChart from 'src/components/charts/stacked-columns-chart';

export const makeChart = (chart: ChartModel): JSX.Element => {
  if (chart instanceof PieChartModel) {
    return (
      // We don't need to have these charts having this "id" property. We can use the useRef hook from react,
      // instead of forcing the client to provide an id. For the time being, i'm using this bad hash function
      // to have an unique chart id based on the object.
      <SimplePieChart
        id={LOW_PERFORMANCE_Hash(chart)}
        data={chart.parameters.map(p => ({ label: p.label, count: p.data }))}
        hasLegend={chart.legend}
      />
    );
  } else if (chart instanceof ColumnChartModel) {
    if (chart.parameters.length === 1) {
      return (
        <VerticalBarChart
          data={chart.parameters[0].data.map((value, idx) => ({
            key: chart.labels[idx],
            value
          }))}
          scale={chart.scale}
        />
      );
    } else {
      return (
        <MultipleColumnsChart
          hasLegend={chart.legend}
          chartMetrics={chart.parameters.reduce<any>((acc, parameter) => {
            acc[parameter.label] = parameter.data.map((it, idx) => ({
              label: chart.labels[idx],
              count: it
            }));

            return acc;
          }, {})}
          scale={chart.scale}
        />
      );
    }
  } else if (chart instanceof LineChartModel) {
    return (
      <LineChart
        hasLegend={chart.legend}
        chartMetrics={chart.parameters.reduce<any>((acc, parameter) => {
          acc[parameter.label] = parameter.data.map((it, idx) => ({
            label: chart.labels[idx],
            count: it
          }));

          return acc;
        }, {})}
        scale={chart.scale}
      />
    );
  } else if (chart instanceof StackedColumnChartModel) {
    return (
      <StackedColumnsChart
        hasLegend={chart.legend}
        chartMetrics={chart.parameters.reduce<any>((acc, parameter) => {
          acc[parameter.label] = parameter.data.map((it, idx) => ({
            label: chart.labels[idx],
            count: it
          }));

          return acc;
        }, {})}
        scale={chart.scale}
      />
    );
  }

  return null as unknown as JSX.Element;
};
