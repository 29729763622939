import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RequireAuth from 'src/components/hocs/require-auth';
import { Feature } from 'src/config/features';
import { authenticatedRoutes, RouteData } from 'src/config/routes';
import BreadcrumbsProvider from 'src/resources/breadcrumbs/breadcrumbs-provider';
import useFeatures from 'src/resources/features/features-hook';
import LoadingScreen from 'src/screens/loading';
import NotFoundScreen from 'src/screens/not-found';
import { resolveLocationFromState } from 'src/utils/location-utils';

const filterAllowedRoutes = (hasFeature: (feature: Feature) => boolean): RouteData[] => {
  return authenticatedRoutes.filter(route => hasFeature(route.feature));
};

const AuthenticatedRouter: React.FC = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { loading: permissionsAreLoading, hasFeature } = useFeatures();
  const allowedRoutes = useMemo(() => filterAllowedRoutes(hasFeature), [hasFeature]);

  if (permissionsAreLoading) {
    return <LoadingScreen />;
  }

  return (
    <BreadcrumbsProvider routes={allowedRoutes}>
      <Routes>
        <Route path="" element={<Navigate to="/dashboard" replace />} />
        <Route path="/logout/callback" element={<Navigate to="/dashboard" replace />} />
        {allowedRoutes.map(({ path, element, Layout }) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAuth>
                <Layout>{element}</Layout>
              </RequireAuth>
            }
          />
        ))}
        <Route
          path="login"
          element={<Navigate to={resolveLocationFromState(location)} replace={true} />}
        />
        <Route
          path="login/callback"
          element={<Navigate to={resolveLocationFromState(location)} replace={true} />}
        />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BreadcrumbsProvider>
  );
};

export default AuthenticatedRouter;
