import Chips, { Props as ChipsProps } from '../chips';
import FacetsList from './facets-list';
import { Facet, FacetValue, OrderBy } from './types';
import { syncFacets, facetsToChips } from './utils';
import './index.scss';
import { useMemo } from 'react';

export interface Props {
  // Applied facets does make sense as long as you are receiving from the api the applied facets.
  // Therefore, "facets" represents the facets that the user can change at any time, and
  // "appliedFacets" represents the facets that are applied to what the user is looking at.
  appliedFacets?: Facet[];
  orderBy: OrderBy[];
  facets: Facet[];
  onFacetsChange: (facets: Facet[]) => void;
  onOrderByChange: (orderBy: OrderBy[]) => void;
  className?: string;
}

export const hasUnselectedStatus = (facetValues: FacetValue[]): boolean => {
  return facetValues.every(fV => !fV.selected);
};

const Filter: React.FC<Props> = (props: Props) => {
  const {
    // orderBy,
    facets,
    appliedFacets,
    onFacetsChange,
    // onOrderByChange,
    className
  } = props;

  const chipsFacets = useMemo(
    () => facetsToChips(appliedFacets ?? facets),
    [appliedFacets, facets]
  );

  // TEMPORARY: Order/Sort By removed for now
  // const handleOrderByChanges: SelectProps['onChange'] = e => {
  //   onOrderByChange(orderBy.map(entry => ({ ...entry, selected: entry.value === e.target.value })));
  // };

  const handleChipsChange: ChipsProps['onChange'] = newChips => {
    onFacetsChange(syncFacets(facets, newChips));
  };

  return (
    <section className={`filter ${className}`}>
      {/* TEMPORARY: Order/Sort By removed for now */}
      {/* <Select
        title="Order/Sort By"
        options={orderBy.map(({ name }) => ({
          name,
          value: name
        }))}
        onChange={handleOrderByChanges}
      /> */}
      <Chips isReadOnly={false} chips={chipsFacets} onChange={handleChipsChange} />
      <FacetsList facets={facets} onChange={onFacetsChange} />
    </section>
  );
};

export default Filter;
