import { GridLayout, Legend, Root, percent } from '@amcharts/amcharts5';
import { PieChart, PieSeries } from '@amcharts/amcharts5/percent';
import { XYChart } from '@amcharts/amcharts5/xy';

export const withLegend = (root: Root, chart: XYChart): void => {
  const legend = chart.children.push(
    Legend.new(root!, {
      layout: GridLayout.new(root, {
        fixedWidthGrid: true
      })
    })
  );

  legend.data.setAll(chart.series.values);
};

export const withPieChartLegend = (root: Root, chart: PieChart, series: PieSeries): void => {
  const legend = chart.children.push(
    Legend.new(root, {
      centerX: percent(50),
      x: percent(50),
      layout: root.horizontalLayout
    })
  );

  legend.data.setAll(series.dataItems);
};
