import React from 'react';
import { Table } from 'src/resources/table/models/table-model';
import { dateToShortString } from 'src/utils/date-utils';
import './index.scss';

interface Props {
  table: Table;
  shouldShowDates?: boolean;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TABLE_HEALTH_TYPES = {
  '80-100': 'success',
  '60-79': 'intermediate',
  '1-59': 'fail',
  0: 'unavailable'
} as const;

type TableHealthTypes = (typeof TABLE_HEALTH_TYPES)[keyof typeof TABLE_HEALTH_TYPES];

export function getTableHealth(value?: number): TableHealthTypes {
  for (const key in TABLE_HEALTH_TYPES) {
    const [min, max] = key.split('-').map(Number);
    if ((value ?? 0) >= min && (value ?? 0) <= max) {
      return TABLE_HEALTH_TYPES[key as keyof typeof TABLE_HEALTH_TYPES] as TableHealthTypes;
    }
  }
  return 'unavailable';
}

const TableStatus: React.FC<Props> = props => {
  const { table, shouldShowDates = false, className = '' } = props;

  return (
    <article className={`table-status-container ${className}`}>
      <span className={`table-health ${getTableHealth((table.health ?? 0) * 100)}`}>
        Health:{' '}
        {table.health !== null && table.health !== undefined && table.health !== 0
          ? `${(table.health * 100).toFixed(1)}%`
          : 'Unavailable'}
      </span>
      {/* CERTIFIED/UNCERTIFIED REMOVED FOR NOW */}
      {/* <span className={`table-certified ${table.certified ? 'success' : 'fail'}`}>
        {table.certified ? 'Certified' : 'Uncertified'}
      </span> */}
      {shouldShowDates && (
        <p className="status-date">Last modified: {dateToShortString(table.lastModified)}</p>
      )}
    </article>
  );
};

export default TableStatus;
