import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Image, Navbar } from 'react-bootstrap';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Button from 'src/components/ui/button';
import environment, { Environment } from 'src/config/env';
import useFeedback from 'src/resources/feedback/feedback-hook';
import useGlobalSettingsContext from 'src/resources/global-settings/global-settings-hook';
import useMarketsData from 'src/resources/markets-data/markets-data-hook';
import useAuth from '../../../resources/auth/auth-hook';
import FeedbackDialog from '../feedback-dialog';
import './index.scss';
import NavbarMetricsFilter, { Props as MetricsFilterProps } from './navbar-metrics-filter';
import UserDetailsComponent from './user-details';

const resolveEnvLabel = (): Environment | null => {
  if (environment.ENV === Environment.PROD) {
    return null;
  }

  return environment.ENV;
};

const NavBarComponent: React.FC = () => {
  const feedback = useFeedback();
  const { setMarketOptions } = useGlobalSettingsContext();
  const { marketsData, fetchMarketsData } = useMarketsData();
  const auth = useAuth();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const envLabel = useMemo(() => resolveEnvLabel(), []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      void fetchMarketsData();
    }
  }, [auth.isAuthenticated, fetchMarketsData]);

  const handleOpenFeedback = (): void => {
    setIsFeedbackOpen(true);
  };

  const handleCloseFeedback = useCallback((): void => {
    setIsFeedbackOpen(false);
  }, []);

  useEffect(() => {
    if (feedback.createStatus.success) {
      handleCloseFeedback();
    }
  }, [feedback.createStatus.error, feedback.createStatus.success, handleCloseFeedback]);

  const handleMarketsChange: MetricsFilterProps['onChange'] = useCallback(
    filter => {
      const { affiliate, market } = filter;
      setMarketOptions(affiliate, market);
    },
    [setMarketOptions]
  );

  return (
    <>
      <Navbar bg="primary" sticky="top">
        <Container fluid>
          <Navbar.Brand className="brand-container" href="/dashboard">
            <Image src="/assets/amway-logo.svg" width="98" height="35" />
            <Navbar.Text className="app-name">Data Catalog</Navbar.Text>
            {envLabel && <h2 className="env-band">{envLabel}</h2>}
          </Navbar.Brand>
          {auth.isAuthenticated && (
            <ExternalDataRenderer
              externalData={marketsData}
              makeCustomIsLoading={() => !marketsData.data}
              makeLoadingElement={() => <></>}
              makeDataElement={data => (
                <NavbarMetricsFilter marketsData={data} onChange={handleMarketsChange} />
              )}
            />
          )}
          <div className="user-container">
            {auth.isAuthenticated && (
              <Button onClick={handleOpenFeedback} variant="secondary" size="sm" className="me-5">
                FEEDBACK
              </Button>
            )}
            {auth.isAuthenticated && <UserDetailsComponent user={auth.user!} />}
          </div>
        </Container>
      </Navbar>
      <FeedbackDialog isOpen={isFeedbackOpen} onCancel={handleCloseFeedback} />
    </>
  );
};

export default NavBarComponent;
