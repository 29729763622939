import { Option } from 'src/components/ui/select';

export const dataTopicMetricsDatesOptions: Option[] = [
  { name: 'Last month', value: 'LastMonth' },
  { name: 'Last calendar month', value: 'LastCalendarMonth' },
  { name: 'Last year', value: 'LastYear', selected: true },
  { name: 'Perf YTD', value: 'PerfYTD' },
  { name: 'Cal YTD', value: 'CalYTD' }
];

export const encodeTableId = (tableId: string): string => {
  return encodeURI(tableId.replaceAll('/', ' '));
};

export const decodeTableId = (urlTableId: string): string => {
  return decodeURI(urlTableId.replaceAll(' ', '/'));
};

export const setBoldText = (text: string): React.ReactNode => {
  const boldText = text.split('<b>')[1].split('</b>')[0];
  const textBeforeBold = text.split('<b>')[0];
  const textAfterBold = text.split('</b>')[1];

  return (
    <>
      {textBeforeBold}
      <b>{boldText}</b>
      {textAfterBold}
    </>
  );
};
