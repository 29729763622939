export interface DataLineage {
  id: number | null;
  project: string;
  dataset: string;
  table: string;
  children?: DataLineage[] | null;
  parents?: DataLineage[] | null;
}

export const linkTypes = ['Looker', 'BigQuery', 'DataStudio', 'Confluence', 'Other'] as const;
type LinkTypeTuple = typeof linkTypes;
export type LinkType = LinkTypeTuple[number];

export interface TableLink {
  tableLinkId: number;
  type: LinkType;
  description: string;
  linkUrl: string;
}

export interface TableRules {
  tableName: string;
  projectId: string;
  datasetName: string;
  tableRuleId: number;
  tableLinks: TableLink[];
}

export interface GetTableRulesRequest {
  tableName: string;
  project: string;
  dataset: string;
}

export interface AddUsefulLinkRequest {
  tableName?: string;
  project?: string;
  dataset?: string;
  type: LinkType;
  description: string;
  linkUrl: string;
}
