import React, { useCallback, useState } from 'react';
import { ExternalData } from 'src/@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData
} from 'src/helpers/external-data';
import useExternalApiErrorHandler from 'src/hooks/use-external-api-error-handler';
import MarketsDataContext from 'src/resources/markets-data/markets-data-context';
import { MarketsData } from 'src/resources/markets-data/markets-data-model';
import marketsDataService from 'src/resources/markets-data/markets-data.service';

const MarketsDataProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [marketsData, setMarketsData] = useState<ExternalData<MarketsData>>(
    makeExternalDataInitialData()
  );

  const fetchMarketsData = useCallback(async (): Promise<void> => {
    try {
      setMarketsData(makeExternalDataInitialKeepData);
      const data = await marketsDataService.list();
      setMarketsData(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setMarketsData(makeExternalCallErrorData(err));
      errorHandler(err);
    }
  }, [errorHandler]);

  return (
    <MarketsDataContext.Provider
      value={{
        marketsData,
        fetchMarketsData
      }}>
      {children}
    </MarketsDataContext.Provider>
  );
};

export default MarketsDataProvider;
