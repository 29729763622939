import { Tab } from '@mui/material';
import './index.scss';
import { Link as LinkS } from 'react-scroll';

export interface SideMenuNavProps {
  sideMenuItems: Array<{ label: string; link: string }>;
  className?: string;
  style?: React.CSSProperties;
}

const SideMenuNav = (props: SideMenuNavProps): JSX.Element => {
  const { sideMenuItems, className = '', style } = props;

  return (
    <aside style={style} className={`side-menu-nav ${className}`}>
      {sideMenuItems.map(item => (
        <LinkS
          className="button-link"
          key={item.label}
          to={item.link}
          smooth={true}
          duration={500}
          spy={true}
          offset={-100}>
          <Tab label={item.label} />
        </LinkS>
      ))}
    </aside>
  );
};

export default SideMenuNav;
