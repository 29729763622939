import React, { useCallback, useState } from 'react';
import FEATURES from 'src/config/features';
import useFeatures from 'src/resources/features/features-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import { TableChangeRequest } from 'src/resources/table-change-requests/table-change-requests-model';
import ConfirmApprovalModal from '../../modals/confirm-approval-modal';
import RejectApprovalModal from '../../modals/reject-approval-modal';
import RequestedChangesList, { Props as RequestedChangesListProps } from './index';

export type Props = Omit<RequestedChangesListProps, 'handlers'>;

const ContextualizedRequestedChangesList: React.FC<Props> = props => {
  const { data, setPagination } = props;
  const { hasFeature } = useFeatures();
  const { createdReview, createReview } = useTableChangeRequests();
  const [selectedChangeRequest, setSelectedChangeRequest] = useState<
    TableChangeRequest | undefined
  >();
  const [modalsState, setModalsState] = useState({
    confirmApproval: false,
    rejectApproval: false
  });

  const handleCloseConfirmApprovalModal = useCallback((): void => {
    setModalsState(prev => ({ ...prev, confirmApproval: false }));
  }, []);

  const handleCloseRejectApprovalModal = useCallback((): void => {
    setModalsState(prev => ({ ...prev, rejectApproval: false }));
  }, []);

  if (hasFeature(FEATURES.RequestedChangesScreen.ReviewChangeRequests)) {
    const handleApproveChange = (changeId: string): void => {
      setSelectedChangeRequest(data.data!.changeRequests.find(change => change.id === changeId));
      setModalsState(prev => ({ ...prev, confirmApproval: true }));
    };

    const handleRejectChange = (changeId: string): void => {
      setSelectedChangeRequest(data.data!.changeRequests.find(change => change.id === changeId));
      setModalsState(prev => ({ ...prev, rejectApproval: true }));
    };

    const handleApproveSelectedChangeRequest = (reason: string): void => {
      void createReview({
        id: selectedChangeRequest!.id,
        approved: true,
        reasonText: reason
      });
    };

    const handleRejectSelectedChangeRequest = (reason: string): void => {
      void createReview({
        id: selectedChangeRequest!.id,
        approved: false,
        reasonText: reason
      });
    };

    return (
      <>
        <RequestedChangesList
          {...props}
          setPagination={setPagination}
          handlers={{
            handleApprove: handleApproveChange,
            handleReject: handleRejectChange
          }}
        />
        <ConfirmApprovalModal
          isOpen={modalsState.confirmApproval}
          isLoading={!!createdReview.loading}
          changeRequest={selectedChangeRequest}
          onApprove={handleApproveSelectedChangeRequest}
          onCancel={handleCloseConfirmApprovalModal}
        />
        <RejectApprovalModal
          isOpen={modalsState.rejectApproval}
          isLoading={!!createdReview.loading}
          changeRequest={selectedChangeRequest}
          onReject={handleRejectSelectedChangeRequest}
          onCancel={handleCloseRejectApprovalModal}
        />
      </>
    );
  }

  return <RequestedChangesList {...props} setPagination={setPagination} />;
};

export default ContextualizedRequestedChangesList;
