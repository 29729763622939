import React from 'react';
import Radio from '../../radio';
import Checkbox, { Props as CheckboxProps } from '../../checkbox';
import { Facet, FacetKind, FacetValue } from '../types';
import './index.scss';
import Title from '../../title';

interface Props extends Facet {
  onChange: (selectedFacetValues: FacetValue[]) => void;
}

const resolveUnionFacetValues = (
  facetValues: FacetValue[],
  FacetValue: string,
  FacetValueValue: boolean
): FacetValue[] => {
  return facetValues.map(facetValue => ({
    ...facetValue,
    selected: facetValue.value === FacetValue ? FacetValueValue : !!facetValue.selected
  }));
};

const resolveIntersectionFacetValues = (
  facetValues: FacetValue[],
  FacetValue: string
): FacetValue[] => {
  return facetValues.map(facetValue => ({
    ...facetValue,
    selected: facetValue.value === FacetValue
  }));
};

const FacetItem: React.FC<Props> = props => {
  const { name, values: facetValues, onChange, kind = FacetKind.Union } = props;

  const handleOnChange: CheckboxProps['onChange'] = e => {
    const { value, checked: isChecked } = e.target;

    onChange(
      kind === FacetKind.Union
        ? resolveUnionFacetValues(facetValues, value, isChecked)
        : resolveIntersectionFacetValues(facetValues, value)
    );
  };

  return (
    <article className="facet-item">
      <Title colorVariant="tertiary" sizeVariant="sm" className="facet-title pb-1 mb-3">
        {name}
      </Title>
      <div>
        {kind === FacetKind.Union
          ? facetValues.map(({ name: facetValueName, value, selected }) => (
              <Checkbox
                key={value}
                title={facetValueName}
                name={facetValueName}
                value={value}
                isChecked={selected}
                onChange={handleOnChange}
              />
            ))
          : facetValues.map(({ name: facetValueName, value, selected }) => (
              <Radio
                key={value}
                title={facetValueName}
                value={value}
                name={name}
                isChecked={selected}
                onChange={handleOnChange}
              />
            ))}
      </div>
    </article>
  );
};

export default FacetItem;
