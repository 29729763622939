import { useCallback, useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TopMenuTabs from 'src/components/core/top-menu-tabs';
import Filter, { Props as FilterProps } from 'src/components/ui/filter';
import { Facet, FacetKind } from 'src/components/ui/filter/types';
import { PaginationData } from 'src/components/ui/pagination';
import PseudoLink from 'src/components/ui/pseudo-link';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import useFeedback from 'src/resources/feedback/feedback-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import useTable from 'src/resources/table/table-hook';
import { decodeTableId } from 'src/screens/authenticated/utils';
import { tableNameFormatter } from '../data-set-details/utils';
import {
  changeRequestDatasetLabel,
  getChangeRequestsStatusFacet,
  initialChangeRequestsFilter,
  makeFetchTableChangeRequestParams,
  makeFetchTableChangeRequestWithoutStatusParams
} from './change-requests-utils';
import ChangesRequestsListRenderer from './changes-requests-list-renderer';
import {
  getFeedbacksStatusFacet,
  initialFeedbacksFilter,
  makeFetchFeedbacksParams,
  makeFetchFeedbacksWithoutStatusParams
} from './feedback-utils';
import FeedbacksListRenderer from './feedbacks-list-renderer';
import './index.scss';
import TealiumDataLayer from 'src/components/hocs/tealium-data-layer';

const changeRequestsLabel = 'Change Requests';
const feedbacksLabel = 'Feedbacks';

const initialPagePagination: PaginationData = { limit: 5, page: 0 };

const RequestedChanges = (): JSX.Element => {
  const { id } = useParams();
  const { selectedTable, findTable } = useTable();
  const {
    tableChangeRequests,
    pendingTableChangeRequests,
    reviewedTableChangeRequests,
    createdReview,
    datasetsWithRequests,
    fetchTableChangeRequests,
    fetchDatasetsWithRequests,
    fetchPendingTableChangeRequests,
    fetchReviewedTableChangeRequests
  } = useTableChangeRequests();
  const {
    createStatus,
    updateStatusStatus,
    feedbacks,
    pinnedFeedbacks,
    unseenFeedbacks,
    seenFeedbacks,
    fetchFeedbacks,
    fetchPinnedFeedbacks,
    fetchUnseenFeedbacks,
    fetchSeenFeedbacks
  } = useFeedback();
  const [activeTab, setActiveTab] = useState(changeRequestsLabel); // *** this is an one-way data binding
  const [changeRequestsFilter, setChangeRequestsFilter] = useState<Facet[]>(
    initialChangeRequestsFilter
  );
  const [feedbacksFilter, setFeedbacksFilter] = useState<Facet[]>(initialFeedbacksFilter);
  const [appliedFilter, setAppliedFilter] = useState<Facet[]>([]);

  useEffect(() => {
    void fetchDatasetsWithRequests();
  }, [fetchDatasetsWithRequests]);

  useEffect(() => {
    if (activeTab === feedbacksLabel) {
      setAppliedFilter(feedbacksFilter);
    } else {
      setAppliedFilter(changeRequestsFilter);
    }
  }, [activeTab, changeRequestsFilter, feedbacks, feedbacksFilter]);

  useEffect(() => {
    if (datasetsWithRequests.data && ((id && selectedTable.data) ?? !id)) {
      const datasetsFacet: Facet = {
        name: changeRequestDatasetLabel,
        kind: FacetKind.Intersection,
        values: datasetsWithRequests.data.map(dataset => ({
          name: tableNameFormatter(dataset),
          value: dataset,
          selected: selectedTable?.data?.tableName === dataset
        }))
      };

      setChangeRequestsFilter(prev => {
        if (prev.find(filter => filter.name === changeRequestDatasetLabel)) {
          return prev;
        }

        return [...prev, datasetsFacet];
      });
    }
  }, [datasetsWithRequests, id, selectedTable.data]);

  useEffect(() => {
    if (id) {
      const decodedId = decodeTableId(id!);
      if (selectedTable.data?.id !== decodedId) {
        void findTable(decodedId);
      }
    }
  }, [id, findTable, selectedTable.data?.id]);

  const handleFetchTableChangeRequests = useCallback(
    (pagination: PaginationData) => {
      void fetchTableChangeRequests(
        makeFetchTableChangeRequestParams(changeRequestsFilter, pagination)
      );
    },
    [changeRequestsFilter, fetchTableChangeRequests]
  );

  const handleFetchPendingTableChangeRequests = useCallback(
    (pagination: PaginationData) => {
      void fetchPendingTableChangeRequests(
        makeFetchTableChangeRequestWithoutStatusParams(changeRequestsFilter, pagination)
      );
    },
    [changeRequestsFilter, fetchPendingTableChangeRequests]
  );

  const handleFetchReviewedTableChangeRequests = useCallback(
    (pagination: PaginationData) => {
      void fetchReviewedTableChangeRequests(
        makeFetchTableChangeRequestWithoutStatusParams(changeRequestsFilter, pagination)
      );
    },
    [changeRequestsFilter, fetchReviewedTableChangeRequests]
  );

  const handleFetchFeedbacks = useCallback(
    (pagination: PaginationData) => {
      void fetchFeedbacks(makeFetchFeedbacksParams(feedbacksFilter, pagination));
    },
    [fetchFeedbacks, feedbacksFilter]
  );

  const handleFetchUnseenFeedbacks = useCallback(
    (pagination: PaginationData) => {
      void fetchUnseenFeedbacks(makeFetchFeedbacksWithoutStatusParams(feedbacksFilter, pagination));
    },
    [fetchUnseenFeedbacks, feedbacksFilter]
  );

  const handleFetchPinnedFeedbacks = useCallback(
    (pagination: PaginationData) => {
      void fetchPinnedFeedbacks(makeFetchFeedbacksWithoutStatusParams(feedbacksFilter, pagination));
    },
    [fetchPinnedFeedbacks, feedbacksFilter]
  );

  const handleFetchSeenFeedbacks = useCallback(
    (pagination: PaginationData) => {
      void fetchSeenFeedbacks(makeFetchFeedbacksWithoutStatusParams(feedbacksFilter, pagination));
    },
    [fetchSeenFeedbacks, feedbacksFilter]
  );

  useEffect(() => {
    handleFetchFeedbacks(initialPagePagination);
    handleFetchUnseenFeedbacks(initialPagePagination);
    handleFetchPinnedFeedbacks(initialPagePagination);
    handleFetchSeenFeedbacks(initialPagePagination);
  }, [
    handleFetchFeedbacks,
    handleFetchPinnedFeedbacks,
    handleFetchSeenFeedbacks,
    handleFetchUnseenFeedbacks
  ]);

  useEffect(() => {
    if (updateStatusStatus.success) {
      handleFetchFeedbacks(initialPagePagination);
      handleFetchUnseenFeedbacks(initialPagePagination);
      handleFetchPinnedFeedbacks(initialPagePagination);
      handleFetchSeenFeedbacks(initialPagePagination);
    }
  }, [
    handleFetchFeedbacks,
    handleFetchPinnedFeedbacks,
    handleFetchSeenFeedbacks,
    handleFetchUnseenFeedbacks,
    updateStatusStatus.success
  ]);

  useEffect(() => {
    if (createStatus.success) {
      handleFetchFeedbacks(initialPagePagination);
      handleFetchUnseenFeedbacks(initialPagePagination);
    }
  }, [handleFetchFeedbacks, handleFetchUnseenFeedbacks, createStatus.success]);

  useEffect(() => {
    handleFetchPendingTableChangeRequests(initialPagePagination);
    handleFetchReviewedTableChangeRequests(initialPagePagination);
    handleFetchTableChangeRequests(initialPagePagination);
  }, [
    handleFetchPendingTableChangeRequests,
    handleFetchReviewedTableChangeRequests,
    handleFetchTableChangeRequests
  ]);

  useEffect(() => {
    if (createdReview.success) {
      handleFetchPendingTableChangeRequests(initialPagePagination);
      handleFetchReviewedTableChangeRequests(initialPagePagination);
      handleFetchTableChangeRequests(initialPagePagination);
    }
  }, [
    createdReview.success,
    handleFetchPendingTableChangeRequests,
    handleFetchReviewedTableChangeRequests,
    handleFetchTableChangeRequests
  ]);

  const onActiveTabChange = (activeTab: string): void => {
    setActiveTab(activeTab);
  };

  const handleFilterChanges: FilterProps['onFacetsChange'] = filter => {
    if (activeTab === feedbacksLabel) {
      setFeedbacksFilter(filter);
    } else {
      setChangeRequestsFilter(filter);
    }
  };

  const handleOrderByChanges: FilterProps['onOrderByChange'] = orderBy => {
    console.log(orderBy);
  };

  const handleClearAll = (): void => {
    const setFilter = activeTab === feedbacksLabel ? setFeedbacksFilter : setChangeRequestsFilter;

    setFilter(prev =>
      prev.map(facet => ({
        ...facet,
        values: facet.values.map(value => ({ ...value, selected: false }))
      }))
    );
  };

  return (
    <TealiumDataLayer>
      <Container className="change-requests-container">
        <Card>
          <Card.Body>
            <header>
              <Title>
                <Typography colorVariant="primary" sizeVariant="xl">
                  Requests List
                </Typography>
              </Title>
              <Typography className="mt-3" weight="bold">
                View below all requested changes that haven&apos;t yet been implemented
              </Typography>
            </header>
            <article className="search-data-container mt-4">
              <div className="requested-changes-filter-container">
                <Filter
                  className="requested-changes-filter fancy-scrollbar"
                  facets={appliedFilter}
                  onFacetsChange={handleFilterChanges}
                  onOrderByChange={handleOrderByChanges}
                  orderBy={[
                    {
                      name: 'Most Recent',
                      value: 'recent',
                      selected: true
                    }
                  ]}
                />
                <PseudoLink className="clear-filters" onClick={handleClearAll}>
                  Clear all
                </PseudoLink>
              </div>
              <TopMenuTabs
                onTabChange={onActiveTabChange}
                topMenuItems={[
                  {
                    label: changeRequestsLabel,
                    component: (
                      <ChangesRequestsListRenderer
                        facetValues={getChangeRequestsStatusFacet(changeRequestsFilter).values}
                        tableChangeRequests={{
                          data: tableChangeRequests,
                          onPaginationChange: handleFetchTableChangeRequests
                        }}
                        pendingTableChangeRequests={{
                          data: pendingTableChangeRequests,
                          onPaginationChange: handleFetchPendingTableChangeRequests
                        }}
                        reviewedTableChangeRequests={{
                          data: reviewedTableChangeRequests,
                          onPaginationChange: handleFetchReviewedTableChangeRequests
                        }}
                      />
                    )
                  },
                  {
                    label: feedbacksLabel,
                    component: (
                      <FeedbacksListRenderer
                        facetValues={getFeedbacksStatusFacet(feedbacksFilter).values}
                        feedbacks={{
                          data: feedbacks,
                          onPaginationChange: handleFetchFeedbacks
                        }}
                        unseenFeedbacks={{
                          data: unseenFeedbacks,
                          onPaginationChange: handleFetchUnseenFeedbacks
                        }}
                        pinnedFeedbacks={{
                          data: pinnedFeedbacks,
                          onPaginationChange: handleFetchPinnedFeedbacks
                        }}
                        seenFeedbacks={{
                          data: seenFeedbacks,
                          onPaginationChange: handleFetchSeenFeedbacks
                        }}
                      />
                    )
                  }
                ]}
              />
            </article>
          </Card.Body>
        </Card>
      </Container>
    </TealiumDataLayer>
  );
};

export default RequestedChanges;
