import React, { RefObject } from 'react';
import { TableSample } from 'src/resources/table/models/table-sample-model';
import Table from '../table';

interface Props {
  tableSample: TableSample;
  parentId?: string;
  parentRef?: RefObject<HTMLDivElement>;
}

const DataSectionTable: React.FC<Props> = (props: Props) => {
  const { header, formattedValues, dataTypes } = props.tableSample;
  return (
    <Table
      description=""
      headers={header}
      dataTypes={dataTypes}
      hasInfiniteScroll
      rows={formattedValues.map(items => ({ data: items }))}
      isSortable
      hasSearchBar
      parentId={props.parentId}
      parentRef={props.parentRef}
    />
  );
};

export default DataSectionTable;
