import Card from 'src/components/ui/card';
import Subtitle from 'src/components/ui/subtitle';
import TableStatus from 'src/components/ui/table-status';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import { reduceNumber } from 'src/utils/number-formatter-utils';
import { prettyFormatList } from 'src/utils/pretty-formatters-utils';
import { Table } from '../../../../../resources/table/models/table-model';
import './index.scss';

interface Props {
  dataItem: Table;
  onNavigationClick: VoidFunction;
}

const convertTableName = (uglyName: string): string => {
  return uglyName.split('_').reduce((acc, entry) => `${acc} ${entry}`);
};

const GeneralInfo = ({ dataItem, onNavigationClick }: Props): JSX.Element => {
  return (
    <div className="general-info-container">
      <Title
        className="mb-3"
        sizeVariant="sm-md"
        colorVariant="quaternary"
        onClick={onNavigationClick}>
        {convertTableName(dataItem.tableName)}
      </Title>
      <Subtitle sizeVariant="sm" colorVariant="tertiary">
        {dataItem.tableName}
      </Subtitle>
      <TableStatus table={dataItem} shouldShowDates className="my-1" />
      <Typography className="mt-2">{dataItem.description}</Typography>
    </div>
  );
};

const filterRelevantLabels = (labels: Table['labels']): Table['labels'] => {
  const relevantLabels = new Set(['data_topic', 'user_defined_category']);

  return labels.filter(label => relevantLabels.has(label.name));
};

const MetadataInfo = ({ dataItem }: Props): JSX.Element => {
  return (
    <div className="metadata-container">
      <div className="metadata-inside-container">
        <Typography>Tags:</Typography>
        {filterRelevantLabels(dataItem.labels).map(label => (
          <div key={label.id} className="tag-section">
            <Typography colorVariant="primary">{label.name}:</Typography>
            <Typography colorVariant="primary">{label.value}</Typography>
          </div>
        ))}
      </div>
      <div className="metadata-inside-container">
        <Typography>Table:</Typography>
        <Typography colorVariant="primary">{dataItem.columns.length} columns</Typography>
        <Typography colorVariant="primary">
          {reduceNumber(dataItem.numRows, 1, false)} records
        </Typography>
      </div>
    </div>
  );
};

export default function DataListItem(props: Props): JSX.Element {
  return (
    <Card className="table-item-container">
      <GeneralInfo {...props} />
      <MetadataInfo {...props} />
    </Card>
  );
}
