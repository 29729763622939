import {
  Chart,
  ChartComponent,
  ColumnChart,
  Component,
  DataDisplay,
  DataDisplaySection,
  DataTopicComponents,
  DataTopicMetricsDetails,
  DividerComponent,
  HeaderComponent,
  LineChart,
  PageComponent,
  PieChart,
  StackedColumnChart
} from './models';

const makeChart = (it: any): Chart => {
  switch (it.name) {
    case DataTopicComponents.ColumnChart: {
      return new ColumnChart(it.labels, it.orientation, it.parameters, it.legend, it.scale);
    }
    case DataTopicComponents.LineChart: {
      return new LineChart(it.labels, it.parameters, it.legend, it.scale);
    }
    case DataTopicComponents.StackedColumnChart: {
      return new StackedColumnChart(it.labels, it.parameters, it.legend, it.scale);
    }
    case DataTopicComponents.PieChart: {
      return new PieChart(it.labels, it.parameters, it.legend);
    }
  }

  return null as unknown as Chart;
};

const makeComponent = (it: any): Component => {
  switch (it.name) {
    case DataTopicComponents.Header: {
      return new HeaderComponent(it.title, it.description);
    }
    case DataTopicComponents.Divider: {
      return new DividerComponent();
    }
    case DataTopicComponents.ChartSection: {
      return new ChartComponent(
        it.title,
        it.description,
        it.sql,
        it.orientation,
        makeChart(it.chart)
      );
    }
    case DataTopicComponents.DataDisplay: {
      return new DataDisplay(
        it.title,
        it.currentDataText,
        it.previousDataText,
        it.currentDataSQL,
        it.previousDataSQL,
        parseFloat(it.currentData),
        parseFloat(it.previousData),
        parseFloat(it.percentRatio)
      );
    }
    case DataTopicComponents.DataDisplaySection: {
      return new DataDisplaySection(
        it.title,
        it.description,
        it.orientation,
        it.items.map(
          (that: any) =>
            new DataDisplay(
              that.title,
              that.currentDataText,
              that.previousDataText,
              that.currentDataSQL,
              that.previousDataSQL,
              parseFloat(that.currentData),
              parseFloat(that.previousData),
              parseFloat(that.percentRatio)
            )
        )
      );
    }
    default:
      return makeChart(it);
  }
};

const makePage = (it: any): PageComponent => {
  return new PageComponent(
    it.title,
    it.components.map((that: any) => makeComponent(that))
  );
};

export const dataTopicMetricsDetailsMapper = (apiData: any): DataTopicMetricsDetails => {
  return {
    pages: apiData.pages.map((it: any) => makePage(it))
  };
};
