// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Chart {}

export enum DataTopicComponents {
  Header = 'Header',
  DataDisplay = 'DataDisplay',
  DataDisplaySection = 'DataDisplaySection',
  Divider = 'Divider',
  ChartSection = 'ChartSection',
  ColumnChart = 'ColumnChart',
  StackedColumnChart = 'StackedColumnChart',
  PieChart = 'PieChart',
  LineChart = 'LineChart'
}

export enum Orientation {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal'
}

export enum Scale {
  Linear = 'Linear',
  Logarithmic = 'Logarithmic'
}

export class ColumnChart implements Chart {
  constructor(
    readonly labels: string[],
    readonly orientation: Orientation,
    readonly parameters: Array<{ label: string; data: number[] }>,
    readonly legend: boolean,
    readonly scale: Scale
  ) {}
}

export class StackedColumnChart implements Chart {
  constructor(
    readonly labels: string[],
    readonly parameters: Array<{ label: string; data: number[] }>,
    readonly legend: boolean,
    readonly scale: Scale
  ) {}
}

export class LineChart implements Chart {
  constructor(
    readonly labels: string[],
    readonly parameters: Array<{ label: string; data: number[] }>,
    readonly legend: boolean,
    readonly scale: Scale
  ) {}
}

export class PieChart implements Chart {
  constructor(
    readonly labels: string[],
    readonly parameters: Array<{ label: string; data: number }>,
    readonly legend: boolean
  ) {}
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Component {}

export class PageComponent implements Component {
  constructor(readonly title: string, readonly components: Component[]) {}
}

export class HeaderComponent implements Component {
  constructor(readonly title: string, readonly description: string) {}
}

export class DataDisplay implements Component {
  constructor(
    readonly title: string,
    readonly currentDataText: string,
    readonly previousDataText: string,
    readonly currentDataSQL: string,
    readonly previousDataSQL: string,
    readonly currentData: number,
    readonly previousData: number,
    readonly percentRatio: number
  ) {}
}

export class DataDisplaySection implements Component {
  constructor(
    readonly title: string,
    readonly description: string,
    readonly orientation: Orientation,
    readonly items: DataDisplay[]
  ) {}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DividerComponent implements Component {}

export class ChartComponent implements Component {
  constructor(
    readonly title: string,
    readonly description: string,
    readonly sql: string,
    readonly orientation: Orientation,
    readonly chart: Chart
  ) {}
}

export interface DataTopicMetricsDetails {
  pages: PageComponent[];
}
