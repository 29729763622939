import { AdmDemographicData, DataTopic, DataTopicMetrics } from './data-topic-model';
import { apiWrapper } from 'src/config/api-wrapper';
import { WithAbortFn } from 'src/@types/external-api';
import axios from 'axios';
import {
  dataTopicMetricsMapper,
  admDemographicMapper,
  dataTopicsMapper
} from './data-topic-mapper';
import {
  MetricsFilters,
  ExternalMetricsFilters,
  AdmDemographicFilters,
  ExternalAdmDemographicFilters,
  DetailedMetricsFilter
} from './data-topic-types';
import { dataTopicMetricsDetailsMapper } from './metrics-details/mappers';
import { DataTopicMetricsDetails } from './metrics-details/models';

const toExternalMetricsFilters = (filters: MetricsFilters): ExternalMetricsFilters => {
  return {
    country_cd: filters.countriesCode.length === 0 ? undefined : filters.countriesCode.join(','),
    data_topic_name: filters.dataTopicValue,
    date_period: filters.datePeriod
  };
};

const toExternalAdmDemographicFilters = (
  filters: AdmDemographicFilters
): ExternalAdmDemographicFilters => {
  return {
    country_cd: filters.countryCodes?.length === 0 ? undefined : filters.countryCodes?.join(',')
  };
};

class DataTopicService {
  public list(): Promise<DataTopic[]> {
    return apiWrapper.api
      .get('/data-topic')
      .then(res => res.data)
      .then(dataTopicsMapper);
  }

  public metrics(filters: MetricsFilters): WithAbortFn<Promise<DataTopicMetrics[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .get('/data-topic/metrics', {
          params: toExternalMetricsFilters(filters),
          cancelToken: source.token
        })
        .then(res => res.data)
        .then(data => dataTopicMetricsMapper(data)),
      abort: source.cancel
    };
  }

  public admDemographic(filters: AdmDemographicFilters): Promise<AdmDemographicData> {
    return apiWrapper.api
      .get(`/data-topic/metrics/${filters.dataTopicValue}/admDemographic`, {
        params: toExternalAdmDemographicFilters(filters)
      })
      .then(res => res.data)
      .then(data => admDemographicMapper(data));
  }

  public metricDetails(filters: DetailedMetricsFilter): Promise<DataTopicMetricsDetails> {
    return apiWrapper.api
      .get(`/data-topic/metrics/${filters.dataTopicValue}/details`, {
        params: toExternalMetricsFilters(filters)
      })
      .then(res => res.data)
      .then(data => dataTopicMetricsDetailsMapper(data));
  }
}

export default new DataTopicService();
