import { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import VerticalBarChart from 'src/components/charts/vertical-bar-chart';
import ExternalDataRenderer from 'src/components/hocs/external-data-renderer';
import Breadcrumbs from 'src/components/ui/breadcrumbs';
import Title from 'src/components/ui/title';
import Typography from 'src/components/ui/typography';
import useBreadcrumbs from 'src/resources/breadcrumbs/breadcrumbs-hook';
import useTable from 'src/resources/table/table-hook';
import { decodeTableId } from 'src/screens/authenticated/utils';
import DataDistributionHeader from './data-distributions-header';
import './index.scss';

const DataDistributionDetailsPage = (): JSX.Element => {
  const { selectedTable, dataDistribution, findTable, fetchDataDistribution } = useTable();
  const { breadcrumbs } = useBreadcrumbs();
  const { id, columnName } = useParams();

  useEffect(() => {
    const decodedId = decodeTableId(id!);
    if (selectedTable.data?.id !== decodedId) {
      void findTable(decodedId);
    }
  }, [id, findTable, selectedTable.data?.id]);

  useEffect(() => {
    if (selectedTable.data && columnName) {
      void fetchDataDistribution(
        selectedTable.data.dataset,
        selectedTable.data.tableName,
        columnName
      );
    }
  }, [fetchDataDistribution, selectedTable.data, columnName]);

  return (
    <Container>
      <Card>
        <Card.Body className="data-distribution-container">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Title>
            Data Distribution: <Typography sizeVariant="xl">{columnName}</Typography>
          </Title>
          <ExternalDataRenderer
            externalData={dataDistribution}
            makeDataElement={dataDistributionData => (
              <section className="chart my-4">
                <ExternalDataRenderer
                  externalData={selectedTable}
                  makeDataElement={data => (
                    <DataDistributionHeader
                      table={data}
                      dataDistribution={dataDistributionData}
                      columnName={columnName!}
                    />
                  )}
                />
                <article className="mt-2 mb-4">
                  <VerticalBarChart
                    data={dataDistributionData.distribution}
                    style={{
                      width: '100%',
                      height: '480px'
                    }}
                  />
                </article>
                <Typography>{dataDistributionData.description}</Typography>
              </section>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DataDistributionDetailsPage;
