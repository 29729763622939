import React, { useMemo } from 'react';
import { Component as ComponentModel } from 'src/resources/data-topic/metrics-details/models';
import { makeImplementedComponent } from '../../components-factory';

interface Props {
  component: ComponentModel;
}

const Component: React.FC<Props> = props => {
  const { component } = props;

  return useMemo(() => makeImplementedComponent(component), [component]);
};

export default Component;
