import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../../resources/auth/auth-hook';

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    // Saves the location the user was trying to go to before being redirected to login page.
    // It allows to send the user to that page after login, which is better for user experience.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
