import React from 'react';
import { HeaderComponent as HeaderComponentModel } from 'src/resources/data-topic/metrics-details/models';
import './index.scss';

interface Props {
  component: HeaderComponentModel;
}

const HeaderComponent: React.FC<Props> = props => {
  const { component } = props;

  return (
    <header className="header-component">
      <h1>{component.title}</h1>
      <h6>{component.description}</h6>
    </header>
  );
};

export default HeaderComponent;
