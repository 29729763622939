import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getAffiliatesOptions,
  getMarketsOptions,
  buildInitialFilters,
  Filter
} from 'src/components/ui/metrics-filter/utils';
import MultiSelectDropdown, {
  Props as MultiSelectProps
} from 'src/components/ui/multi-select-dropdown';
import Select, { Props as SelectProps } from 'src/components/ui/select';
import useGlobalSettingsContext from 'src/resources/global-settings/global-settings-hook';
import { MarketsData } from 'src/resources/markets-data/markets-data-model';
import { getFirstOptionValue } from 'src/utils/select-option-utils';
import './index.scss';

export interface Props {
  marketsData: MarketsData;
  onChange: (filter: Filter) => void;
  className?: string;
}

const NavbarMetricsFilter: React.FC<Props> = props => {
  const { marketsData, onChange, className = '' } = props;
  const { settings } = useGlobalSettingsContext();
  const marketsAffiliatesOptions = useMemo(
    () => (marketsData ? getAffiliatesOptions(marketsData) : []),
    [marketsData]
  );
  const [marketsOptions, setMarketOptions] = useState(
    marketsAffiliatesOptions.length
      ? getMarketsOptions(
          marketsData!,
          settings.defaultOptions.marketAffiliateCode ??
            getFirstOptionValue(marketsAffiliatesOptions)!
        )
      : []
  );
  const [filters, setFilters] = useState<Filter>(
    buildInitialFilters(settings.defaultOptions, marketsAffiliatesOptions, marketsOptions)
  );

  useEffect(() => {
    onChange({ ...filters });
  }, [filters, marketsData, onChange]);

  const handleSelectChanges: SelectProps['onChange'] = useCallback(
    e => {
      const { id: name, value } = e.target;

      if (name === 'affiliate') {
        const marketOptions = getMarketsOptions(marketsData, value);
        setMarketOptions(marketOptions);
        setFilters(prev => ({
          ...prev,
          market: marketOptions.map(option => option.value)
        }));
      }

      setFilters(prev => ({ ...prev, [name]: value }));
    },
    [marketsData]
  );

  const handleOnMarketChanges: MultiSelectProps['onChange'] = useCallback(market => {
    market = market ?? [];

    setFilters(prev => ({
      ...prev,
      market: market as string[]
    }));
  }, []);

  return (
    <article className={`navbar-metrics-filter ${className}`}>
      <Select
        id="affiliate"
        options={marketsAffiliatesOptions}
        value={filters.affiliate}
        onChange={handleSelectChanges}
      />
      <MultiSelectDropdown
        id="market"
        items={marketsOptions}
        value={filters.market}
        onChange={handleOnMarketChanges}
        eager={false}
        required
        all
        allLabel="All Markets"
      />
    </article>
  );
};

export default NavbarMetricsFilter;
