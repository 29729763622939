import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import GuestLayout from 'src/components/core/guest-layout';
import LoginScreen from 'src/screens/login';

const GuestRouter: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<GuestLayout />}>
        <Route path="" element={<Navigate to="/login" state={{ from: location }} replace />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="login/callback" element={<LoginCallback />} />
        <Route path="*" element={<Navigate to="/login" state={{ from: location }} replace />} />
      </Route>
    </Routes>
  );
};

export default GuestRouter;
