import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  value: string;
  isReadOnly: boolean;
  onDelete: (chipValue: string) => void;
}

const Chip: React.FC<Props> = ({ children, value, isReadOnly, onDelete }) => {
  return (
    <span key={value} className={`item ${isReadOnly ? 'read-only' : ''}`}>
      {children}
      {!isReadOnly && (
        <span onClick={() => onDelete(value)} className="delete-icon-wrapper">
          <Icon path={mdiClose} size="1rem" />
        </span>
      )}
    </span>
  );
};

export default Chip;
