import papa from 'papaparse';

export const toCSV = (data: string[][]): string => {
  return papa.unparse(data, {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false
  });
};

export const downloadCSV = (title: string, content: string): void => {
  const anchor = document.createElement('a');
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  anchor.href = url;
  anchor.setAttribute('download', title);
  anchor.click();
};
