import { diff_match_patch as DiffMatchPatch } from 'diff-match-patch';
import 'diff-match-patch-line-and-word';
import { TextDiff } from 'src/@types/text-diff';

// details: https://github.com/google/diff-match-patch/wiki/Line-or-Word-Diffs
// https://github.com/google/diff-match-patch/wiki/Line-or-Word-Diffs
export const diffText = (text1: string, text2: string): TextDiff[] => {
  const dmp = new DiffMatchPatch();
  return dmp.diff_wordMode(text1, text2);
};
