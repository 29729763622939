export enum FeedbackEventFlow {
  Dashboard = 'Dashboard',
  Search = 'Search',
  DataTopic = 'DataTopic',
  DataSetDetails = 'DataSetDetails',
  DataDistribution = 'DataDistribution',
  RequestedChanges = 'RequestedChanges',
  UploadLookerStudioLogs = 'UploadLookerStudioLogs'
}

export enum FeedbackSubject {
  DataInconsistency = 'DataInconsistency',
  LayoutIssues = 'LayoutIssues',
  UserExperience = 'UserExperience',
  Others = 'Others'
}

export enum FeedbackStatus {
  Unseen = 'Unseen',
  Pinned = 'Pinned',
  Seen = 'Seen'
}

export interface Feedback {
  id: string;
  eventFlow: FeedbackEventFlow;
  status: FeedbackStatus;
  subject: FeedbackSubject;
  body: string;
  sentBy: string;
  createdAt: Date;
}

export interface UnseenFeedback extends Feedback {
  status: FeedbackStatus.Unseen;
}

export interface PinnedFeedback extends Feedback {
  status: FeedbackStatus.Pinned;
}

export interface SeenFeedback extends Feedback {
  status: FeedbackStatus.Seen;
}

export interface FeedbacksPagination {
  offset: number;
  limit: number;
  total: number;
}
