import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from 'src/@types/external-api';
import useExternalApiErrorHandler from 'src/hooks/use-external-api-error-handler';
import useToasts from 'src/resources/toasts/toasts-hook';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalCallSuccessData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData
} from 'src/helpers/external-data';
import FeedbackContext, { FeedbackCtxType } from './feedback-context';
import {
  FetchFeedbacksRequestParams,
  FetchFeedbacksRequestWithoutStatusParams,
  ListResult
} from './feedback-types';
import feedbackService from './feedback.service';
import {
  Feedback,
  FeedbackStatus,
  PinnedFeedback,
  SeenFeedback,
  UnseenFeedback
} from './feedback-model';

const FeedbackProvider: React.FC = ({ children }) => {
  const { push } = useToasts();
  const errorHandler = useExternalApiErrorHandler();
  const [feedbacks, setFeedbacks] = useState<ExternalData<ListResult<Feedback>>>(
    makeExternalDataInitialData()
  );
  const [unseenFeedbacks, setUnseenFeedbacks] = useState<ExternalData<ListResult<UnseenFeedback>>>(
    makeExternalDataInitialData()
  );
  const [pinnedFeedbacks, setPinnedFeedbacks] = useState<ExternalData<ListResult<PinnedFeedback>>>(
    makeExternalDataInitialData()
  );
  const [seenFeedbacks, setSeenFeedbacks] = useState<ExternalData<ListResult<SeenFeedback>>>(
    makeExternalDataInitialData()
  );
  const [createStatus, setCreateStatus] = useState<ExternalAction>({});
  const [updateStatusStatus, setUpdateStatusStatus] = useState<ExternalAction>({});

  // This cleanup logic resets the external action to its initial state
  // Later, we should find a way to improve this
  useEffect(() => {
    if (updateStatusStatus.success) {
      setUpdateStatusStatus({});
    }
  }, [updateStatusStatus.success]);

  const create: FeedbackCtxType['create'] = async feedback => {
    try {
      setCreateStatus(makeExternalDataInitialData);
      await feedbackService.create(feedback);
      push('Feedback created successfully');
      setCreateStatus(makeExternalCallSuccessData);
    } catch (err: any) {
      setCreateStatus(makeExternalCallErrorData(err));
      errorHandler(err);
    }
  };

  const fetchFeedbacks = useCallback(
    async (params: FetchFeedbacksRequestParams): Promise<void> => {
      try {
        setFeedbacks(makeExternalDataInitialKeepData);
        const data = await feedbackService.list<Feedback>(params);
        setFeedbacks(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setFeedbacks(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchUnseenFeedbacks = useCallback(
    async (params: FetchFeedbacksRequestWithoutStatusParams): Promise<void> => {
      try {
        setUnseenFeedbacks(makeExternalDataInitialKeepData);
        const data = await feedbackService.list<UnseenFeedback>({
          ...params,
          status: FeedbackStatus.Unseen
        });
        setUnseenFeedbacks(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setUnseenFeedbacks(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchPinnedFeedbacks = useCallback(
    async (params: FetchFeedbacksRequestWithoutStatusParams): Promise<void> => {
      try {
        setPinnedFeedbacks(makeExternalDataInitialKeepData);
        const data = await feedbackService.list<PinnedFeedback>({
          ...params,
          status: FeedbackStatus.Pinned
        });
        setPinnedFeedbacks(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setPinnedFeedbacks(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const fetchSeenFeedbacks = useCallback(
    async (params: FetchFeedbacksRequestWithoutStatusParams): Promise<void> => {
      try {
        setSeenFeedbacks(makeExternalDataInitialKeepData);
        const data = await feedbackService.list<SeenFeedback>({
          ...params,
          status: FeedbackStatus.Seen
        });
        setSeenFeedbacks(makeExternalDataSuccessData(data));
      } catch (err: any) {
        setSeenFeedbacks(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler]
  );

  const updateStatus: FeedbackCtxType['updateStatus'] = useCallback(
    async (feedbackId, status) => {
      try {
        setUpdateStatusStatus(makeExternalDataInitialData);
        await feedbackService.updateStatus(feedbackId, status);
        push('Feedback status update successfully');
        setUpdateStatusStatus(makeExternalCallSuccessData);
      } catch (err: any) {
        setUpdateStatusStatus(makeExternalCallErrorData(err));
        errorHandler(err);
      }
    },
    [errorHandler, push]
  );

  return (
    <FeedbackContext.Provider
      value={{
        feedbacks,
        seenFeedbacks,
        pinnedFeedbacks,
        unseenFeedbacks,
        createStatus,
        updateStatusStatus,
        create,
        updateStatus,
        fetchFeedbacks,
        fetchUnseenFeedbacks,
        fetchPinnedFeedbacks,
        fetchSeenFeedbacks
      }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
