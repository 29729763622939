import { DataTopicMetrics, AdmDemographicData, DataTopic } from './data-topic-model';

export const dataTopicsMapper = (apiData: any[]): DataTopic[] => {
  return apiData
    .map((data, i) => ({
      name: data.name,
      value: data.facet,
      description: data.description,
      disabled: data.disabled
    }))
    .sort(a => (a.disabled ? 1 : -1));
};

export const dataTopicMetricsMapper = (apiData: any[]): DataTopicMetrics[] => {
  return apiData.map(data => ({
    title: data.title,
    currentData: parseFloat(data.metric1),
    previousData: parseFloat(data.metric2),
    percentRatio: parseFloat(data.percentRatio),
    currentDataText: data.text1,
    previousDataText: data.text2,
    currentDataSQL: data.metric1Sql,
    previousDataSQL: data.metric2Sql
  }));
};

export const admDemographicMapper = (apiData: any): AdmDemographicData => {
  return {
    aboSignup: dataTopicMetricsMapper([apiData.aboSignup])[0],
    newRegs: dataTopicMetricsMapper([apiData.newRegs])[0],
    aboGraph: apiData.aboGraph.map((data: any) => ({
      count: parseFloat(data.count),
      label: data.label
    })),
    memberCustGraph: apiData.memberCustGraph.map((data: any) => ({
      count: parseFloat(data.count),
      label: data.label
    })),
    genderText: apiData.genderText,
    genderSQL: apiData.genderSQL,
    genderGraph: apiData.genderGraph.map((data: any) => ({
      count: parseFloat(data.count),
      label: data.label
    })),
    ageByGenderAvg: parseFloat(apiData.ageByGenderAvg),
    ageByGenderMale: parseFloat(apiData.ageByGenderMale),
    ageByGenderFml: parseFloat(apiData.ageByGenderFml),
    ageByGenderText: apiData.ageByGenderText,
    ageByGenderSQL: apiData.ageByGenderSQL,
    ageByGenderGraphMale: apiData.ageByGenderGraphMale.map((data: any) => ({
      count: parseFloat(data.count),
      label: data.label
    })),
    ageByGenderGraphFml: apiData.ageByGenderGraphFml.map((data: any) => ({
      count: parseFloat(data.count),
      label: data.label
    }))
  };
};
