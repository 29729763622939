import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useGlobalSettingsContext from 'src/resources/global-settings/global-settings-hook';
import { MarketsData } from 'src/resources/markets-data/markets-data-model';
import { getFirstOptionValue } from 'src/utils/select-option-utils';
import MultiSelectDropdown, { Props as MultiSelectProps } from '../multi-select-dropdown';
import Select, { Props as SelectProps } from '../select';
import {
  buildInitialFilters,
  datesOptions,
  Filter,
  getAffiliatesOptions,
  getMarketsOptions,
  resolveMarketOptions
} from './utils';

interface Props {
  marketsData: MarketsData;
  onChange: (filter: Filter) => void;
  className?: string;
}

const MetricsFilter: React.FC<Props> = (props: Props) => {
  const { marketsData, onChange, className = '' } = props;
  const { settings } = useGlobalSettingsContext();
  const marketsAffiliatesOptions = useMemo(
    () => (marketsData ? getAffiliatesOptions(marketsData) : []),
    [marketsData]
  );
  const [marketsOptions, setMarketOptions] = useState(
    marketsAffiliatesOptions.length
      ? getMarketsOptions(
          marketsData!,
          settings.defaultOptions.marketAffiliateCode ??
            getFirstOptionValue(marketsAffiliatesOptions)!
        )
      : []
  );
  const [filters, setFilters] = useState<Filter>(
    buildInitialFilters(settings.defaultOptions, marketsAffiliatesOptions, marketsOptions)
  );

  useEffect(() => {
    if (settings.defaultOptions.marketAffiliateCode) {
      const marketOptions = getMarketsOptions(
        marketsData,
        settings.defaultOptions.marketAffiliateCode
      );
      setMarketOptions(marketOptions);
      setFilters(prev => ({ ...prev, affiliate: settings.defaultOptions.marketAffiliateCode! }));
    }
    if (settings.defaultOptions.marketCodes) {
      setFilters(prev => ({ ...prev, market: settings.defaultOptions.marketCodes! }));
    }
  }, [
    marketsData,
    settings.defaultOptions.marketAffiliateCode,
    settings.defaultOptions.marketCodes
  ]);

  useEffect(() => {
    onChange({ ...filters, market: resolveMarketOptions(marketsData, filters.market) });
  }, [filters, marketsData, onChange]);

  const handleSelectChanges: SelectProps['onChange'] = useCallback(
    e => {
      const { id: name, value } = e.target;

      if (name === 'affiliate') {
        const marketOptions = getMarketsOptions(marketsData, value);
        setMarketOptions(marketOptions);
        setFilters(prev => ({
          ...prev,
          market: marketOptions.map(option => option.value)
        }));
      }

      setFilters(prev => ({ ...prev, [name]: value }));
    },
    [marketsData]
  );

  const handleOnMarketChanges: MultiSelectProps['onChange'] = useCallback(market => {
    market = market ?? [];

    setFilters(prev => ({
      ...prev,
      market: market as string[]
    }));
  }, []);

  return (
    <Row className={className}>
      <Col xs={4}>
        <Select
          id="affiliate"
          title="Region"
          options={marketsAffiliatesOptions}
          value={filters.affiliate}
          onChange={handleSelectChanges}
        />
      </Col>
      <Col xs={4}>
        <MultiSelectDropdown
          id="market"
          items={marketsOptions}
          label="Market"
          value={filters.market}
          onChange={handleOnMarketChanges}
          eager={false}
          required
          all
        />
      </Col>
      <Col xs={4}>
        <Select
          title="Date Range"
          id="date"
          options={datesOptions}
          value={filters.date}
          onChange={handleSelectChanges}
        />
      </Col>
    </Row>
  );
};

export default MetricsFilter;
