import React from 'react';
import { Stack } from 'react-bootstrap';
import Typography from 'src/components/ui/typography';

export interface Props {
  title: string;
  value: string | JSX.Element;
}

const MetadataListItem: React.FC<Props> = props => {
  const { title, value } = props;

  return (
    <Stack className="align-items-flex-start">
      <Typography>{title}:</Typography>
      {typeof value === 'string' ? (
        <Typography colorVariant="primary" className="mt-1">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

export default MetadataListItem;
