import { createContext } from 'react';
import { DataDistribution } from 'src/resources/table/models/table-data-distribution-model';
import { Table } from 'src/resources/table/models/table-model';
import { TableSample } from 'src/resources/table/models/table-sample-model';
import { ExternalData, FetchFunction } from 'src/@types/external-api';
import { ListResult, ListProps } from './table-types';
import { DataLineage } from './models/table-data-lineage';
import { LookerStudioReport } from './models/looker-studio-reports-model';
import { AddUsefulLinkRequest, GetTableRulesRequest, TableRules } from './models/table-rules-model';

export interface TableContextType {
  dataDistribution: ExternalData<DataDistribution>;
  tables: ExternalData<ListResult>;
  selectedTable: ExternalData<Table>;
  tableSample: ExternalData<TableSample>;
  dataLineage: ExternalData<DataLineage>;
  lookerStudioReports: ExternalData<LookerStudioReport[]>;
  tableRules: ExternalData<TableRules>;
  fetchTables: FetchFunction<ListProps>;
  findTable: (tableName: string) => Promise<void>;
  findTableSample: (datasetName: string, tableName: string) => Promise<void>;
  fetchDataDistribution: (
    datasetName: string,
    tableName: string,
    columnName: string
  ) => Promise<void>;
  refreshDataDistribution: (
    datasetName: string,
    tableName: string,
    columnName: string
  ) => Promise<void>;
  fetchDataLineage: (table: string, dataset: string, project: string) => Promise<void>;
  createLineageEntry: (
    sourceTable: string,
    sourceDataset: string,
    sourceProject: string,
    targetTable: string,
    targetDataset: string,
    targetProject: string
  ) => Promise<DataLineage | undefined>;
  deleteLineageEntry: (id: string) => Promise<void>;
  fetchLookerStudioReports: (tableName: string) => Promise<void>;
  getTableRules: (tableRulesRequest: GetTableRulesRequest) => Promise<void>;
  deleteUsefulLink: (usefulLinkId: number) => Promise<void>;
  addNewTableUsefulLink: ({ description, linkUrl, type }: AddUsefulLinkRequest) => Promise<void>;
}

const tableContext = createContext<TableContextType>(null as any);

export default tableContext;
