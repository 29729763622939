import { useCallback, useEffect, useState } from 'react';
import LoadingComponent from 'src/components/core/loading-component';
import Input, { Props as InputProps } from 'src/components/ui/input';
import Typography from 'src/components/ui/typography';
import { AddTagValidationSchema } from 'src/helpers/tag-validators';
import { ErrorsMap, validateSchema } from 'src/utils/schema-validation-utils';
import Dialog from '../../../../../components/core/dialog-layout';
import './index.scss';

interface Values {
  displayName: string;
  value: string;
  requestReason: string;
}

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: Values) => void;
  loading: boolean;
  tagsInfo: string;
}

const emptyValues: Values = { displayName: '', value: '', requestReason: '' };

const UpdateTagModal: React.FC<Props> = ({ open, onClose, onConfirm, loading, tagsInfo }) => {
  const [data, setData] = useState<Values>({ ...emptyValues });
  const [errors, setErrors] = useState<ErrorsMap<Values>>({});
  const [isTouched, setIsTouched] = useState(false);

  const resetForm = useCallback((): void => {
    setData({ ...emptyValues });
    setIsTouched(false);
  }, []);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  useEffect(() => {
    setErrors(validateSchema(AddTagValidationSchema, data));
  }, [data]);

  const handleConfirm = (): void => {
    onConfirm(data);
  };

  const handleChange: InputProps['onChange'] = e => {
    const { name, value } = e.target;
    setIsTouched(true);
    setData({ ...data, [name]: value });
  };

  const getTagInfo = (tag: string): { displayName: string; value: string } => {
    const tagInfo = tag?.split(':');
    return {
      displayName: tagInfo[0],
      value: tagInfo[1]
    };
  };

  return (
    <Dialog
      open={open}
      title="Edit Tag"
      subtitle="Your change request will be executed after approval."
      scapeActionText="CANCEL"
      confirmActionText={loading ? <LoadingComponent /> : 'REQUEST EDIT'}
      confirmActionHandler={Object.keys(errors).length === 0 ? handleConfirm : undefined}
      scapeActionHandler={onClose}
      maxWidth="xs">
      <div className="update-tag-main-container">
        <header className="edit-tag-dialog-header">
          <Typography sizeVariant="sm">
            Current Display Name:{' '}
            <Typography sizeVariant="sm" colorVariant="primary" className="ms-2">
              {getTagInfo(tagsInfo).displayName}
            </Typography>
          </Typography>
          <Typography sizeVariant="sm">
            Current Value:{' '}
            <Typography sizeVariant="sm" colorVariant="primary" className="ms-2">
              {getTagInfo(tagsInfo).value}
            </Typography>
          </Typography>
        </header>
        <Input
          title="New display name:"
          required
          className="mt-4"
          placeholder="Type display name"
          name="displayName"
          value={data.displayName}
          onChange={handleChange}
          errorMessage={isTouched && errors.displayName}
        />
        <Input
          title="New Value:"
          required
          className="mt-4"
          placeholder="Type value"
          name="value"
          value={data.value}
          onChange={handleChange}
          errorMessage={isTouched && errors.value}
        />
        <Input
          title="Request Reason"
          className="mt-4"
          placeholder="Type your request reason"
          name="requestReason"
          required
          value={data.requestReason}
          onChange={handleChange}
          errorMessage={isTouched && errors.requestReason}
        />
      </div>
    </Dialog>
  );
};

export default UpdateTagModal;
