import { DefaultOptionsSettings } from 'src/resources/global-settings/global-settings-model';
import { MarketsData } from 'src/resources/markets-data/markets-data-model';
import { getFirstOptionValue } from 'src/utils/select-option-utils';
import { Option } from '../select';

export interface Filter {
  affiliate: string;
  date: string;
  market: string[];
}

export const datesOptions: Option[] = [
  { name: 'Last month', value: 'LastMonth' },
  { name: 'Last year', value: 'LastYear' },
  { name: 'Last calendar month', value: 'LastCalendarMonth', disabled: true },
  { name: 'Perf YTD', value: 'PerfYTD', disabled: true },
  { name: 'Cal YTD', value: 'CalYTD', disabled: true }
];

export const AllAffiliatesOption = { name: 'All Affiliates', value: 'all' };

export const getAffiliatesOptions = (marketsData: MarketsData): Option[] => {
  return [
    AllAffiliatesOption,
    ...marketsData.affiliates.map(affiliate => ({ name: affiliate.name, value: affiliate.code }))
  ];
};

export const getMarketsOptions = (marketsData: MarketsData, affiliateValue: string): Option[] => {
  if (affiliateValue === AllAffiliatesOption.value) {
    return Object.values(marketsData.marketsMap)
      .flat()
      .map(market => ({ name: market.name, value: market.code }));
  }

  return marketsData.marketsMap[affiliateValue].map(market => ({
    name: market.name,
    value: market.code
  }));
};

export const isAllMarketsSelected = (
  marketData: MarketsData,
  selectedMarkets: string[]
): boolean => {
  return Object.values(marketData.marketsMap).flat().length === selectedMarkets.length;
};

export const resolveMarketOptions = (
  marketsData: MarketsData,
  marketsValues: string[]
): string[] => {
  return isAllMarketsSelected(marketsData, marketsValues) ? [] : marketsValues;
};

export const buildInitialFilters = (
  defaultOptions: DefaultOptionsSettings,
  marketsAffiliatesOptions: Option[],
  marketsOptions: Option[]
): Filter => ({
  affiliate: defaultOptions.marketAffiliateCode ?? getFirstOptionValue(marketsAffiliatesOptions)!,
  market: defaultOptions.marketCodes ?? marketsOptions.map(marketOption => marketOption.value),
  date: getFirstOptionValue(datesOptions)!
});
