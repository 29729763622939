import { Feedback, FeedbacksPagination, FeedbackStatus } from './feedback-model';

export const feedbacksMapper = <T = Feedback>(apiData: any[]): T[] => {
  return apiData.map(data => ({
    id: data.feedbackId,
    body: data.body,
    eventFlow: data.eventFlow,
    status: data.status ?? FeedbackStatus.Unseen,
    subject: data.subject,
    createdAt: new Date(data.creationTimestamp),
    sentBy: data.createdBy
  })) as T[];
};

export const feedbacksPaginationMapper = (apiData: any): FeedbacksPagination => {
  return {
    limit: apiData.pageable.pageSize,
    offset: apiData.pageable.offset,
    total: apiData.totalElements
  };
};
