import axios from 'axios';
import { WithAbortFn } from 'src/@types/external-api';
import { apiWrapper } from 'src/config/api-wrapper';
import { DataDistribution } from 'src/resources/table/models/table-data-distribution-model';
import { TableSample } from 'src/resources/table/models/table-sample-model';
import { tableDataDistributionMapper } from './mappers/table-data-distribution-mapper';
import {
  createdDataLineageEntryMapper,
  tableDataLineageMapper
} from './mappers/table-data-lineage-mapper';
import { facetsMapper, tablePaginationMapper, tablesMapper } from './mappers/table-mapper';
import { tableSampleMapper } from './mappers/table-sample-mapper';
import { DataLineage } from './models/table-data-lineage';
import { Table } from './models/table-model';
import { ListProps, ListResult } from './table-types';
import { LookerStudioReport } from './models/looker-studio-reports-model';
import { AddUsefulLinkRequest, GetTableRulesRequest, TableRules } from './models/table-rules-model';

class TableService {
  public list(props: ListProps): WithAbortFn<Promise<ListResult>> {
    // eslint-disable-next-line max-len
    // detailed "why": https://julietonyekaoha.medium.com/react-cancel-all-axios-request-in-componentwillunmount-e5b2c978c071

    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post('/search', props, { cancelToken: source.token })
        .then(res => res.data)
        .then(data => ({
          facets: facetsMapper(data.facets),
          tables: tablesMapper(data.content),
          pagination: tablePaginationMapper(data)
        })),
      abort: source.cancel
    };
  }

  public show(id: String): Promise<Table> {
    return apiWrapper.api
      .post('/search', {
        query: '',
        filters: [
          {
            filterBy: 'id',
            filterTerms: [id]
          }
        ],
        pageSize: 10,
        pageNumber: 0,
        orderBy: ''
      })
      .then(res => res.data)
      .then(res => tablesMapper(res.content)[0]);
  }

  public samples(dataset: string, tableName: string): Promise<TableSample> {
    return apiWrapper.api
      .get(`/sample/${dataset}/${tableName}`)
      .then(res => res.data)
      .then(data => tableSampleMapper(data));
  }

  public dataDistribution(
    datasetName: string,
    tableName: string,
    columnName: string
  ): Promise<DataDistribution> {
    return apiWrapper.api
      .get(`/distrib/${datasetName}/${tableName}/${columnName}`)
      .then(res => res.data)
      .then(tableDataDistributionMapper);
  }

  public refreshDataDistribution(
    datasetName: string,
    tableName: string,
    columnName: string
  ): Promise<DataDistribution> {
    return apiWrapper.api
      .get(`/distrib/${datasetName}/${tableName}/${columnName}/refresh`)
      .then(res => res.data)
      .then(tableDataDistributionMapper);
  }

  public dataLineage(
    table: string,
    dataset: string,
    project: string,
    depth: number
  ): Promise<DataLineage> {
    return apiWrapper.api
      .get('/lineage/nested/', { params: { table, dataset, project, depth } })
      .then(res => res.data)
      .then(tableDataLineageMapper);
  }

  public createLineageEntry(
    sourceTable: string,
    sourceDataset: string,
    sourceProject: string,
    targetTable: string,
    targetDataset: string,
    targetProject: string
  ): Promise<DataLineage> {
    return apiWrapper.api
      .post('/lineage', {
        sourceTable,
        sourceDataset,
        sourceProject,
        targetTable,
        targetDataset,
        targetProject
      })
      .then(res => res.data)
      .then(createdDataLineageEntryMapper);
  }

  public deleteLineageEntry(id: string): Promise<void> {
    return apiWrapper.api.delete(`/lineage/${id}`).then(res => res.data);
  }

  public fetchLookerStudioReports(tableName: string): Promise<LookerStudioReport[]> {
    return apiWrapper.api
      .get('looker-studio/table-name', { params: { tableName } })
      .then(res => res.data);
  }

  public updateLookerStudioReports(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    return apiWrapper.api.patch('looker-studio/upload-logs', formData);
  }

  public getTableRules(tableRuleRequest: GetTableRulesRequest): Promise<TableRules> {
    return apiWrapper.api.get('table-rules', { params: tableRuleRequest }).then(res => res.data);
  }

  public addNewTableUsefulLink(addUsefulLinkRequest: AddUsefulLinkRequest): Promise<void> {
    return apiWrapper.api.post('table-rules/create-table-link', addUsefulLinkRequest);
  }

  public deleteTableUsefulLink(usefulLinkId: number): Promise<void> {
    return apiWrapper.api.delete(`table-rules/delete-table-link/${usefulLinkId}`);
  }
}

const tableDataService = new TableService();

export default tableDataService;
