import { ReactNode } from 'react';
import Chip from './chip';
import './index.scss';
import AddIcon from '@mui/icons-material/Add';

interface ChipProps {
  title: ReactNode;
  value: string;
}

export interface Props {
  chips: ChipProps[];
  isReadOnly?: boolean;
  deleteChip?: (chipValue: string) => void;
  onChange?: (newChips: ChipProps[]) => void;
  onAdd?: () => void;
}

const Chips: React.FC<Props> = (props: Props) => {
  const { chips, onChange, isReadOnly = true, deleteChip } = props;

  function handleDelete(chipValue: string): void {
    deleteChip?.(chipValue);
    onChange?.(chips.filter(chip => chip.value !== chipValue));
  }

  return (
    <section className="chips">
      {chips.map(({ title, value }) => (
        <Chip key={value} value={value} isReadOnly={isReadOnly} onDelete={handleDelete}>
          {title}
        </Chip>
      ))}
      {props.onAdd && <AddIcon className="add-chips-button" onClick={props.onAdd} />}
    </section>
  );
};

export default Chips;
