import { toCSV } from 'src/utils/csv-utils';
import { SortEnum } from './table-head';
import { CellType, ColumnDataType, TableRow } from './table-types';

const collator = new Intl.Collator('en');

export const parseTableDataToCSV = (headers: string[], rows: TableRow[]): string => {
  return toCSV([headers, ...rows.map(row => row.data.map(forceString))]);
};

const stringSorter = (a: string, b: string): number => {
  return collator.compare(a, b);
};

const numberSorter = (a: number, b: number): number => {
  return b - a;
};

const dateSorter = (a: string, b: string): number => {
  return new Date(b).getTime() - new Date(a).getTime();
};

const indeedSortRows = (
  rows: TableRow[],
  sortType: SortEnum.ASC | SortEnum.DESC,
  sortIdx: number,
  dataTypes?: ColumnDataType[]
): TableRow[] => {
  const dataType: ColumnDataType = dataTypes?.[sortIdx] ?? 'string';
  let sorter: any;

  if (dataType === 'date') sorter = dateSorter;
  else if (dataType === 'number') sorter = numberSorter;
  else sorter = stringSorter;

  return rows.slice().sort((rowA, rowB) => {
    const [a, b] = sortType === SortEnum.ASC ? [rowA, rowB] : [rowB, rowA];
    return sorter(a.data[sortIdx], b.data[sortIdx]);
  });
};

export const sortRows = (
  rows: TableRow[],
  sortState: SortEnum[],
  dataTypes?: ColumnDataType[]
): TableRow[] => {
  const sortedHeaderIdx = sortState.findIndex(order => order !== SortEnum.SAME);

  if (sortedHeaderIdx === -1) {
    return rows.slice();
  }

  return indeedSortRows(rows, sortState[sortedHeaderIdx] as any, sortedHeaderIdx, dataTypes);
};

const forceString = (cell: CellType): string => {
  if (typeof cell === 'number') return cell.toString();

  return cell;
};

export const filterRows = (rows: TableRow[], searchText: string): TableRow[] => {
  return rows.filter(row => {
    return row.data.some(text => new RegExp(searchText, 'i').test(forceString(text)));
  });
};
