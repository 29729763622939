import { MarketAffiliate, MarketsData, MarketsMap } from './markets-data-model';

export const marketsDataMapper = (apiData: any[]): MarketsData => {
  const markets = apiData.map(data => ({
    name: data.countryName,
    code: data.countryCd,
    affiliateName: data.regionName,
    affiliateCode: data.regionCd
  }));
  const marketsMap: MarketsMap = markets.reduce<MarketsMap>((acc, nextMarket) => {
    const { name, code, affiliateCode } = nextMarket;

    if (!(affiliateCode in acc)) {
      acc[affiliateCode] = [];
    }

    acc[affiliateCode].push({ name, code });

    return acc;
  }, {});
  const affiliates: MarketAffiliate[] = Object.entries(
    markets.reduce<Record<string, string>>((acc, nextMarket) => {
      const { affiliateCode, affiliateName } = nextMarket;

      if (!(affiliateCode in acc)) {
        acc[affiliateCode] = affiliateName;
      }

      return acc;
    }, {})
  ).map(entry => {
    const [code, name] = entry;
    return { code, name };
  });

  return { affiliates, marketsMap };
};
