import { Toast as ToastComponent } from 'react-bootstrap';
import { ToastType } from 'src/@types/toasts';
import './index.scss';

interface ToastProps {
  onClose: () => void;
  type?: ToastType;
  text: string;
  delay?: number;
}

const Toast = ({ onClose, type = 'success', text, delay = 2000 }: ToastProps): JSX.Element => {
  return (
    <ToastComponent className={type} onClose={onClose} delay={delay} autohide>
      <ToastComponent.Header>
        <strong className="me-auto" style={{ textTransform: 'capitalize' }}>
          {type}
        </strong>
        {/* for the time being, this is hardcoded, later we may pass a date and, the component itself wou figure out relative date time */}
        <span className="toast-date">just now</span>
      </ToastComponent.Header>
      <ToastComponent.Body>{text}</ToastComponent.Body>
    </ToastComponent>
  );
};

export default Toast;
