import { OptionGroup, isOptionGroup, Option } from 'src/components/ui/select';

export function getFirstOptionValue<T extends Option | OptionGroup>(
  optsAndOptGroups: T[]
): string | undefined {
  for (let idx = 0; idx < optsAndOptGroups.length; idx++) {
    if (isOptionGroup(optsAndOptGroups[idx])) {
      if ((optsAndOptGroups[idx] as OptionGroup).options.length) {
        return (optsAndOptGroups[idx] as OptionGroup).options[0].value;
      }
    } else {
      return (optsAndOptGroups[idx] as unknown as Option).value;
    }
  }
}
