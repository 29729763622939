import React, { useCallback } from 'react';
import TablePagination from '@mui/material/TablePagination';
import PaginationActions from 'src/components/ui/pagination/pagination-actions';
import './index.scss';

export type LimitOptions = 5 | 10 | 20 | 50 | 100;

export interface PaginationData {
  limit: LimitOptions;
  page: number;
}

interface Props {
  limit: LimitOptions;
  page: number;
  count: number;
  limitOptions?: LimitOptions[];
  onPaginationChange: (pagination: PaginationData) => void;
  // this becomes handy when you don't have the total, that's probably gonna be removed later
  currentRows?: number;
}

const defaultLimitOptions: LimitOptions[] = [5, 10, 20, 50, 100];

const Pagination: React.FC<Props> = (props: Props) => {
  const {
    page,
    limit,
    count,
    limitOptions = defaultLimitOptions,
    onPaginationChange,
    currentRows
  } = props;

  const handleOffsetChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      onPaginationChange({ limit, page });
    },
    [limit, onPaginationChange]
  );

  const handleLimitChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onPaginationChange({ limit: parseInt(event.target.value, 10) as LimitOptions, page: 0 });
    },
    [onPaginationChange]
  );

  return (
    <TablePagination
      component="div"
      className="pagination-container"
      rowsPerPageOptions={limitOptions}
      count={count}
      rowsPerPage={limit}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page'
        },
        native: true
      }}
      onPageChange={handleOffsetChange}
      onRowsPerPageChange={handleLimitChange}
      ActionsComponent={props => (
        <PaginationActions
          {...props}
          currentRows={currentRows}
          shouldShowLastPageButton={!currentRows}
        />
      )}
      sx={{
        overflow: 'hidden'
      }}
    />
  );
};

export default Pagination;
