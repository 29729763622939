import React, { useEffect, useState } from 'react';
import FEATURES from 'src/config/features';
import useFeatures from 'src/resources/features/features-hook';
import useTableChangeRequests from 'src/resources/table-change-requests/table-change-requests-hook';
import EditDatasetDescriptionModal from '../../modals/edit-dataset-description';
import TableDescription, { Props as TableDescriptionProps } from './index';

type Props = Omit<TableDescriptionProps, 'onEdit'>;

const ContextualizedTableDescription: React.FC<Props> = props => {
  const { table } = props;
  const { hasFeature } = useFeatures();
  const { createRequest, createdRequest } = useTableChangeRequests();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (createdRequest.success) {
      setIsEditModalOpen(false);
    }
  }, [createdRequest]);

  if (
    hasFeature(
      FEATURES.DashboardScreen.SearchResultsScreen.DataSetDetailsScreen.TableDescriptionEdition
    )
  ) {
    const handleOpenEditModal = (): void => {
      setIsEditModalOpen(true);
    };

    const handleCloseEditModal = (): void => {
      setIsEditModalOpen(false);
    };

    const handleEditDescription = (newValue: string, requestDescription: string): void => {
      void createRequest({
        requestType: 'ChangeTableDescription',
        datasetName: table.dataset,
        newValue,
        tableName: table.tableName,
        requestDescription,
        currentValue: table.description
      });
    };

    return (
      <>
        <TableDescription table={table} onEdit={handleOpenEditModal} />
        <EditDatasetDescriptionModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          onConfirm={handleEditDescription}
          selectedTable={table}
          loading={!!createdRequest.loading}
        />
      </>
    );
  }

  return <TableDescription table={table} />;
};

export default ContextualizedTableDescription;
